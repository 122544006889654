<template>
	<div class="noApplications" v-if="$can('application_view')">
		<v-snackbar v-model="snackbar" :timeout="4000" bottom right :color="color">
			<span>{{ message }}</span>
		</v-snackbar>
		<v-dialog
            transition="dialog-bottom-transition"
            max-width="600"
            v-model="pdfViewDialog"
        >
            <v-card max-width="600" v-if="assignedApplication!=null && assignedApplication.venture!=null">
				<v-toolbar flat>
					{{ docType }}
					<v-spacer></v-spacer>
					<v-btn icon @click="pdfViewDialog = false">
						<v-icon class="primary--text">mdi-close</v-icon>
					</v-btn>
				</v-toolbar>
                <v-card-text>                    
					<div align="right">
						<v-btn icon @click="$refs.myPdfComponent.print()"><v-icon class="primary--text">mdi-printer</v-icon></v-btn>
					</div>
					<pdf v-if="docType == 'CR12'"
						ref="myPdfComponent"
						:src="path+'/storage/docs/'+ assignedApplication.venture.cr12"
						@num-pages="pageCount = $event"
						@page-loaded="currentPage = $event"
					></pdf>
					<pdf v-else
						ref="myPdfComponent"
						:src="path+'/storage/docs/'+ assignedApplication.venture.registration_cetificate"
						@num-pages="pageCount = $event"
						@page-loaded="currentPage = $event"
					></pdf>
                </v-card-text>
            </v-card>
        </v-dialog>
		<v-container>
			<v-layout column>
				<div v-if="view == 'default'">
					<v-tabs show-arrows v-model="applicationTab" class="mt-5">
						<v-tabs-slider color="primary"></v-tabs-slider>

						<v-tab @click="activeTab = 'noApplication'">
							<div class="text-none primary--text">
								Not Started ({{ noApplicationPagination.total }})
							</div>
						</v-tab>
						<v-tab @click="activeTab = 'personalLevel'">
							<div class="text-none primary--text">
								Personal Level ({{ personalLevelApplicationPagination.total }})
							</div>
						</v-tab>
						<v-tab @click="activeTab = 'problemLevel'">
							<div class="text-none primary--text">
								Problem Level ({{ problemLevelApplicationPagination.total }})
							</div>
						</v-tab>
					</v-tabs>

					<div v-if="activeTab == 'noApplication'">
						<v-card outlined class="mt-5 pa-3" style="border-radius: 25px;">
							<v-flex xs12 class="mt-10 mb-2">
								<div class="mx-5">
									<v-layout row wrap>
										<v-flex xs12 md4>
											<h1>Not Started</h1>
										</v-flex>
										<v-flex xs12 md4>
											<div class="pa-1">
												<!-- <v-text-field class="text_field header" outlined dense label="Search"
													append-icon="mdi-undo-variant" @click:append="resetSearch()"
													v-on:keyup.enter="search" v-model="searchTerm"
													@input="enableSearch()"></v-text-field> -->
											</div>

										</v-flex>
										<v-flex xs12 md2>
											<div class="pa-1">
												<!-- <v-btn class="secondary white--text text-none" depressed block
													@click="search" :loading="searchLoader"
													:disabled="searchButtonDisabled">
													Search
													<v-icon right>mdi-magnify</v-icon>
												</v-btn> -->
											</div>
										</v-flex>
										<v-flex xs12 md2>
											<!-- <div class="pa-1">
												<v-btn class="primary white--text text-none" depressed block
													@click="csvDownload('noApplications')" :loading="csvLoading">
													Download CSV
													<v-icon right>mdi-microsoft-excel</v-icon>
												</v-btn>
											</div> -->
										</v-flex>
									</v-layout>
								</div>
							</v-flex>
							<div class="mx-5 mt-9">
								<v-progress-linear v-if="noApplicationLoader" height="1" indeterminate
									color="primary"></v-progress-linear>
							</div>
							<v-divider class="mx-5"></v-divider>
							<v-flex xs12 class="mt-5">
								<div v-if="noApplications.length == 0">
									<v-card elevation="0">
										<v-layout row wrap>
											<v-flex xs12 md1>
												<v-container fill-height fluid>
													<v-row align="center" justify="center">
														<v-col class="text-center">
															<v-icon large class="text--text">
																mdi-alert-circle-outline
															</v-icon>
														</v-col>
													</v-row>
												</v-container>
											</v-flex>
											<v-flex xs12 md11>
												<v-container fill-height fluid>
													<v-row align="center" justify="center">
														<v-col class="text-center"> No User Found </v-col>
													</v-row>
												</v-container>
											</v-flex>
										</v-layout>
									</v-card>
								</div>
								<div v-else>
									<div class="hidden-sm-and-down">
										<v-card elevation="0" class="pa-2">
											<v-simple-table>
												<template v-slot:default>
													<thead class="header">
														<tr class="tableHeader">
															<th class="text-left text--text">#</th>
															<th class="text-left text--text">Image</th>
															<th class="text-left text--text">Name</th>
                                                            <th class="text-left text--text">Email</th>
                                                            <th class="text-left text--text">Phone No</th>
															<th class="text-left text--text">Account Creation Date</th>
														</tr>
													</thead>
													<tbody>
														<tr v-for="(item, index) in noApplications" :key="item.id">
															<td>
																{{ index + 1 }}
															</td>
															<td>
																<v-avatar size="40" class="primary title white--text mx-1"
																	v-if="item.prof_pic == null">
																	{{ item.first_name[0] }}
																</v-avatar>
																<v-avatar size="40" class="primary title mx-1" v-else>
																	<img :src="path + '/storage/profile_pics/' + item.prof_pic"
																	>
																</v-avatar>
															</td>
															<td>{{ item.first_name }} {{ item.middle_name }} {{ item.last_name }}</td>
                                                            <td>{{ item.email }} </td>
                                                            <td>{{ item.phone }} </td>                                                           
															<td>
																{{
																	item.created_at | moment("DD/MM/YYYY - hh:mm a")
																}}
															</td>
														</tr>
													</tbody>
												</template>
											</v-simple-table>
										</v-card>

									</div>
									<div class="hidden-md-and-up">
										<v-layout column>

											<template v-for="(application, index) in noApplications">
												<div :key="index">
													<v-flex xs12 class="mb-2">
														<v-card elevation="0">
															<div class="pa-5">
																<v-layout column>
																	<v-flex xs12>
																		<v-layout row wrap>
																			<v-flex xs11>
																				<div class="title primary--text ml-3">
																					<b>{{ application.first_name }} {{ application.middle_name }} {{ application.last_name }} </b>
																				</div>
																			</v-flex>
																			<v-flex xs1>
																				
																			</v-flex>
																		</v-layout>
																	</v-flex>
																	<v-divider class="mt-2"></v-divider>
																	<v-flex xs12 class="mt-1">
																		<v-layout column>
                                                                            <v-flex xs12 class="mt-1">
																				<div>
																					<b>Email</b>
																				</div>
																				<div>
																					{{
																						application.email
																					}}
																				</div>
																			</v-flex>
                                                                            <v-flex xs12 class="mt-1">
																				<div>
																					<b>Phone No</b>
																				</div>
																				<div>
																					{{
																						application.phone
																					}}
																				</div>
																			</v-flex>
                                                                            <v-flex xs12 class="mt-1">
																				<div>
																					<b>Account Active</b>
																				</div>
																				<div>
																					<div v-if="application.active==1">
                                                                                            Yes
                                                                                        </div>
                                                                                        <div v-else>
                                                                                            No
                                                                                        </div>
																				</div>
																			</v-flex>
																		
																			<v-flex xs12 class="mt-1">
																				<div>
																					<b>Date Added</b>
																				</div>
																				<div>
																					{{
																						application.created_at
																						| moment("DD/MM/YYYY - hh:mm a")
																					}}
																				</div>
																			</v-flex>
																		</v-layout>
																	</v-flex>
																</v-layout>
															</div>
														</v-card>
													</v-flex>
												</div>
											</template>
										</v-layout>
									</div>
								</div>
							</v-flex>
						</v-card>

						<v-layout column class="mt-5">
							<v-flex xs12>
								<div align="center">
									<v-pagination v-if="length != 0" :length="noApplicationLength" total-visible="10"
										v-model="noApplicationPagination.current_page" @input="changeNoApplicationPage()" circle>
									</v-pagination>
								</div>
							</v-flex>
							<v-flex xs12>
								<div v-if="length != 0" align="center" class="mt-5">
									<b class="primary--text">Total: </b>{{ noApplicationPagination.total | formatNumber }}
								</div>
							</v-flex>
						</v-layout>
					</div>
					<div v-if="activeTab == 'personalLevel'">
						<v-card outlined class="mt-5 pa-3" style="border-radius: 25px;">
							<v-flex xs12 class="mt-10 mb-2">
								<div class="mx-5">
									<v-layout row wrap>
										<v-flex xs12 md4>
											<h1>Personal Level</h1>
										</v-flex>
										<v-flex xs12 md4>
											<div class="pa-1">
												<!-- <v-text-field class="text_field header" outlined dense label="Search"
													append-icon="mdi-undo-variant" @click:append="resetSearch()"
													v-on:keyup.enter="search" v-model="searchTerm"
													@input="enableSearch()"></v-text-field> -->
											</div>

										</v-flex>
										<v-flex xs12 md2>
											<div class="pa-1">
												<!-- <v-btn class="secondary white--text text-none" depressed block
													@click="search" :loading="searchLoader"
													:disabled="searchButtonDisabled">
													Search
													<v-icon right>mdi-magnify</v-icon>
												</v-btn> -->
											</div>
										</v-flex>
										<v-flex xs12 md2>
											<div class="pa-1">
												<!-- <v-btn class="primary white--text text-none" depressed block
													@click="csvDownload('personalLevelApplications')" :loading="csvLoading">
													Download CSV
													<v-icon right>mdi-microsoft-excel</v-icon>
												</v-btn>												 -->
											</div>
										</v-flex>
									</v-layout>
								</div>
							</v-flex>
							<div class="mx-5 mt-9">
								<v-progress-linear v-if="personalLevelApplicationLoader" height="1" indeterminate
									color="primary"></v-progress-linear>
							</div>
							<v-divider class="mx-5"></v-divider>
							<v-flex xs12 class="mt-5">
								<div v-if="personalLevelApplications.length == 0">
									<v-card elevation="0">
										<v-layout row wrap>
											<v-flex xs12 md1>
												<v-container fill-height fluid>
													<v-row align="center" justify="center">
														<v-col class="text-center">
															<v-icon large class="primary--text">
																mdi-alert-circle-outline
															</v-icon>
														</v-col>
													</v-row>
												</v-container>
											</v-flex>
											<v-flex xs12 md11>
												<v-container fill-height fluid>
													<v-row align="center" justify="center">
														<v-col class="text-center"> No User Found </v-col>
													</v-row>
												</v-container>
											</v-flex>
										</v-layout>
									</v-card>
								</div>
								<div v-else>
									<div class="hidden-sm-and-down">
										<v-card elevation="0" class="pa-2">
											<v-simple-table>
												<template v-slot:default>
													<thead class="header">
														<tr class="tableHeader">
															<th class="text-left text--text">#</th>
															<th class="text-left text--text">Image</th>
															<th class="text-left text--text">Name</th>
                                                            <th class="text-left text--text">Email</th>
                                                            <th class="text-left text--text">Phone No</th>
                                                            <th class="text-left text--text">Account Active</th>
															<th class="text-left text--text">Account Creation Date</th>
                                                            <th class="text-left text--text">Actions</th>
														</tr>
													</thead>
													<tbody>
														<tr v-for="(item, index) in personalLevelApplications" :key="item.id">
															<td>
																{{ index + 1 }}
															</td>
															<td>
																<v-avatar size="40" class="primary title white--text mx-1"
																	v-if="item.prof_pic == null">
																	{{ item.first_name[0] }}
																</v-avatar>
																<v-avatar size="40" class="primary title mx-1" v-else>
																	<img :src="path + '/storage/profile_pics/' + item.prof_pic"
																	>
																</v-avatar>
															</td>
															<td>{{ item.first_name }} {{ item.middle_name }} {{ item.last_name }}</td>
                                                            <td>{{ item.email }} </td>
                                                            <td>{{ item.phone }} </td>
                                                            <td>
                                                                <div v-if="item.active==1">
                                                                    Yes
                                                                </div>
                                                                <div v-else>
                                                                    No
                                                                </div>
                                                            </td>
															<td>
																{{
																	item.created_at | moment("DD/MM/YYYY - hh:mm a")
																}}
															</td>
															<td>
																<div align="right">
																	<v-tooltip top v-if="$can('application_view')">
																		<template v-slot:activator="{ on, attrs }">
																			<v-btn icon class="button mr-1 universal--text"
																				@click="showApplication(item)">
																				<v-icon small> mdi-eye </v-icon>
																			</v-btn>
																		</template>
																		<span>View</span>
																	</v-tooltip>
																</div>
															</td>
														</tr>
													</tbody>
												</template>
											</v-simple-table>
										</v-card>

									</div>
									<div class="hidden-md-and-up">
										<v-layout column>

											<template v-for="(application, index) in personalLevelApplications">
												<div :key="index">
													<v-flex xs12 class="mb-2">
														<v-card elevation="0">
															<div class="pa-5">
																<v-layout column>
																	<v-flex xs12>
																		<v-layout row wrap>
																			<v-flex xs11>
																				<div class="title primary--text ml-3">
																					<b>{{ application.first_name }} {{ application.middle_name }} {{ application.last_name }} </b>
																				</div>
																			</v-flex>
																			<v-flex xs1>
																				
																			</v-flex>
																		</v-layout>
																	</v-flex>
																	<v-divider class="mt-2"></v-divider>
																	<v-flex xs12 class="mt-1">
																		<v-layout column>
																			<v-flex xs12 class="mt-1">
																				<div>
																					<b>Email</b>
																				</div>
																				<div>
																					{{
																						application.email
																					}}
																				</div>
																			</v-flex>
                                                                            <v-flex xs12 class="mt-1">
																				<div>
																					<b>Phone No</b>
																				</div>
																				<div>
																					{{
																						application.phone
																					}}
																				</div>
																			</v-flex>
                                                                            <v-flex xs12 class="mt-1">
																				<div>
																					<b>Account Active</b>
																				</div>
																				<div>
																					<div v-if="application.active==1">
                                                                                            Yes
                                                                                        </div>
                                                                                        <div v-else>
                                                                                            No
                                                                                        </div>
																				</div>
																			</v-flex>
																		
																			<v-flex xs12 class="mt-1">
																				<div>
																					<b>Date Added</b>
																				</div>
																				<div>
																					{{
																						application.created_at
																						| moment("DD/MM/YYYY - hh:mm a")
																					}}
																				</div>
																			</v-flex>
																			<v-flex xs12 class="mt-5">
																				<v-layout row wrap>
																					<v-flex xs12>
																						<div class="pa-1">
																							<v-btn
																								v-if="$can('application_view')"
																								depressed class="
																								primary
																								text-none
																								white--text
																								" block @click="showApplication(application)">View
																								<v-icon right> mdi-eye
																								</v-icon>
																							</v-btn>
																						</div>
																					</v-flex>
																				</v-layout>
																			</v-flex>
																		</v-layout>
																	</v-flex>
																</v-layout>
															</div>
														</v-card>
													</v-flex>
												</div>
											</template>
										</v-layout>
									</div>
								</div>
							</v-flex>
						</v-card>

						<v-layout column class="mt-5">
							<v-flex xs12>
								<div align="center">
									<v-pagination v-if="length != 0" :length="personalLevelApplicationLength" total-visible="10"
										v-model="personalLevelApplicationPagination.current_page" @input="changePersonalLevelApplicationPage()" circle>
									</v-pagination>
								</div>
							</v-flex>
							<v-flex xs12>
								<div v-if="length != 0" align="center" class="mt-5">
									<b class="primary--text">Total: </b>{{ personalLevelApplicationPagination.total | formatNumber }}
								</div>
							</v-flex>
						</v-layout>
					</div>
					<div v-if="activeTab == 'problemLevel'">
						<v-card outlined class="mt-5 pa-3" style="border-radius: 25px;">
							<v-flex xs12 class="mt-10 mb-2">
								<div class="mx-5">
									<v-layout row wrap>
										<v-flex xs12 md4>
											<h1>Problem Level</h1>
										</v-flex>
										<v-flex xs12 md4>
											<div class="pa-1">
												<!-- <v-text-field class="text_field header" outlined dense label="Search"
													append-icon="mdi-undo-variant" @click:append="resetSearch()"
													v-on:keyup.enter="search" v-model="searchTerm"
													@input="enableSearch()"></v-text-field> -->
											</div>

										</v-flex>
										<v-flex xs12 md2>
											<div class="pa-1">
												<!-- <v-btn class="secondary white--text text-none" depressed block
													@click="search" :loading="searchLoader"
													:disabled="searchButtonDisabled">
													Search
													<v-icon right>mdi-magnify</v-icon>
												</v-btn> -->
											</div>
										</v-flex>
										<v-flex xs12 md2>
											<div class="pa-1">
												<!-- <v-btn class="primary white--text text-none" depressed block
													@click="csvDownload('problemLevelApplications')" :loading="csvLoading">
													Download CSV
													<v-icon right>mdi-microsoft-excel</v-icon>
												</v-btn> -->
											</div>
										</v-flex>
									</v-layout>
								</div>
							</v-flex>
							<div class="mx-5 mt-9">
								<v-progress-linear v-if="problemLevelApplicationLoader" height="1" indeterminate
									color="primary"></v-progress-linear>
							</div>
							<v-divider class="mx-5"></v-divider>
							<v-flex xs12 class="mt-5">
								<div v-if="problemLevelApplications.length == 0">
									<v-card elevation="0">
										<v-layout row wrap>
											<v-flex xs12 md1>
												<v-container fill-height fluid>
													<v-row align="center" justify="center">
														<v-col class="text-center">
															<v-icon large class="primary--text">
																mdi-alert-circle-outline
															</v-icon>
														</v-col>
													</v-row>
												</v-container>
											</v-flex>
											<v-flex xs12 md11>
												<v-container fill-height fluid>
													<v-row align="center" justify="center">
														<v-col class="text-center"> No User Found </v-col>
													</v-row>
												</v-container>
											</v-flex>
										</v-layout>
									</v-card>
								</div>
								<div v-else>
									<div class="hidden-sm-and-down">
										<v-card elevation="0" class="pa-2">
											<v-simple-table>
												<template v-slot:default>
													<thead class="header">
														<tr class="tableHeader">
															<th class="text-left text--text">#</th>
															<th class="text-left text--text">Images</th>
															<th class="text-left text--text">Principal Founder</th>
                                                            <th class="text-left text--text">Founder Email</th>
                                                            <th class="text-left text--text">Founder Phone No</th>
															<th class="text-left text--text">Venture</th>
															<th class="text-left text--text">Venture Email</th>
                                                            <th class="text-left text--text">Venture Phone No</th>
															<th class="text-left text--text">Account Creation Date</th>
															<th class="text-right text--text">Actions</th>
														</tr>
													</thead>
													<tbody>
														<tr v-for="(item, index) in problemLevelApplications" :key="item.id">
															<td>
																{{ index + 1 }}
															</td>
															<td>
																<v-avatar size="40" class="primary title white--text mx-1"
																	v-if="item.prof_pic == null">
																	{{ item.first_name[0] }}
																</v-avatar>
																<v-avatar size="40" class="primary title mx-1" v-else>
																	<img :src="path + '/storage/profile_pics/' + item.prof_pic"
																	>
																</v-avatar>
																<v-avatar size="40" class="primary title mx-1 white--text"
																	v-if="item.venture.image == null && item.venture.name!=null">
																	{{ item.venture.name[0] }}
																</v-avatar>
																<v-avatar size="40" class="primary title mx-1" v-if="item.venture.image != null && item.venture.name!=null">
																	<img :src="path + '/storage/venture_logos/' + item.venture.image"
																	>
																</v-avatar>
															</td>
															<td>{{ item.first_name }} {{ item.middle_name }} {{ item.last_name }}</td>
                                                            <td>{{ item.email }} </td>
                                                            <td>{{ item.phone }} </td>
															<td>
                                                                <div v-if="item.venture.name!=null">
                                                                    {{ item.venture.name }}
                                                                </div>
                                                                <div v-else>
                                                                    N/A
                                                                </div>
                                                            </td>
                                                            <td>
                                                                <div v-if="item.venture.email_address!=null">
                                                                    {{ item.venture.email_address }}
                                                                </div>
                                                                <div v-else>
                                                                    N/A
                                                                </div>
                                                            </td>
                                                            <td>
                                                                <div v-if="item.venture.phone_number!=null">
                                                                    {{ item.venture.phone_number }}
                                                                </div>
                                                                <div v-else>
                                                                    N/A
                                                                </div>
                                                            
                                                            </td>
															<td>
																{{
																	item.created_at | moment("DD/MM/YYYY - hh:mm a")
																}}
															</td>
															<td>
																<div align="right">
																	<v-tooltip top v-if="$can('application_view')">
																		<template v-slot:activator="{ on, attrs }">
																			<v-btn icon class="button mr-1 universal--text"
																				@click="showApplication(item)">
																				<v-icon small> mdi-eye </v-icon>
																			</v-btn>
																		</template>
																		<span>View</span>
																	</v-tooltip>
																</div>
															</td>
														</tr>
													</tbody>
												</template>
											</v-simple-table>
										</v-card>

									</div>
									<div class="hidden-md-and-up">
										<v-layout column>

											<template v-for="(application, index) in problemLevelApplications">
												<div :key="index">
													<v-flex xs12 class="mb-2">
														<v-card elevation="0">
															<div class="pa-5">
																<v-layout column>
																	<v-flex xs12>
																		<v-layout row wrap>
																			<v-flex xs11>
																				<div class="title primary--text ml-3">
																					<b>{{ application.first_name }} {{ application.middle_name }} {{ application.last_name }} </b>
																				</div>
																			</v-flex>
																			<v-flex xs1>
																				
																			</v-flex>
																		</v-layout>
																	</v-flex>
																	<v-divider class="mt-2"></v-divider>
																	<v-flex xs12 class="mt-1">
																		<v-layout column>
																			<v-flex xs12 class="mt-1">
																				<div>
																					<b>Founder Email</b>
																				</div>
																				<div>
																					{{
																						application.email
																					}}
																				</div>
																			</v-flex>
                                                                            <v-flex xs12 class="mt-1">
																				<div>
																					<b>Founder Phone No</b>
																				</div>
																				<div>
																					{{
																						application.phone
																					}}
																				</div>
																			</v-flex>
                                                                            <v-flex xs12 class="mt-1">
																				<div>
																					<b>Venture</b>
																				</div>
																				<div>
																					{{
																						application.venture.name
																					}}
																				</div>
																			</v-flex>
                                                                            <v-flex xs12 class="mt-1">
																				<div>
																					<b>Venture Email</b>
																				</div>
																				<div>
																					{{
																						application.venture.email_address
																					}}
																				</div>
																			</v-flex>
                                                                            <v-flex xs12 class="mt-1">
																				<div>
																					<b>Venture Phone No</b>
																				</div>
																				<div>
																					{{
																						application.venture.phone_number
																					}}
																				</div>
																			</v-flex>
                                                                           
																		
																			<v-flex xs12 class="mt-1">
																				<div>
																					<b>Date Added</b>
																				</div>
																				<div>
																					{{
																						application.created_at
																						| moment("DD/MM/YYYY - hh:mm a")
																					}}
																				</div>
																			</v-flex>
																			<v-flex xs12 class="mt-5">
																				<v-layout row wrap>
																					<v-flex xs12>
																						<div class="pa-1">
																							<v-btn
																								v-if="$can('application_view')"
																								depressed class="
																								primary
																								text-none
																								white--text
																								" block @click="showApplication(application)">View
																								<v-icon right> mdi-eye
																								</v-icon>
																							</v-btn>
																						</div>
																					</v-flex>
																				</v-layout>
																			</v-flex>
																		</v-layout>
																	</v-flex>
																</v-layout>
															</div>
														</v-card>
													</v-flex>
												</div>
											</template>
										</v-layout>
									</div>
								</div>
							</v-flex>
						</v-card>

						<v-layout column class="mt-5">
							<v-flex xs12>
								<div align="center">
									<v-pagination v-if="length != 0" :length="selectedForRatingApplicationLength" total-visible="10"
										v-model="problemLevelApplicationPagination.current_page" @input="changeProblemLevelApplicationPage()" circle>
									</v-pagination>
								</div>
							</v-flex>
							<v-flex xs12>
								<div v-if="length != 0" align="center" class="mt-5">
									<b class="primary--text">Total: </b>{{ problemLevelApplicationPagination.total | formatNumber }}
								</div>
							</v-flex>
						</v-layout>
					</div>

				</div>
	
				<div v-if="view == 'show'">
					<v-card outlined class="mt-5">
						<v-card-title class="tableHeader text--text">
							{{ assignedApplication.first_name }} {{ assignedApplication.middle_name }} {{ assignedApplication.last_name }} 
							<v-spacer></v-spacer>
							<v-btn icon @click="changeView('default')">
								<v-icon class="text--text"> mdi-close </v-icon>
							</v-btn>
						</v-card-title>
						<v-card-text>
							<div class="mt-2">
								<v-tabs show-arrows v-model="applicantTab">
									<v-tabs-slider color="primary"></v-tabs-slider>

									<v-tab @click="subTab = 'principalFounder'">
										<div class="text-none primary--text">Principal Founder</div>
									</v-tab>
									<v-tab @click="subTab = 'aboutMe'" v-if="assignedApplication.venture!=null && assignedApplication.answers!=null">
										<div class="text-none primary--text">
											Questions
										</div>
									</v-tab>
									
									<!-- <v-tab @click="pdfDownload()" :loading="pdfLoading" class="pdf text-none white--text" style="border-radius: 5px;">
										<div class="mt-1">
											Download PDF
											<v-icon right class="white--text mb-1">mdi-file-pdf-box</v-icon>
										</div>
									</v-tab> -->
								</v-tabs>
								<div v-if="subTab == 'principalFounder'" class="mx-3 mt-7">
									<v-layout row wrap>
										<v-flex xs12 md3>
											<div align="center" class="mt-7">
												<v-avatar size="200" class="primary title white--text"
													v-if="assignedApplication.prof_pic == null">
													{{ assignedApplication.first_name[0] }}
												</v-avatar>
												<v-avatar size="200" class="primary title" v-else>
													<img :src="path + '/storage/profile_pics/' + assignedApplication.prof_pic"
													>
												</v-avatar>
											</div>
										</v-flex>
										<v-flex xs12 md9>
											<div class="pa-5">
												<v-layout row wrap>
													<v-flex xs12 md6>
														<v-layout column>
															<v-flex xs12 class="mt-7">
																<v-layout row wrap>
																	<v-flex xs12 md3>
																		<div><b>Name:</b></div>
																	</v-flex>
																	<v-flex xs12 md9>
																		{{ assignedApplication.first_name }} {{ assignedApplication.middle_name }} {{ assignedApplication.last_name }}
																	</v-flex>
																</v-layout>
															</v-flex>
															<v-flex xs12 class="mt-7">
																<v-layout row wrap>
																	<v-flex xs12 md3>
																		<div><b>Email Address:</b></div>
																	</v-flex>
																	<v-flex xs12 md9>
																		{{ assignedApplication.email }}
																	</v-flex>
																</v-layout>
															</v-flex>
															<v-flex xs12 class="mt-7">
																<v-layout row wrap>
																	<v-flex xs12 md3>
																		<div><b>Phone Number:</b></div>
																	</v-flex>
																	<v-flex xs12 md9>
																		{{ assignedApplication.phone }}
																	</v-flex>
																</v-layout>
															</v-flex>

														</v-layout>
													</v-flex>
													<v-flex xs12 md6>
														<v-layout column>
															<v-flex xs12 class="mt-7">
																<v-layout row wrap>
																	<v-flex xs12 md3>
																		<div><b>Gender:</b></div>
																	</v-flex>
																	<v-flex xs12 md9>
                                                                        <div v-if="assignedApplication.gender!=null">
																		    {{ assignedApplication.gender.name }}
                                                                        </div>
                                                                        <div v-else>
                                                                            N/A
                                                                        </div>
																	</v-flex>
																</v-layout>
															</v-flex>
															<v-flex xs12 class="mt-7">
																<v-layout row wrap>
																	<v-flex xs12 md3>
																		<div><b>Nationality:</b></div>
																	</v-flex>
																	<v-flex xs12 md9>
                                                                        <div v-if="assignedApplication.country!=null">
																		    {{ assignedApplication.country.name }}
                                                                        </div>
                                                                        <div v-else>
                                                                            N/A
                                                                        </div>
																	</v-flex>
																</v-layout>
															</v-flex>
															<v-flex xs12 class="mt-7">
																<v-layout row wrap>
																	<v-flex xs12 md3>
																		<div><b>Date of Birth:</b></div>
																	</v-flex>
																	<v-flex xs12 md9>
																		{{ assignedApplication.dob | moment("DD/MM/YYYY") }}
																	</v-flex>
																</v-layout>
															</v-flex>

														</v-layout>
													</v-flex>
													<v-flex xs12 class="mt-10">
														<v-layout row wrap>
															<v-flex xs12>
																<div><b>Socials</b></div>
															</v-flex>
														</v-layout>														
													</v-flex>
													<v-flex xs12 md6>
														<v-layout column>
															<v-flex xs12 class="mt-7">
																<v-layout row wrap>
																	<v-flex xs12 md3>
																		<div><b>LinkedIn:</b></div>
																	</v-flex>
																	<v-flex xs12 md9>
																		<div v-if="assignedApplication.linkedin_url!=null">
																			<a :href="assignedApplication.linkedin_url" style="text-decoration: none;">
																				{{ assignedApplication.linkedin_url }}
																			</a>
																		</div>
																		<div v-else>
																			N/A
																		</div>																		
																	</v-flex>
																</v-layout>
															</v-flex>
														</v-layout>
													</v-flex>
													<v-flex xs12 md6>
														<v-layout column>
															<v-flex xs12 class="mt-7">
																<v-layout row wrap>
																	<v-flex xs12 md3>
																		<div><b>X:</b></div>
																	</v-flex>
																	<v-flex xs12 md9>
																		<div v-if="assignedApplication.twitter_url!=null">
																			<a :href="assignedApplication.twitter_url" style="text-decoration: none;">
																				{{ assignedApplication.twitter_url }}
																			</a>
																		</div>
																		<div v-else>
																			N/A
																		</div>	
																	</v-flex>
																</v-layout>
															</v-flex>
														</v-layout>
													</v-flex>
													<v-flex xs12 class="mt-10">
														<v-layout row wrap>
															<v-flex xs12>
																<div><b>Bio</b></div>
															</v-flex>
														</v-layout>														
													</v-flex>
													<v-flex xs12>
														<v-layout column>
															<v-flex xs12 class="my-7">
																<v-layout row wrap>
																	<v-flex xs12>
																		<div v-if="assignedApplication.bio != null" v-html="assignedApplication.bio"></div>
																		<div v-else>N/A</div>
																	</v-flex>
																</v-layout>
															</v-flex>
														</v-layout>
													</v-flex>
												</v-layout>
												
											</div>
										</v-flex>
									</v-layout>
									
								</div>
								<div v-if="subTab == 'aboutMe'" class="mx-3 mt-7">
									<v-layout column>
										<div v-if="assignedApplication.answers!=null && questions.length != 0">
												<template v-for="(question, index) in questions">
													<div :key="index">
														<v-flex xs12 class="my-2">
															<v-card class="mx-auto" elevation="0">
																<v-card-text>
																	<v-layout row wrap>
																		<v-flex xs1>
																			<div align="center" class="subtitle-1">
																				{{ index + 1 }}.
																			</div>
																		</v-flex>
																		<v-flex xs11>
																			<div class="subtitle-1">
																				<b>{{ question.question }}<span class="red--text" v-if="question.required==1">*</span></b>
																			</div>
																			<div class="font-italic caption">{{ question.description }}</div>
																			<div v-if="question.question_type.name == 'Single Select Multiple Choice Question'">
																				<v-radio-group
																					disabled
																					v-model="singleSelect[question.id]"
																					column>
																					<template v-for="(option, index) in question.multi_choice_options">
																						<div :key="index">
																							<v-radio
																								:label="option.option"
																								:value="option.id"
																								@change="checkOther(question.id, option, question)"></v-radio>
																						</div>
																					</template>
																					<div
																						v-if="otherQuestions[question.id] == true">
																							{{ openEnded[question.id] }}
																					</div>
																					<div v-if="childQuestions.length != 0"
																						class="mt-3">
																						<template
																							v-for="(question, index) in childQuestions[question.id]">
																							<div :key="index">
																								<v-flex xs12
																									class="my-2">
																									<v-card
																										:loading="loading"
																										class="mx-auto"
																										elevation="0">
																										<v-card-text>
																											<v-layout
																												row
																												wrap>
																												<v-flex
																													xs1>
																													<div align="center"
																														class="subtitle-1">
																														{{ index + 1 }}.
																													</div>
																												</v-flex>
																												<v-flex
																													xs11>
																													<div
																														class="subtitle-1">
																														<b>{{ question.question }}</b>
																													</div>
																													<div v-if="question.question_type.name == 'Single Select Multiple Choice Question'">
																														<v-radio-group
																															disabled
																															v-model="singleSelect[question.id]"
																															column>
																															<template
																																v-for="(option, index) in question.multi_choice_options">
																																<div
																																	:key="index">
																																	<v-radio
																																		:label="option.option"
																																		:value="option.id"
																																		@change="checkOther(question.id, option, question)"></v-radio>
																																</div>
																															</template>
																															<div
																																v-if="otherQuestions[question.id] == true">
																																	{{ openEnded[question.id] }}
																															</div>
																															<div
																																v-if="childQuestions.length != 0">
																																{{ childQuestions[question.id] }}
																															</div>
																														</v-radio-group>
																													</div>
																													<div v-if="question.question_type.name == 'Multi Part Single Select Multiple Choice Question'">
																														<div
																															class="mt-4">
																															<template
																																v-for="( multi_question, index ) in question.multi_part_questions">
																																<div :key="index">{{ multi_question.question }}
																																	<v-radio-group disabled
																																		v-model="multiPartSingleAnswers[multi_question.id]" column>
																																		<template
																																			v-for="(option, index) in multi_question.multi_part_options">
																																			<div
																																				:key="index">
																																				<v-radio
																																					:label="option.option"
																																					:value="option.id"
																																					@change="calculateProgress()"></v-radio>
																																			</div>
																																		</template>
																																	</v-radio-group>
																																</div>
																															</template>
																														</div>
																													</div>
																													<div v-if="question.question_type.name == 'Multi Select Multiple Choice Questions'">
																														<div
																															class="mt-4">
																															<template
																																v-for="(option, index) in question.multi_choice_options">
																																<v-checkbox
																																	disabled
																																	v-model="multiSelects"
																																	:key="index"
																																	:label="option.option"
																																	:value="option.id"
																																	@change="calculateProgress()"></v-checkbox>
																															</template>

																														</div>
																													</div>
																													<div v-if="question.question_type.name == 'Multi Part Multi Select Multiple Choice Question'">
																														<div
																															class="mt-4">
																															<template
																																v-for="(multi_question, index) in question.multi_part_questions">
																																<div :key="index">{{ multi_question.question }}
																																	<template
																																		v-for="(option, index) in multi_question.multi_part_options">
																																		<v-checkbox
																																			disabled
																																			v-model="multiPartMultiAnswers"
																																			:key="index"
																																			:label="option.option"
																																			:value="option.id"
																																			@change="calculateProgress()"></v-checkbox>
																																	</template>
																																</div>
																															</template>
																														</div>
																													</div>
																													<div v-if="question.question_type.name == 'Open Ended'">
																														<div
																															class="mt-3 mb-5" v-if="question.length<=25">
																																<div v-if="openEnded[question.id] != null">
																																	{{ openEnded[question.id] }}
																																</div>
																																<div v-else>
																																	N/A
																																</div>
																														</div>
																													</div>
																												</v-flex>
																											</v-layout>
																										</v-card-text>
																									</v-card>
																								</v-flex>
																							</div>
																						</template>
																					</div>
																				</v-radio-group>
																			</div>
																			<div v-if="question.question_type.name =='Multi Part Single Select Multiple Choice Question'">
																				<div class="mt-4">
																					<template v-for="(multi_question, index) in question.multi_part_questions">
																						<div :key="index">
																							{{ multi_question.question }}
																							<v-radio-group disabled v-model="multiPartSingleAnswers[multi_question.id]" column>
																								<template v-for="(option, index) in multi_question.multi_part_options">
																									<div :key="index">
																										<v-radio
																											:label="option.option"
																											:value="option.id">
																										</v-radio>
																									</div>
																								</template>
																							</v-radio-group>
																							<v-text-field
																								v-if="multi_question.question == 'Other'"
																								class="text_field mb-6"
																								outlined
																								disabled
																								v-model="openEnded[question.id]"
																								label="Please specify.."
																								required></v-text-field>
																						</div>
																					</template>
																				</div>
																			</div>
																			<div v-if="question.question_type.name == 'Multi Select Multiple Choice Questions'">
																				<div class="mt-4">
																					<template v-for="(option, index) in question.multi_choice_options">
																						<v-checkbox
																							disabled
																							v-model="multiSelects"
																							:key="index"
																							:label="option.option"
																							:value="option.id"></v-checkbox>
																					</template>
																				</div>
																			</div>
																			<div v-if="question.question_type.name =='Multi Part Multi Select Multiple Choice Question'">
																				<div class="mt-4">
																					<template v-for="(multi_question, index) in question.multi_part_questions">
																						<div :key="index">
																							{{ multi_question.question }}
																							<template v-for="(option, index) in multi_question.multi_part_options">
																								<v-checkbox
																									disabled
																									v-model="multiPartMultiAnswers"
																									:key="index"
																									:label="option.option"
																									:value="option.id"></v-checkbox>
																							</template>
																						</div>
																					</template>
																				</div>
																			</div>
																			<div v-if="question.question_type.name == 'Open Ended'">
																				<div class="mt-3 mb-5" v-if="question.length<=25 && question.length!=null">
																						<div v-if="openEnded[question.id] != null">
																							{{ openEnded[question.id] }}
																						</div>
																						<div v-else>
																							N/A
																						</div>
																				</div>
																				<div class="mt-3 mb-5" v-else>
																					<div v-html="openEnded[question.id]"></div>																					
																				</div>
																			</div>
																		</v-flex>
																	</v-layout>
																</v-card-text>
															</v-card>
														</v-flex>
													</div>
												</template>
											</div>
                                        
									</v-layout>
								</div>
							</div>
						</v-card-text>
					</v-card>
				</div>
			</v-layout>
		</v-container>
		<logData v-if="logDataModel" v-model="logDataModel" />
	</div>
</template>

<script>
import apiCall from "@/utils/api";
import { mapGetters, mapActions } from "vuex";
import logData from "@/views/accessControl/logData";
import Vue from "vue";
import pdf from 'vue-pdf'
var numeral = require("numeral");
Vue.filter("formatNumber", function (value) {
	return numeral(value).format("0,0");
});

Vue.use(require("vue-moment"));

export default {
	components: {
		logData,
		pdf
	},
	data() {
		return {
			path: process.env.VUE_APP_API_URL,
			view: "default",
			activeTab: 'noApplication',
			subTab: 'principalFounder',
			applicationTab: null,
			applicantTab: null,

			docType: null,
			pdfViewDialog: false,

			loading: false,
			upgradeLoading: false,
			downgradeLoading: false,
			rejectLoading: false,
			deactivateLoading: false,

			csvLoading: false,
			pdfLoading: false,

			albumLoader: false,
			imageLoader: false,

			albumDatePicker: false,

			valid: true,
			message: "",
			color: "",
			delete: false,
			activate: false,
			confirm: false,
			lockEmail: false,

			applicationIndex: null,
			albumIndex: null,

			snackbar: false,
			inputRules: [
				(v) => !!v || "Input is required"
			],
			fileRules: [(v) => !!v || "Kindly Select a File"],

			imageDialog: false,
			imageLink: '',
			imageName: "",
			imageUrl: "",
			imageFile: "",
			imageValid: true,
			imageLoading: false,

			months: [
				"01",
				"02",
				"03",
				"04",
				"05",
				"06",
				"07",
				"08",
				"09",
				"10",
				"11",
				"12",
			],

			images: [],

			openEnded: [],
			singleSelect: [],
			multiPartSingleAnswers: [],
			multiPartMultiAnswers: [],
			multiSelects: [],
			questions: [],

			searchTerm: null,
			searchLoader: false,
			searchButtonDisabled: false,

			filteredSubmittedApplications: false,
			filteredReviewApplications: false,
			filteredRejectedApplications: false,
			filteredSelectedForRatingApplications: false,
			filteredShortlistedApplications: false,

			newApplication: {
				id: null,
				name: null,
			},

			assignedApplication: null,

			formData: null,
			logData: {
				message: null,
				model: "App\\Models\\Application",
			},
		};
	},
	created() {
		if (this.$can("application_view")) {
			this.startNoApplicationLoader();
			this.fetchNoApplications(this.noApplicationPagination.current_page);

			this.startPersonalLevelApplicationLoader();
			this.fetchPersonalLevelApplications(this.personalLevelApplicationPagination.current_page);

			this.startProblemLevelApplicationLoader();
			this.fetchProblemLevelApplications(this.problemLevelApplicationPagination.current_page);
			
			this.getQuestions();
			this.getFormData()
		}
	},
	methods: {
		...mapActions([
			"fetchNoApplications",
			"startNoApplicationLoader",
			"stopNoApplicationLoader",
			"filterNoApplications",

			"fetchPersonalLevelApplications",
			"startPersonalLevelApplicationLoader",
			"stopPersonalLevelApplicationLoader",
			"filterPersonalLevelApplications",

			"fetchProblemLevelApplications",
			"startProblemLevelApplicationLoader",
			"stopProblemLevelApplicationLoader",
			"filterProblemLevelApplications",

			"filterLogData",
			"openLogDataModel",
		]),

		showApplication(application) {
			this.assignedApplication = application;
            if(this.assignedApplication.answers!=null){
                this.assignAnwers()
            }			
			this.changeView("show");
		},

		assignAnwers() {				
			this.openEnded = this.assignedApplication.answers[0];
			this.singleSelect = this.assignedApplication.answers[1];
			this.multiPartSingleAnswers = this.assignedApplication.answers[2];
			this.multiPartMultiAnswers = this.assignedApplication.answers[3];
			this.multiSelects = this.assignedApplication.answers[4];
		},

		enableSearch() {
			this.searchButtonDisabled = false;
		},

		resetSubmittedSearch() {
			this.searchTerm = null;
			this.filteredSubmittedApplications = true;
			this.startNoApplicationLoader();
			this.fetchNoApplications(1);
		},
		resetReviewSearch() {
			this.searchTerm = null;
			this.filteredReviewApplications = true;
			this.startPersonalLevelApplicationLoader();
			this.fetchPersonalLevelApplications(1);
		},
		resetRejectedSearch() {
			this.searchTerm = null;
			this.filteredRejectedApplications = true;
			this.startVentureLevelApplicationLoader();
			this.fetchVentureLevelApplications(1);
		},
		resetSelectedForRatingSearch() {
			this.searchTerm = null;
			this.filteredSelectedForRatingApplications = true;
			this.startProblemLevelApplicationLoader();
			this.fetchProblemLevelApplications(1);
		},
		resetShortlistedSearch() {
			this.searchTerm = null;
			this.filteredShortlistedApplications = true;
			this.startTeamLevelApplicationLoader();
			this.fetchTeamLevelApplications(1);
		},
		

		viewLog(data) {
			this.logData.message = data.id;
			this.filterLogData(this.logData);
			this.openLogDataModel();
		},

		viewDocument(doctype){
			this.docType = doctype
			this.pdfViewDialog = true
		},

		csvDownload(type) {
            this.csvLoading = true;
            apiCall({
                url: "/api/report?type="+type,
                method: "GET",
            })
                .then((resp) => {
                    this.csvLoading = false;
                    this.downloadFile(resp, type);
                })
                .catch((error) => {
                    console.log(error.response);
                    this.csvLoading = false;
                    this.message = "An Error Occurred";
                    this.color = "error";
                    this.snackbar = true;
                });
        },

		downloadFile(response, type) {
            // It is necessary to create a new blob object with mime-type explicitly set
            // otherwise only Chrome works like it should
            var newBlob = new Blob([response], { type: "application/csv" });

            // IE doesn't allow using a blob object directly as link href
            // instead it is necessary to use msSaveOrOpenBlob
            if (window.navigator && window.navigator.msSaveOrOpenBlob) {
                window.navigator.msSaveOrOpenBlob(newBlob);
                return;
            }

            // For other browsers:
            // Create a link pointing to the ObjectURL containing the blob.
            const data = window.URL.createObjectURL(newBlob);
            var link = document.createElement("a");
            link.href = data;
			if(type=='noApplications'){
				link.download = 'Submitted Applications - '  + this.dateToday +  ".csv";
			}
			if(type=='personalLevelApplications'){
				link.download = 'Review Applications - '  + this.dateToday +  ".csv";
			}
			if(type=='ventureLevelApplications'){
				link.download = 'Rejected Applications - '  + this.dateToday +  ".csv";
			}
			if(type=='problemLevelApplications'){
				link.download = 'Selected for Rating Applications - '  + this.dateToday +  ".csv";
			}
			if(type=='teamLevelApplications'){
				link.download = 'Shortlisted Applications - '  + this.dateToday +  ".csv";
			}
            
            link.click();
            setTimeout(function () {
                // For Firefox it is necessary to delay revoking the ObjectURL
                window.URL.revokeObjectURL(data);
            }, 100);
        },

		pdfDownload() {
            this.pdfLoading = true;

            axios.defaults.headers.common['Authorization'] = localStorage.getItem('user-token');
            axios({
                url: this.path+"/api/report?type=applicationPDF",
                data: this.assignedApplication,
                method: "POST",
                responseType: "blob",
            })
                    .then((resp) => {
                        this.downloadPDFFile(resp, this.assignedApplication.venture.name);
                        this.pdfLoading = false;
                    })
                    .catch((error) => {
                        this.pdfLoading = false;
                    });
        },
		downloadPDFFile(response, filename) {
            // It is necessary to create a new blob object with mime-type explicitly set
            // otherwise only Chrome works like it should
            console.log('response.data', response.data)
            var newBlob = new Blob([response.data], { type: "application/pdf" });

            // IE doesn't allow using a blob object directly as link href
            // instead it is necessary to use msSaveOrOpenBlob
            if (window.navigator && window.navigator.msSaveOrOpenBlob) {
                window.navigator.msSaveOrOpenBlob(newBlob);
                return;
            }

            // For other browsers:
            // Create a link pointing to the ObjectURL containing the blob.
            const data = window.URL.createObjectURL(newBlob);
            var link = document.createElement("a");
            link.href = data;
            link.download = filename + ' ' + this.dateToday + ".pdf";
            link.click();
            setTimeout(function () {
                // For Firefox it is necessary to delay revoking the ObjectURL
                window.URL.revokeObjectURL(data);
            }, 100);
        },



		submittedSearch() {
			if (this.searchTerm == null) {
				this.searchButtonDisabled = true;
			} else {
				this.searchLoader = true;
				this.searchButtonDisabled = false;
				this.startNoApplicationLoader();
				apiCall({
					url: "/api/application?type=submittedSearch&search=" + this.searchTerm,
					method: "GET",
				})
					.then((resp) => {
						this.filterNoApplications(resp);
						this.searchLoader = false;
						this.stopNoApplicationLoader();
						this.filteredSubmittedApplications = true;
					})
					.catch((error) => {
						console.log(error.response);
						this.searchLoader = false;
						this.stopNoApplicationLoader();
						this.message = "An Error Occurred";
						this.color = "error";
						this.snackbar = true;
					});
			}
		},
		reviewSearch() {
			if (this.searchTerm == null) {
				this.searchButtonDisabled = true;
			} else {
				this.searchLoader = true;
				this.searchButtonDisabled = false;
				this.startPersonalLevelApplicationLoader();
				apiCall({
					url: "/api/application?type=reviewSearch&search=" + this.searchTerm,
					method: "GET",
				})
					.then((resp) => {
						this.filterPersonalLevelApplications(resp);
						this.searchLoader = false;
						this.stopPersonalLevelApplicationLoader();
						this.filteredReviewApplications = true;
					})
					.catch((error) => {
						console.log(error.response);
						this.searchLoader = false;
						this.stopPersonalLevelApplicationLoader();
						this.message = "An Error Occurred";
						this.color = "error";
						this.snackbar = true;
					});
			}
		},
		rejectedSearch() {
			if (this.searchTerm == null) {
				this.searchButtonDisabled = true;
			} else {
				this.searchLoader = true;
				this.searchButtonDisabled = false;
				this.startVentureLevelApplicationLoader();
				apiCall({
					url: "/api/application?type=rejectedSearch&search=" + this.searchTerm,
					method: "GET",
				})
					.then((resp) => {
						this.filterVentureLevelApplications(resp);
						this.searchLoader = false;
						this.stopVentureLevelApplicationLoader();
						this.filteredRejectedApplications = true;
					})
					.catch((error) => {
						console.log(error.response);
						this.searchLoader = false;
						this.stopVentureLevelApplicationLoader();
						this.message = "An Error Occurred";
						this.color = "error";
						this.snackbar = true;
					});
			}
		},
		selectedForRatingSearch() {
			if (this.searchTerm == null) {
				this.searchButtonDisabled = true;
			} else {
				this.searchLoader = true;
				this.searchButtonDisabled = false;
				this.startProblemLevelApplicationLoader();
				apiCall({
					url: "/api/application?type=selectedForRatingSearch&search=" + this.searchTerm,
					method: "GET",
				})
					.then((resp) => {
						this.filterProblemLevelApplications(resp);
						this.searchLoader = false;
						this.stopProblemLevelApplicationLoader();
						this.filteredSelectedForRatingApplications = true;
					})
					.catch((error) => {
						console.log(error.response);
						this.searchLoader = false;
						this.stopProblemLevelApplicationLoader();
						this.message = "An Error Occurred";
						this.color = "error";
						this.snackbar = true;
					});
			}
		},
		shortlistedSearch() {
			if (this.searchTerm == null) {
				this.searchButtonDisabled = true;
			} else {
				this.searchLoader = true;
				this.searchButtonDisabled = false;
				this.startTeamLevelApplicationLoader();
				apiCall({
					url: "/api/application?type=shortlistedSearch&search=" + this.searchTerm,
					method: "GET",
				})
					.then((resp) => {
						this.filterTeamLevelApplications(resp);
						this.searchLoader = false;
						this.stopTeamLevelApplicationLoader();
						this.filteredShortlistedApplications = true;
					})
					.catch((error) => {
						console.log(error.response);
						this.searchLoader = false;
						this.stopTeamLevelApplicationLoader();
						this.message = "An Error Occurred";
						this.color = "error";
						this.snackbar = true;
					});
			}
		},
		
		changeView(view) {
			if (view == "default") {
				this.subTab = 'principalFounder'
			} else if (view == "create") {
				this.getFormData();
			} else if (view == "show") {
			}
			this.view = view;
		},

		getQuestions() {
			apiCall({
				url: "/api/question?type=public",
				method: "GET",
			})
				.then((resp) => {
					this.questions = resp;
				})
				.catch((error) => {
					this.message = "An Error Occurred; Couldn't Get Form Data";
					this.color = "error";
				});
		},

		changeNoApplicationPage() {
			this.startNoApplicationLoader();
			if (this.filteredSubmittedApplications == true) {
				this.searchButtonDisabled = false;
				apiCall({
					url:
						"/api/application?page=" +
						this.noApplicationPagination.current_page +
						"&type=search&search=" +
						this.searchTerm,
					method: "GET",
				})
					.then((resp) => {
						this.filterNoApplications(resp);
						this.stopNoApplicationLoader();
					})
					.catch((error) => {
						this.stopNoApplicationLoader();
						this.message = "An Error Occurred";
						this.color = "error";
						this.snackbar = true;
					});
			} else {
				this.fetchNoApplications(this.noApplicationPagination.current_page);
			}
		},
		changePersonalLevelApplicationPage() {
			this.startPersonalLevelApplicationLoader();
			if (this.filteredReviewApplications == true) {
				this.searchButtonDisabled = false;
				apiCall({
					url:
						"/api/application?page=" +
						this.personalLevelApplicationPagination.current_page +
						"&type=search&search=" +
						this.searchTerm,
					method: "GET",
				})
					.then((resp) => {
						this.filterPersonalLevelApplications(resp);
						this.stopPersonalLevelApplicationLoader();
					})
					.catch((error) => {
						this.stopPersonalLevelApplicationLoader();
						this.message = "An Error Occurred";
						this.color = "error";
						this.snackbar = true;
					});
			} else {
				this.fetchPersonalLevelApplications(this.personalLevelApplicationPagination.current_page);
			}
		},
		changeProblemLevelApplicationPage() {
			this.startProblemLevelApplicationLoader();
			if (this.filteredSelectedForRatingApplications == true) {
				this.searchButtonDisabled = false;
				apiCall({
					url:
						"/api/application?page=" +
						this.problemLevelApplicationPagination.current_page +
						"&type=search&search=" +
						this.searchTerm,
					method: "GET",
				})
					.then((resp) => {
						this.filterProblemLevelApplications(resp);
						this.stopProblemLevelApplicationLoader();
					})
					.catch((error) => {
						this.stopProblemLevelApplicationLoader();
						this.message = "An Error Occurred";
						this.color = "error";
						this.snackbar = true;
					});
			} else {
				this.fetchProblemLevelApplications(this.problemLevelApplicationPagination.current_page);
			}
		},
		getFormData() {
			apiCall({
				url: "/api/application?type=formData",
				method: "GET",
			})
				.then((resp) => {
					this.formData = resp;
				})
				.catch((error) => {
					this.message = "An Error Occurred; Couldn't Get Form Data";
					this.color = "error";
				});
		},
	},
	computed: {
		...mapGetters([
			"noApplications", "noApplicationPagination", "noApplicationLoader", 
			"personalLevelApplications", "personalLevelApplicationPagination", "personalLevelApplicationLoader", 
			"problemLevelApplications", "problemLevelApplicationPagination", "problemLevelApplicationLoader", 
			
			"logDataModel"
		]),
		noApplicationLength: function () {
			return Math.ceil(
				this.noApplicationPagination.total / this.noApplicationPagination.per_page
			);
		},
		personalLevelApplicationLength: function () {
			return Math.ceil(
				this.personalLevelApplicationPagination.total / this.personalLevelApplicationPagination.per_page
			);
		},
		problemLevelApplicationLength: function () {
			return Math.ceil(
				this.problemLevelApplicationPagination.total / this.problemLevelApplicationPagination.per_page
			);
		},

		dateToday() {
			var today = new Date();

			var timestamp =
				today.getFullYear() +
				"-" +
				this.months[today.getMonth()] +
				"-" +
				("0" + today.getDate()).slice(-2);
			return timestamp;
		},
	},
};
</script>
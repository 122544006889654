<template>
    <div class="faqs">
        <v-layout column>
            <v-flex xs12>
                <v-img dark height="250" src="img/page-title-about-us.jpg">
                    <div align="center" class="font-weight-black display-1 mt-16">
                        FAQs
                    </div>
                </v-img>
            </v-flex>
            <v-flex xs12 class="white">
                <v-container>
                    <v-layout column>
                        <v-flex xs12 class="mt-16">
                            <div align="center" class="display-3 font-weight-black primary--text mt-16">Got Queries?</div>
                            <div align="center" class="display-1 secondary--text mt-10">Here are Answers to the Frequently
                                Asked Questions</div>
                        </v-flex>
                        <v-flex xs12 class="mt-15">
                            <v-layout row wrap>
                                <v-flex xs5>

                                </v-flex>
                                <v-flex xs2>
                                    <v-divider class="primary mx-10"></v-divider>
                                    <v-divider class="secondary"></v-divider>
                                    <v-divider class="primary mx-10"></v-divider>
                                </v-flex>
                                <v-flex xs5>

                                </v-flex>
                            </v-layout>
                        </v-flex>
                        <v-flex xs12 class="my-16">
                            <v-layout row wrap>
                                <v-flex xs12 md6>
                                    <div class="pa-5">
                                        <template>
                                            <v-expansion-panels flat>
                                                <v-expansion-panel>
                                                    <v-expansion-panel-header color="#EAEAEA">
                                                        <div class="font-weight-medium">Who is funding the Women In Tech Incubator Program?</div>
                                                    </v-expansion-panel-header>
                                                    <v-expansion-panel-content color="#FCFCFC">
                                                        <v-divider class="primary"></v-divider>
                                                        <div class="my-10 font-weight-thin">
                                                            The Women in Tech Incubation Program is proudly sponsored by
                                                            Standard Chartered.
                                                        </div>
                                                        <p>
                                                            <b>About Standard Chartered</b>
                                                        </p>
                                                        <div class="mt-9">
                                                            We are a leading international banking group, with around 84,000
                                                            employees and a 150-year history in some of the world’s most
                                                            dynamic markets. We bank the people and companies driving
                                                            investment, trade and the creation of wealth across Asia, Africa
                                                            and the Middle East. Our heritage and values are expressed in
                                                            our brand promise, Here for good. Standard Chartered PLC is
                                                            listed on the London and Hong Kong Stock Exchanges as well as
                                                            the Bombay and National Stock Exchanges in India.
                                                        </div>
                                                    </v-expansion-panel-content>
                                                </v-expansion-panel>
                                                
                                                <v-expansion-panel>
                                                    <v-expansion-panel-header color="#EAEAEA">                                       
                                                        <div class="font-weight-medium">Who is implementing the Women In Tech Incubator Program?</div>
                                                    </v-expansion-panel-header>
                                                    <v-expansion-panel-content color="#FCFCFC">
                                                        <v-divider class="secondary"></v-divider>
                                                        <div class="my-10 font-weight-thin">
                                                            The program is implemented by @iBizAfrica Centre at Strathmore
                                                            University.
                                                        </div>
                                                        <p>
                                                            <b>About @iBizAfrica</b>
                                                        </p>
                                                        <div class="mt-9">
                                                            @iBizAfrica is the business incubation centre at Strathmore
                                                            University which seeks to nurture the development, growth and
                                                            success of startups. We attain this through building the
                                                            potential of the youth to develop ICT solutions and businesses
                                                            that work for the common good in society.
                                                        </div>
                                                        <div class="mt-9">
                                                            @iBizAfrica implements the entrepreneurship theme of
                                                            @iLabAfrica, the research and innovation centre at Strathmore
                                                            University.
                                                        </div>
                                                    </v-expansion-panel-content>
                                                </v-expansion-panel>

                                                <v-expansion-panel>
                                                    <v-expansion-panel-header color="#EAEAEA">
                                                        <div class="font-weight-medium">Where can I access the Application Form?</div>
                                                    </v-expansion-panel-header>
                                                    <v-expansion-panel-content color="#FCFCFC">
                                                        <v-divider class="primary"></v-divider>
                                                        <div class="my-10 font-weight-thin">
                                                            The application form can be accessed <a href="/#/cohort-7" style="text-decoration: none;">Here</a>
                                                        </div>
                                                    </v-expansion-panel-content>
                                                </v-expansion-panel>

                                                <v-expansion-panel>
                                                    <v-expansion-panel-header color="#EAEAEA">
                                                        <div class="font-weight-medium">Who are we looking for?</div>
                                                    </v-expansion-panel-header>
                                                    <v-expansion-panel-content color="#FCFCFC">
                                                        <v-divider class="secondary"></v-divider>
                                                        <div class="my-10 font-weight-thin">
                                                            We are seeking to recruit the best entrepreneurial minds, women-led tech startups as key drivers of innovation in business.
                                                        </div>
                                                        <div class="font-weight-thin">
                                                            The principle founder must be:
                                                        </div>
                                                        <div>
                                                            <ol>
                                                                <li>A woman.</li>
                                                                <li>Above 18 years of age.</li>
                                                                <li>Of Kenyan nationality or permanent residency.</li>
                                                            </ol>
                                                        </div>
                                                    </v-expansion-panel-content>
                                                </v-expansion-panel>

                                                <v-expansion-panel>
                                                    <v-expansion-panel-header color="#EAEAEA">
                                                        <div class="font-weight-medium">What are the eligibility criteria?</div>
                                                    </v-expansion-panel-header>
                                                    <v-expansion-panel-content color="#FCFCFC">
                                                        <v-divider class="primary"></v-divider>
                                                        <div class="my-10 font-weight-thin">
                                                            <ol>
                                                                <li>The startups must be Kenyan registered companies which are building technological solutions aimed at mitigating Kenyan socio-economic challenges.</li>
                                                                <li>The selected startup ventures may be sector agnostic.</li>
                                                                <li>The startup ventures must embed components of emerging technologies in building their solutions ie. Artificial Intelligence, Internet of Things; Big Data; Machine Learning; Robotics; Augmented & Virtual Reality; 3D & 4D Printing; Cloud Computing; Big Data; Blockchain; Drone Technology and Biometrics e.t.c within their respective sectors.</li>
                                                                <li>The applicants must be Women led startup ventures and the teams should be made up of at least 50% women.</li>
                                                                <li>Principal applicant must be the founder with a majority stake.</li>
                                                                <li>3 months to less than 2 years of operation seeking support for proof of concept, customer, product and business model development.</li>
                                                                <li>Pre-revenue with early traction (users). Post revenue is an added advantage.</li>
                                                                <li>Not received investment of more than KES. 500,000 and not participated in a similar program in the last 1 year.</li>
                                                                <li>Companies solving for any of the 17 SDGs.</li>
                                                            </ol>
                                                        </div>
                                                    </v-expansion-panel-content>
                                                </v-expansion-panel>

                                                <v-expansion-panel>
                                                    <v-expansion-panel-header color="#EAEAEA">
                                                        <div class="font-weight-medium">When is the application deadline?</div>
                                                    </v-expansion-panel-header>
                                                    <v-expansion-panel-content color="#FCFCFC">
                                                        <v-divider class="secondary"></v-divider>
                                                        <div class="my-10 font-weight-thin">
                                                            The application deadline is 16th May 2024
                                                        </div>
                                                    </v-expansion-panel-content>
                                                </v-expansion-panel>

                                                <v-expansion-panel>
                                                    <v-expansion-panel-header color="#EAEAEA">
                                                        <div class="font-weight-medium">What are the benefits of applying to the Women In Tech incubation programme?</div>
                                                    </v-expansion-panel-header>
                                                    <v-expansion-panel-content color="#FCFCFC">
                                                        <v-divider class="primary"></v-divider>
                                                        <div class="my-10 font-weight-thin">
                                                            The program has the following benefits for you:
                                                        </div>
                                                        <div>
                                                            <ol>
                                                                <li>
                                                                    12 weeks of an immersive learning experience
                                                                </li>
                                                                <li>
                                                                    Access to a leading network of coaching experts
                                                                </li>
                                                                <li>
                                                                    Access to B2B networking opportunities
                                                                </li>
                                                                <li>
                                                                    Personalized mentorship sessions to address your unique business needs
                                                                </li>
                                                                <li>
                                                                    Opportunity to join a supportive Women in Tech Alumni Network
                                                                </li>
                                                                <li>
                                                                    KES 1,000,000 equity-free grant seed funding for the top 7 finalists
                                                                </li>
                                                                <li>
                                                                    9 months ongoing support from Standard Chartered and @iBizAfrica to assist with go-to-market and scale for the top 7 finalists.
                                                                </li>
                                                            </ol>
                                                        </div>
                                                    </v-expansion-panel-content>
                                                </v-expansion-panel>

                                                <v-expansion-panel>
                                                    <v-expansion-panel-header color="#EAEAEA">
                                                        <div class="font-weight-medium">What is the Standard Chartered Women in Technology Incubator Kenya Program?</div>
                                                    </v-expansion-panel-header>
                                                    <v-expansion-panel-content color="#FCFCFC">
                                                        <v-divider class="secondary"></v-divider>
                                                        <div class="my-10 font-weight-thin">
                                                            The Standard Chartered Women in Technology Incubator Kenya is Africa’s leading women in tech incubator, aligning with calls for more diversity in technology and for more opportunities for women to develop entrepreneurial and leadership excellence. The program is an initiative of Standard Chartered in partnership with Strathmore University’s @iBizAfrica incubator. It combines world-class startup support with local and international experience to provide Africa’s most competitive and attractive startup incubation program focusing on immersive learning, mentorship, building and growing Africa’s next iconic startups taking on the continent’s most relevant challenges and opportunities with the support of seed capital.
                                                        </div>
                                                    </v-expansion-panel-content>
                                                </v-expansion-panel>
                                            </v-expansion-panels>
                                        </template>
                                    </div>
                                </v-flex>
                                <v-flex xs12 md6>
                                    <div class="pa-5">
                                        <template>
                                            <v-expansion-panels flat>
                                                <v-expansion-panel>
                                                    <v-expansion-panel-header color="#EAEAEA">
                                                        <div class="font-weight-medium">How long is the incubation period?</div>
                                                    </v-expansion-panel-header>
                                                    <v-expansion-panel-content color="#FCFCFC">
                                                        <v-divider class="primary"></v-divider>
                                                        <div class="my-10 font-weight-thin">
                                                            The incubation period is 12 weeks.
                                                        </div>
                                                    </v-expansion-panel-content>
                                                </v-expansion-panel>
                                                
                                                <v-expansion-panel>
                                                    <v-expansion-panel-header color="#EAEAEA">
                                                        <div class="font-weight-medium">When does the program begin?</div>
                                                    </v-expansion-panel-header>
                                                    <v-expansion-panel-content color="#FCFCFC">
                                                        <v-divider class="secondary"></v-divider>
                                                        <div class="my-10 font-weight-thin">
                                                            The program is starting on 3rd June 2024.
                                                        </div>
                                                    </v-expansion-panel-content>
                                                </v-expansion-panel>

                                                <v-expansion-panel>
                                                    <v-expansion-panel-header color="#EAEAEA">
                                                        <div class="font-weight-medium">When does the programme end?</div>
                                                    </v-expansion-panel-header>
                                                    <v-expansion-panel-content color="#FCFCFC">
                                                        <v-divider class="primary"></v-divider>
                                                        <div class="my-10 font-weight-thin">
                                                            The program is ending on 26th September 2024
                                                        </div>
                                                    </v-expansion-panel-content>
                                                </v-expansion-panel>

                                                <v-expansion-panel>
                                                    <v-expansion-panel-header color="#EAEAEA">
                                                        <div class="font-weight-medium">What specialized skill will I learn during the programme?</div>
                                                    </v-expansion-panel-header>
                                                    <v-expansion-panel-content color="#FCFCFC">
                                                        <v-divider class="secondary"></v-divider>
                                                        <div class="my-10 font-weight-thin">
                                                            <ol>
                                                                <li>Prototyping and MVP Development</li>
                                                                <li>Investor Preparedness and Deal Structuring</li>
                                                                <li>Product Design</li>
                                                                <li>Customer Discovery and Validation</li>
                                                                <li>Business Modelling</li>
                                                                <li>Brand Development</li>
                                                                <li>Financial Modelling and Management</li>
                                                                <li>Sales and Marketing</li>
                                                                <li>Intellectual Property, Legal Processes and Structures</li>
                                                            </ol>
                                                        </div>
                                                    </v-expansion-panel-content>
                                                </v-expansion-panel>

                                                <v-expansion-panel>
                                                    <v-expansion-panel-header color="#EAEAEA">
                                                        <div class="font-weight-medium">What coaching sessions are available?</div>
                                                    </v-expansion-panel-header>
                                                    <v-expansion-panel-content color="#FCFCFC">
                                                        <v-divider class="primary"></v-divider>
                                                        <div class="my-10 font-weight-thin">
                                                            Coaching sessions are one to one meetings with inhouse coaches designed to address unique company needs. Tailored sessions include;
                                                        </div>
                                                        <div>
                                                            <ul>
                                                                <li>Pitching and presentation</li>
                                                                <li>Prototyping and MVP Development</li>
                                                                <li>Product Design</li>
                                                                <li>Brand Development</li>
                                                                <li>Financial Modeling and Budgeting</li>
                                                                <li>Deal Structuring and Negotiation</li>
                                                                <li>Sales and Marketing</li>
                                                                <li>Designing customer discovery and validation experiments</li>
                                                                <li>Business Modeling</li>
                                                                <li>Business MIntellectual Property, Legal processes and structuresodeling</li>
                                                            </ul>
                                                        </div>
                                                    </v-expansion-panel-content>
                                                </v-expansion-panel>

                                                <v-expansion-panel>
                                                    <v-expansion-panel-header color="#EAEAEA">
                                                        <div class="font-weight-medium">What is the Women in Tech Alumni Network?</div>
                                                    </v-expansion-panel-header>
                                                    <v-expansion-panel-content color="#FCFCFC">
                                                        <v-divider class="secondary"></v-divider>
                                                        <div class="my-10 font-weight-thin">
                                                            The Women in Tech Alumni Network is a network of companies who have participated in the Women in Tech Incubation Program.
                                                        </div>
                                                    </v-expansion-panel-content>
                                                </v-expansion-panel>

                                                
                                            </v-expansion-panels>
                                        </template>
                                    </div>
                                </v-flex>
                            </v-layout>
                        </v-flex>
                        <v-flex xs12 class="mb-16">
                            <div align="center">
                                <a :href="
                                    'mailto: adminibiz@strathmore.edu'
                                ">
                                <v-btn large depressed class="primary text-none">
                                    <v-icon left>mdi-gmail</v-icon>adminibiz@strathmore.edu
                                </v-btn>
                                </a>
                            </div>
                        </v-flex>
                    </v-layout>
                </v-container>
            </v-flex>

        </v-layout>
    </div>
</template>
<style>
/* <-- remove scoped here if you have it*/
.v-carousel__controls {
    background: #fff !important;
    border-radius: 25px;
}

.v-carousel__controls__item {
    color: #007932 !important;
}
</style>
<script>
import apiCall from "@/utils/api";
import { mapState, mapGetters, mapActions } from "vuex";
import Vue from "vue";

export default {
    components: {

    },
    data() {
        return {
            path: process.env.VUE_APP_API_URL,
            color: "",
            message: "",
            snackbar: false,
            drawer: false,

            absolute: true,
            overlay: true,
            opacity: 0.3,
        };
    },
    mounted() {
        window.onscroll = () => {
            this.changeColor();
        };
    },
    created() {
        window.scrollTo(0, 0);
        this.changeHeaderColor("white");
        this.changeTitleColor("primary--text");
        this.changeButtonColor("primary--text hidden-sm-and-down text-none caption");
    },
    methods: {
        ...mapActions([
            "changeHeaderColor",
            "changeTitleColor",
            "changeButtonColor"
        ]),
        changeColor() {
            if (document.body.scrollTop > 100 || document.documentElement.scrollTop > 100) {
                this.changeHeaderColor("primary");
                this.changeTitleColor("white--text");
                this.changeButtonColor("text-none mr-1 hidden-sm-and-down white--text");
                } else {
                this.changeHeaderColor("white");
                this.changeTitleColor("primary--text");
                this.changeButtonColor("text-none mr-1 hidden-sm-and-down white primary--text");
            }
        },
    },
    computed: {
        ...mapGetters(["darkState"]),
    },
};
</script>

<template>
    <div class="welcome">
      <v-layout column>
            <v-flex xs12>
                <v-img dark height="1100" src="img/banner-3.png">
                    <v-layout row wrap>
                      <v-flex xs4>
                        <div align="right" class="font-weight-black display-4 mt-10 mr-4 primary--text">
                          About
                        </div>
                        <div align="right" class="mt-2 display-2 mr-4 secondary--text">
                          Us
                        </div>
                      </v-flex>
                      <v-flex xs2>
                        <div align="left" class="display-4 black--text mt-7" style="margin-left: -50px;">
                          <v-icon size="160px" color="accent">
                            mdi-help
                          </v-icon>
                        </div>
                      </v-flex>
                    </v-layout>                    
                </v-img>
            </v-flex>
            <v-flex xs12 class="white">
                <v-container>
                  <div align="center" class="mt-15">
                    <v-card elevation="0" style="margin-top: -950px;" max-width="1000" class="pa-16">  
                        <div align="right" class="display-1 font-weight-black primary--text">
                          Why Women in CyberSec?
                        </div>
                        <div>
                          <v-layout row wrap>
                            <v-flex xs12 md3>
                              <div align="center">
                                <v-avatar class="primary my-5" size="200px">
                                  <v-img max-width="250" src="img/why.jpg"> </v-img>
                                </v-avatar>                          
                              </div>
                            </v-flex>
                            <v-flex xs12 md9>
                              <p class="grey--text text--darken-1 mt-3" align="right">
                                According to the (ISC)² Cybersecurity Workforce Study, women’s participation currently makes up approximately 24% of the cybersecurity workforce, with even fewer women holding
                                managerial or senior positions. In addition, the number of unfilled cybersecurity positions in 2021 was at 2.7 million (ITU, 2022).
                              </p>
                              <p class="grey--text text--darken-1" align="right">
                                The proposed Cyber Acceleration Program will aim to shift the mentality and tackle systemic barriers that prevent broader inclusion in the cybersecurity field, encourage women to take on leadership roles in cybersecurity, and promote knowledge sharing of professional best practices.
                              </p>
                            </v-flex>
                          </v-layout>
                        </div>
                        <div class="mt-16 mx-16">
                          <v-divider></v-divider>
                        </div>
                        <div align="left" class="display-1 font-weight-black secondary--text mt-12">
                          Standard Chartered Women in Cyber Security Program
                        </div>
                        <div>
                          <v-layout row wrap>
                            <v-flex xs12 md9>
                              <p class="grey--text text--darken-1 mt-4" align="left">
                                Technology proliferation and incorporation into most of the day-to-day activities of all people is being seen increasingly. It is thus becoming a basic skill at the onset of and for the practice of any career. With the use of technology and all the benefits of connection and efficiency,
                                necessarily comes the increased exposure and the need to keep organizations safe. Historically, women have been underrepresented in technology-related disciplines, particularly cybersecurity.
                              </p>
                              <p class="grey--text text--darken-1" align="left">
                                This begins early on, with fewer females being interested in technology at the grade-school level, which results in fewer women choosing cybersecurity as a profession in industry or academia. It is with this need in mind that @iLabAfrica – Strathmore University and Standard Chartered are partnering to run a program for Women in Cybersecurity.
                              </p>
                              <p class="grey--text text--darken-1" align="left">
                                Do you have any question (s) about the program?
                              </p>
                            </v-flex>
                            <v-flex xs12 md3>
                              <div align="center">
                                <v-avatar class="primary my-5" size="200px">
                                  <v-img max-width="250" src="img/ceo.jpg"> </v-img>
                                </v-avatar>                          
                              </div>
                            </v-flex>
                          </v-layout>
                        </div>
                    </v-card>
                  </div>
                </v-container>
            </v-flex>
        </v-layout>
    </div>
</template>
<script>
import apiCall from "@/utils/api";
import { mapState, mapGetters, mapActions } from "vuex";
import Vue from "vue";

export default {
  components: {
    
  },
  data() {
    return {
      path: process.env.VUE_APP_API_URL,
      color: "",
      message: "",
      snackbar: false,
      drawer: false,

      absolute: true,
      overlay: true,
      opacity: 0.3,
    };
  },
  mounted() {
    window.onscroll = () => {
      this.changeColor();
    };
  },
  created() {
    window.scrollTo(0, 0);
    this.changeNavLogo("img/logo-black.png");
    this.changeHeaderColor("white");
    this.changeTitleColor("primary--text");
    this.changeButtonColor("primary--text mr-1 hidden-sm-and-down text-none caption");
  },
  methods: {
    ...mapActions([
        "changeNavLogo",
        "changeHeaderColor",
        "changeTitleColor",
        "changeButtonColor"
    ]),
      changeColor() {
        if (document.body.scrollTop > 100 || document.documentElement.scrollTop > 100) {
          this.changeNavLogo("img/logo-white.png");
          this.changeHeaderColor("primary");
          this.changeTitleColor("white--text");
          this.changeButtonColor("text-none mr-1 hidden-sm-and-down white--text");
        } else {
          this.changeNavLogo("img/logo-black.png");
          this.changeHeaderColor("white");
          this.changeTitleColor("primary--text");
          this.changeButtonColor("text-none mr-1 hidden-sm-and-down white primary--text");
        }
      },
  },
  computed: {
    ...mapGetters(["darkState"]),
  },
};
</script>

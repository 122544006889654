<template>
  <div class="welcome">
    <v-layout column>
      <v-flex xs12>
        <div class="hidden-md-and-up" style="overflow: hidden;">
          <v-row align="center" justify="center">
            <v-parallax dark src="img/header.jpg" height="900">
              <v-layout row wrap>
                <v-flex xs12 md6>
                  <v-img class="mt-1" max-width="850" src="img/security.png" style="position: absolute; margin-bottom: -57%;" />
                </v-flex>
                <v-flex xs12 md6>
                  <v-row align="center" justify="center">
                    <v-col class="text-left mt-16" cols="12">
                      <div class="display-4 font-weight-black ml-5 mb-4">
                        Start Now!
                      </div>
                      <div class="subheading title ml-5">
                        Experience interchange and international collaboration among cybersecurity professionals.
                      </div>
                    </v-col>
                  </v-row>
                </v-flex>
              </v-layout>
              
            </v-parallax>
          </v-row>
        </div>
        <div class="hidden-sm-and-down">
          <v-parallax dark src="img/header.jpg" height="700">
            <v-layout row wrap>
              <v-flex xs12 md6>
                <v-img class="mt-16" max-width="850" src="img/security.png" style="position: absolute; margin-bottom: 0%;" />
              </v-flex>
              <v-flex xs12 md6>
                <v-row align="center" justify="center" class="mt-16">
                  <v-col class="text-left mt-16" cols="12">
                    <div class="display-4 font-weight-black mb-4 mt-16">
                      Start Now!
                    </div>
                    <div class="subheading title">
                      Experience interchange and international collaboration among cybersecurity professionals.
                    </div>
                  </v-col>
                </v-row>
              </v-flex>
            </v-layout>
            
          </v-parallax>
        </div>
      </v-flex>
      <v-flex xs12 class="white">
        <div align="center" class="display-1 font-weight-black primary--text mt-16">
          Why Women in Cybersecurity?
        </div>
      </v-flex>
   
      <v-flex xs12 class="white">
        <v-container class="my-1">
          <div class="mb-16">
            <v-layout row wrap class="mb-16">
              <v-flex xs12 md3>
                <v-layout column class="my-10 mx-3">
                      <v-flex xs12>
                        <div align="center">
                          <v-avatar size="190px" class="primary">
                            <v-icon size="80px" class="white--text">
                              mdi-account-child-circle
                            </v-icon>
                          </v-avatar>                          
                        </div>
                      </v-flex>
                      <v-flex xs12>
                        <div align="center" class="primary--text mt-5 title"
                          style="font-family: 'SC Prosper Sans Regular';">
                          Mentorship circles
                        </div>
                      </v-flex>
                      <v-flex xs12>
                        <div align="center" class="black--text caption">
                          One on one meetings between
                          the mentor and the mentee. The mentor will be acting as a
                          trusted ally who will be speaking to the mentees current
                          journey and how to achieve their set goals for the program
                        </div>
                      </v-flex>
                    </v-layout>
              </v-flex>
              <v-flex xs12 md3>
                <v-layout column class="my-10 mx-3">
                      <v-flex xs12>
                        <div align="center">
                          <v-avatar size="190px" class="primary">
                            <v-icon size="80px" class="white--text">
                              mdi-forum
                            </v-icon>
                          </v-avatar>                          
                        </div>
                      </v-flex>
                      <v-flex xs12>
                        <div align="center" class="primary--text mt-5 title"
                          style="font-family: 'SC Prosper Sans Regular';">
                          Fireside chats
                        </div>
                      </v-flex>
                      <v-flex xs12>
                        <div align="center" class="black--text caption">
                          This will be interactive sessions, held to discuss cybersecurity interest areas with industrial players in the region. The chats will be an opportunity to demystify some of the current cybersecurity challenges.
                        </div>
                      </v-flex>
                    </v-layout>
              </v-flex>
              <v-flex xs12 md3>
                <v-layout column class="my-10 mx-3">
                      <v-flex xs12>
                        <div align="center">
                          <v-avatar size="190px" class="primary">
                            <v-icon size="80px" class="white--text">
                              mdi-human-male-board-poll
                            </v-icon>
                          </v-avatar>                          
                        </div>
                      </v-flex>
                      <v-flex xs12>
                        <div align="center" class="primary--text mt-5 title"
                          style="font-family: 'SC Prosper Sans Regular';">
                          Technical Training
                        </div>
                      </v-flex>
                      <v-flex xs12>
                        <div align="center" class="black--text caption">
                          The technical training will be
                          structured in a self-paced model. The trainers selected for this program are subject matter experts with vast knowledge
                          and experience in the cybersecurity domain.
                        </div>
                      </v-flex>
                    </v-layout>
              </v-flex>
              <v-flex xs12 md3>
                <v-layout column class="my-10 mx-3">
                      <v-flex xs12>
                        <div align="center">
                          <v-avatar size="190px" class="primary">
                            <v-icon size="80px" class="white--text">
                              mdi-account-voice
                            </v-icon>
                          </v-avatar>                          
                        </div>
                      </v-flex>
                      <v-flex xs12>
                        <div align="center" class="primary--text mt-5 title"
                          style="font-family: 'SC Prosper Sans Regular';">
                          Soft skill Training
                        </div>
                      </v-flex>
                      <v-flex xs12>
                        <div align="center" class="black--text caption">
                          The aim of these training will be to equip teh mentees with soft skills which are a requirement for the everyday work life. They will cover issues such as work life balance, interviewing and CV writing skills.
                        </div>
                      </v-flex>
                    </v-layout>
              </v-flex>
            </v-layout>
          </div>
          <div align="center" class="mb-10">
            <v-btn outlined class="text-none secondary--text" router to="/about">
              Learn More
              <v-icon right>mdi-information-variant</v-icon>
            </v-btn>
          </div>
        </v-container>
      </v-flex>

      <v-flex xs12 class="primary">
        <div class="primary--text mt-16">.</div>
        <div align="center" class="display-1 font-weight-black white--text mt-16">
          Ready to Start The Standard Chartered Women in Cyber Security Program?
        </div>
        <div align="center">
          <v-btn elevation="0" class="text-none white primary--text mt-2 mb-16" router to="/about">
            Get Started
            <v-icon right>mdi-arch</v-icon>
          </v-btn>
        </div>
        <div class="primary--text mb-16">.</div>
      </v-flex>
      <v-flex xs12 class="background">
        <v-container class="my-16">
          <div class="mb-16">
            <v-layout row wrap class="mb-16">
              <v-flex xs12 md4>
                <v-layout column class="my-10 mx-3">                      
                      <v-flex xs12>
                        <div align="center" class="primary--text mt-10 headline font-weight-black"
                          style="font-family: 'SC Prosper Sans Regular';">
                          Our Mentors
                        </div>
                      </v-flex>
                      <v-flex xs12>
                        <div align="center" class="black--text title mb-8">
                          Our mentors will be acting as trusted allies who will be speaking to the mentees current journey and how to achieve their set goals for the program
                        </div>
                      </v-flex>
                      <v-flex xs12>
                        <div align="center">
                          <v-avatar class="primary mt-8" size="250px">
                            <v-img max-width="250" src="img/mentor_main.jpg"> </v-img>
                          </v-avatar>                          
                        </div>
                      </v-flex>
                    </v-layout>
              </v-flex>
              <v-flex xs12 md4>
                <v-layout column class="my-10 mx-3">  
                  <v-flex xs12>
                        <div align="center">
                          <v-avatar class="primary mt-8" size="300px">
                            <v-img max-width="300" src="img/world-class.jpg"> </v-img>
                          </v-avatar>                          
                        </div>
                      </v-flex>                    
                      <v-flex xs12>
                        <div align="center" class="primary--text mt-5 headline font-weight-black"
                          style="font-family: 'SC Prosper Sans Regular';">
                          Our Cybersecurity consultants and Mentors have real world experience
                        </div>
                      </v-flex>
                      
                    </v-layout>
              </v-flex>
              <v-flex xs12 md4>
                <v-layout column class="my-10 mx-3">                      
                      <v-flex xs12>
                        <div align="center" class="primary--text mt-10 headline font-weight-black"
                          style="font-family: 'SC Prosper Sans Regular';">
                          Our Mentees
                        </div>
                      </v-flex>
                      <v-flex xs12>
                        <div align="center" class="black--text title">
                          The programme calls for gender diversity in cybersecurity and
                          encourages women in their early careers to take up roles in
                          cybersecurity by exposing them to technical and soft skill training
                          as well as providing interactive sessions to hear from other leaders
                          in the industry.
                        </div>
                      </v-flex>
                      <v-flex xs12>
                        <div align="center">
                          <v-avatar class="primary mt-8" size="200px">
                            <v-img max-width="200" src="img/linet_mentee.jpg"> </v-img>
                          </v-avatar>                          
                        </div>
                      </v-flex>
                    </v-layout>
              </v-flex>
            </v-layout>
          </div>          
        </v-container>
      </v-flex>
      <v-flex xs12 class="white">
        <v-container class="my-16">
          <v-carousel
            cycle
            height="700"
            hide-delimiter-background
            show-arrows-on-hover
            class="mb-15"
          >
            <v-carousel-item
              v-for="(slide, i) in slides"
              :key="i"

            >
           
              <div align="center" >
                <v-img :src="'img/'+slide"/>
              </div>
              
            </v-carousel-item>
          </v-carousel>
        </v-container>
      </v-flex>
    </v-layout>
  </div>
</template>
<style>
/*option 1:this makes the video responsive*/
.videoDiv {
  width: 70%;
  /*or whatever % you prefer*/
  margin: 0 auto;
  display: block;
}

/* option 2* does not make the video responsive*/

.videoDiv {
  margin: 0 auto;
  display: block;
}
</style>
<script>
import apiCall from "@/utils/api";
import { mapState, mapGetters, mapActions } from "vuex";
import Vue from "vue";
import VClamp from "vue-clamp";

export default {
  components: {
    VClamp
  },
  data() {
    return {
      path: process.env.VUE_APP_API_URL,
      color: "",
      message: "",
      snackbar: false,
      drawer: false,

      absolute: true,
      overlay: true,
      opacity: 0.3,

      originalArray: [],
      publicNewsFeedArray: [],

      model: 0,
      slides: [
        'data-protection-and-gdrp-banner.png',
        'interview-skills-and-cv-writing-banner.png',
        'the-wheel-of-life-banner.png',
        'dr.-bright-gameli-webinar-banner.png',
        'wic-graduation-banner-2-1.png',
      ],
    };
  },
  watch: {
    publicNewsFeeds() {
      this.chunkPublicNewsFeeds();
    },
  },
  mounted() {
    window.onscroll = () => {
      this.changeColor();
    };
  },
  created() {
    window.scrollTo(0, 0);
    this.changeNavLogo("img/logo-black.png");
    this.changeHeaderColor("white");
    this.changeTitleColor("primary--text");
    this.changeButtonColor("primary--text mr-1 hidden-sm-and-down text-none caption");

    // this.fetchPublicNewsFeeds(this.publicNewsFeedPagination.current_page)

    if (this.publicNewsFeeds.length != 0) {
      this.chunkPublicNewsFeeds()
    }

  },
  methods: {
    ...mapActions([
      "changeNavLogo",
      "changeHeaderColor",
      "changeTitleColor",
      "changeButtonColor",

      "fetchPublicNewsFeeds"
    ]),
    changeColor() {
      if (document.body.scrollTop > 100 || document.documentElement.scrollTop > 100) {
        this.changeNavLogo("img/logo-black.png");
        this.changeHeaderColor("white");
        this.changeTitleColor("primary--text");
        this.changeButtonColor("text-none mr-1 hidden-sm-and-down primary--text");
      } else {
        this.changeNavLogo("img/logo-black.png");
        this.changeHeaderColor("transparent");
        this.changeTitleColor("primary--text");
        this.changeButtonColor("text-none mr-1 hidden-sm-and-down primary--text");
      }
    },
    removeTags(str) {
      if ((str === null) || (str === ''))
        return false;
      else
        str = str.toString();
      return str.replace(/(<([^>]+)>)/ig, '');
    },
    chunkPublicNewsFeeds() {
      this.publicNewsFeedArray = [];

      for (var i = 0; i < this.publicNewsFeeds.length; i++) {
        this.originalArray.push(this.publicNewsFeeds[i])
      }

      let result = [];
      for (let i = 3; i > 0; i--) {
        result.push(this.originalArray.splice(0, Math.ceil(this.originalArray.length / i)));
      }
      this.publicNewsFeedArray = result;
    },

  },
  computed: {
    ...mapGetters(["darkState", "publicNewsFeeds", "publicNewsFeedPagination"]),
  },
};
</script>

<template>
    <div class="welcome">
        <v-layout column>
            <v-flex xs12>
                <v-parallax dark height="250" src="img/image.jpg">
                    <div align="center" class="font-weight-black display-1">
                        Coaches
                    </div>
                </v-parallax>
            </v-flex>
            <v-flex xs12 class="white">
                <v-container class="mt-12">
                    <v-layout column>
                        <v-flex xs12>
                            <v-layout row wrap>
                                <v-flex xs12 md3>
                                    <v-hover v-slot="{ hover }">
                                        <v-card class="mx-1 mt-8" color="grey lighten-4" max-width="600" elevation="0">
                                            <v-img :aspect-ratio="0.9778" src="img/coaches/Denis-Gichuki-Kihohia.jpg">
                                                <v-expand-transition>
                                                    <div v-if="hover"
                                                        class="d-flex transition-fast-in-fast-out secondary darken-2 v-card--reveal white--text"
                                                        style="height: 100%;">
                                                        DEAN DENIS GICHUKI KIHOHIA
                                                    </div>
                                                </v-expand-transition>
                                            </v-img>
                                            <v-card-text class="pt-6" style="position: relative;">
                                                <div class="font-weight-light primary--text mb-2">
                                                    DEAN DENIS GICHUKI KIHOHIA
                                                </div>
                                                <v-divider class="my-5 secondary"></v-divider>
                                                <div class="font-weight-light mb-2">
                                                    Marketing and Business Development Expert
                                                </div>
                                            </v-card-text>
                                        </v-card>
                                    </v-hover>
                                </v-flex>
                                
                                <v-flex xs12 md3>
                                    <v-hover v-slot="{ hover }">
                                        <v-card class="mx-1 mt-8" color="grey lighten-4" max-width="600" elevation="0">
                                            <v-img :aspect-ratio="0.9778" src="img/coaches/Caroline-Muchiri.jpg">
                                                <v-expand-transition>
                                                    <div v-if="hover"
                                                        class="d-flex transition-fast-in-fast-out secondary darken-2 v-card--reveal white--text"
                                                        style="height: 100%;">
                                                        CAROLINE MUCHIRI
                                                    </div>
                                                </v-expand-transition>
                                            </v-img>
                                            <v-card-text class="pt-6" style="position: relative;">
                                                <div class="font-weight-light primary--text mb-2">
                                                    CAROLINE MUCHIRI
                                                </div>
                                                <v-divider class="my-5 secondary"></v-divider>
                                                <div class="font-weight-light mb-2">
                                                    Intellectual Property Law and Corporate Commercial Law Practitioner
                                                </div>
                                            </v-card-text>
                                        </v-card>
                                    </v-hover>
                                </v-flex>
                                <v-flex xs12 md3>
                                    <v-hover v-slot="{ hover }">
                                        <v-card class="mx-1 mt-8" color="grey lighten-4" max-width="600" elevation="0">
                                            <v-img :aspect-ratio="0.9778" src="img/coaches/Enos-Masinde.jpg">
                                                <v-expand-transition>
                                                    <div v-if="hover"
                                                        class="d-flex transition-fast-in-fast-out secondary darken-2 v-card--reveal white--text"
                                                        style="height: 100%;">
                                                        ENOS MASINDE
                                                    </div>
                                                </v-expand-transition>
                                            </v-img>
                                            <v-card-text class="pt-6" style="position: relative;">
                                                <div class="font-weight-light primary--text mb-2">
                                                    ENOS MASINDE
                                                </div>
                                                <v-divider class="my-5 secondary"></v-divider>
                                                <div class="font-weight-light mb-2">
                                                    Founder, CEO Mass Inspire
                                                </div>
                                            </v-card-text>
                                        </v-card>
                                    </v-hover>
                                </v-flex>
                                <v-flex xs12 md3>
                                    <v-hover v-slot="{ hover }">
                                        <v-card class="mx-1 mt-8" color="grey lighten-4" max-width="600" elevation="0">
                                            <v-img :aspect-ratio="0.9778" src="img/coaches/Kimathi-Ikiao-1.jpg">
                                                <v-expand-transition>
                                                    <div v-if="hover"
                                                        class="d-flex transition-fast-in-fast-out secondary darken-2 v-card--reveal white--text"
                                                        style="height: 100%;">
                                                        KIMATHI IKIAO
                                                    </div>
                                                </v-expand-transition>
                                            </v-img>
                                            <v-card-text class="pt-6" style="position: relative;">
                                                <div class="font-weight-light primary--text mb-2">
                                                    KIMATHI IKIAO
                                                </div>
                                                <v-divider class="my-5 secondary"></v-divider>
                                                <div class="font-weight-light mb-2">
                                                    Investment Professional
                                                </div>
                                            </v-card-text>
                                        </v-card>
                                    </v-hover>
                                </v-flex>
                            </v-layout>
                        </v-flex>
                        <v-flex xs12 class="mb-8">
                            <v-layout row wrap>
                                
                                <v-flex xs12 md3>
                                    <v-hover v-slot="{ hover }">
                                        <v-card class="mx-1 mt-8" color="grey lighten-4" max-width="600" elevation="0">
                                            <v-img :aspect-ratio="0.9778" src="img/coaches/Bikundo-onyari.jpg">
                                                <v-expand-transition>
                                                    <div v-if="hover"
                                                        class="d-flex transition-fast-in-fast-out secondary darken-2 v-card--reveal white--text"
                                                        style="height: 100%;">
                                                        BIKUNDO ONYARI
                                                    </div>
                                                </v-expand-transition>
                                            </v-img>
                                            <v-card-text class="pt-6" style="position: relative;">
                                                <div class="font-weight-light primary--text mb-2">
                                                    BIKUNDO ONYARI
                                                </div>
                                                <v-divider class="my-5 secondary"></v-divider>
                                                <div class="font-weight-light mb-2">
                                                    A self-taught social entrepreneur.
                                                </div>
                                                
                                            </v-card-text>
                                        </v-card>
                                    </v-hover>
                                </v-flex>
                                <v-flex xs12 md3>
                                    <v-hover v-slot="{ hover }">
                                        <v-card class="mx-1 mt-8" color="grey lighten-4" max-width="600" elevation="0">
                                            <v-img :aspect-ratio="0.9778" src="img/coaches/Peter-nyambura.jpg">
                                                <v-expand-transition>
                                                    <div v-if="hover"
                                                        class="d-flex transition-fast-in-fast-out secondary darken-2 v-card--reveal white--text"
                                                        style="height: 100%;">
                                                        PETER NYAMBURA
                                                    </div>
                                                </v-expand-transition>
                                            </v-img>
                                            <v-card-text class="pt-6" style="position: relative;">
                                                <div class="font-weight-light primary--text mb-2">
                                                    PETER NYAMBURA
                                                </div>
                                                <v-divider class="my-5 secondary"></v-divider>
                                                <div class="font-weight-light mb-2">
                                                    Assistant lecturer at Strathmore University teaching mobile programming units
                                                </div>
                                            </v-card-text>
                                        </v-card>
                                    </v-hover>
                                </v-flex>
                                
                                <v-flex xs12 md3>
                                    <v-hover v-slot="{ hover }">
                                        <v-card class="mx-1 mt-8" color="grey lighten-4" max-width="600" elevation="0">
                                            <v-img :aspect-ratio="0.9778" src="img/coaches/Adelaide-njoki-1.jpg">
                                                <v-expand-transition>
                                                    <div v-if="hover"
                                                        class="d-flex transition-fast-in-fast-out secondary darken-2 v-card--reveal white--text"
                                                        style="height: 100%;">
                                                        ADELAIDE NJOKI
                                                    </div>
                                                </v-expand-transition>
                                            </v-img>
                                            <v-card-text class="pt-6" style="position: relative;">
                                                <div class="font-weight-light primary--text mb-2">
                                                    ADELAIDE NJOKI
                                                </div>
                                                <v-divider class="my-5 secondary"></v-divider>
                                                <div class="font-weight-light mb-2">
                                                    Finance & Economics
                                                </div>
                                            </v-card-text>
                                        </v-card>
                                    </v-hover>
                                </v-flex>
                                <v-flex xs12 md3>
                                    <v-hover v-slot="{ hover }">
                                        <v-card class="mx-1 mt-8" color="grey lighten-4" max-width="600" elevation="0">
                                            <v-img :aspect-ratio="0.9778" src="img/coaches/Irene-mwihaki.jpg">
                                                <v-expand-transition>
                                                    <div v-if="hover"
                                                        class="d-flex transition-fast-in-fast-out secondary darken-2 v-card--reveal white--text"
                                                        style="height: 100%;">
                                                        IRENE MWIHAKI
                                                    </div>
                                                </v-expand-transition>
                                            </v-img>
                                            <v-card-text class="pt-6" style="position: relative;">
                                                <div class="font-weight-light primary--text mb-2">
                                                    IRENE MWIHAKI
                                                </div>
                                                <v-divider class="my-5 secondary"></v-divider>
                                                <div class="font-weight-light mb-2">
                                                    Has interests in FinTech, Professional Services and FMCG trading in Kenya and previously in Zambia.
                                                </div>
                                            </v-card-text>
                                        </v-card>
                                    </v-hover>
                                </v-flex>
                            </v-layout>
                        </v-flex>
                        
                        
                    </v-layout>
                    
                </v-container>
            </v-flex>
        </v-layout>
    </div>
</template>
<style>
.v-card--reveal {
    align-items: center;
    bottom: 0;
    justify-content: center;
    opacity: .5;
    position: absolute;
    width: 100%;
}
</style>
<script>
import apiCall from "@/utils/api";
import { mapState, mapGetters, mapActions } from "vuex";
import Vue from "vue";

export default {
    components: {
        
    },
    data() {
        return {
            path: process.env.VUE_APP_API_URL,
            color: "",
            message: "",
            snackbar: false,
            drawer: false,

            absolute: true,
            overlay: true,
            opacity: 0.3,
        };
    },
    mounted() {
        window.onscroll = () => {
            this.changeColor();
        };
    },
    created() {
        window.scrollTo(0, 0);
        this.changeHeaderColor("white");
        this.changeTitleColor("primary--text");
        this.changeButtonColor("primary--text hidden-sm-and-down text-none caption");
    },
    methods: {
        ...mapActions([
            "changeHeaderColor",
            "changeTitleColor",
            "changeButtonColor"]),
        changeColor() {
            if (document.body.scrollTop > 100 || document.documentElement.scrollTop > 100) {
                this.changeHeaderColor("primary");
                this.changeTitleColor("white--text");
                this.changeButtonColor("text-none mr-1 hidden-sm-and-down white--text");
                } else {
                this.changeHeaderColor("white");
                this.changeTitleColor("primary--text");
                this.changeButtonColor("text-none mr-1 hidden-sm-and-down white primary--text");
            }
        },
    },
    computed: {
        ...mapGetters(["darkState"]),
    },
};
</script>

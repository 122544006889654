<template>
	<div class="welcome">
		<v-snackbar v-model="snackbar" :timeout="4000" bottom right :color="color">
			<span>{{ message }}</span>
		</v-snackbar>
		<v-dialog
            transition="dialog-bottom-transition"
            max-width="600"
            v-model="pdfViewDialog"
        >
            <v-card max-width="600">
				<v-toolbar flat>
					{{ docType }}
					<v-spacer></v-spacer>
					<v-btn icon @click="pdfViewDialog = false">
						<v-icon class="primary--text">mdi-close</v-icon>
					</v-btn>
				</v-toolbar>
                <v-card-text>                    
					<div align="right">
						<v-btn icon @click="$refs.myPdfComponent.print()"><v-icon class="primary--text">mdi-printer</v-icon></v-btn>
						<v-btn icon @click="deleteCV()" :loading="certLoading"><v-icon class="red--text">mdi-delete</v-icon></v-btn>
					</div>
					<pdf v-if="docType == 'CV'"
						ref="myPdfComponent"
						:src="path+'/storage/docs/'+ newUser.curriculum_vitae"
						@num-pages="pageCount = $event"
						@page-loaded="currentPage = $event"
					></pdf>
					<pdf v-else
						ref="myPdfComponent"
						:src="path+'/storage/docs/'+ newUser.curriculum_vitae"
						@num-pages="pageCount = $event"
						@page-loaded="currentPage = $event"
					></pdf>
                </v-card-text>
            </v-card>
        </v-dialog>
		<v-dialog transition="dialog-bottom-transition" max-width="600" v-model="pdfDialog">
			<v-card max-width="600">
				<v-toolbar flat>
					Upload {{ docType }}
					<v-spacer></v-spacer>
					<v-btn icon @click="pdfDialog = false">
						<v-icon>mdi-close</v-icon>
					</v-btn>
				</v-toolbar>

				<v-form ref="pdfForm" v-model="pdfValid" lazy-validation>
					<v-layout column>
						<v-flex xs12>
							<v-file-input class="mx-1 mt-3" :loading="pdfLoading" :rules="fileRules" filled dense show-size chips
								accept=".pdf" v-model="pdfFile" label="Select PDF"></v-file-input>
						</v-flex>
						<v-flex xs12>
							<div align="right" class="mb-3 mx-3">
								<v-btn depressed block class="primary text-none" @click="uploadPDF" :loading="pdfLoading"
									:disabled="!pdfValid">
									Submit <v-icon right dark>mdi-upload</v-icon>
								</v-btn>
							</div>
						</v-flex>
					</v-layout>
				</v-form>
			</v-card>
		</v-dialog>
		<v-dialog transition="dialog-bottom-transition" max-width="600" v-model="imageDialog">
			<v-card max-width="600">
				<v-toolbar flat>
					Upload Picture
					<v-spacer></v-spacer>
					<v-btn icon @click="imageDialog = false">
						<v-icon>mdi-close</v-icon>
					</v-btn>
				</v-toolbar>

				<v-form ref="imageForm" v-model="imageValid" lazy-validation>
					<v-layout column>
						<v-flex xs12>
							<v-file-input class="mx-1 mt-3" :loading="imageLoading" :rules="fileRules" filled dense show-size chips
								accept="image/*" v-model="imageFile" label="Select Image"></v-file-input>
						</v-flex>
						<v-flex xs12>
							<div align="right" class="mb-3 mx-3">
								<v-btn depressed block class="primary text-none" @click="uploadImage" :loading="imageLoading"
									:disabled="!imageValid">
									Submit <v-icon right dark>mdi-upload</v-icon>
								</v-btn>
							</div>
						</v-flex>
					</v-layout>
				</v-form>
			</v-card>
		</v-dialog>
		<v-layout column>
			<v-flex xs12>
				<v-img dark height="1100" src="img/banner.png">
					<v-layout row wrap>
						<v-flex xs10>
							<div align="right" class="font-weight-black display-4 mt-10 primary--text">
								Apply
							</div>
							<div align="right" class="mt-2 display-1 secondary--text">
								Now
							</div>
						</v-flex>
						<v-flex xs2>
							<div align="left" class="display-4 black--text mt-7" style="margin-left: -50px;">
								<v-icon size="160px" color="accent">
									mdi-exclamation-thick
								</v-icon>
							</div>
						</v-flex>
					</v-layout>					
				</v-img>
			</v-flex>
			<v-flex xs12 class="white">
				<v-container>
					<div class="my-16" align="center">
						<v-card elevation="0" style="margin-top: -950px;" max-width="1000">
							<div v-if="openForm==false">
							<div v-if="formData == null">
								<v-container style="height: 550px;">
									<div align="center" class="my-16">
										<v-img max-width="300" src="img/logo.svg"> </v-img>
									</div>
									<v-card-text class="mb-16">
									<v-row class="fill-height" align-content="center" justify="center">
										<v-col class="text-subtitle-1 text-center" cols="12">
											Getting ready..
										</v-col>
										<v-col cols="6">
											<v-progress-linear color="primary" indeterminate rounded height="6"></v-progress-linear>
										</v-col>
									</v-row>
									</v-card-text>
								</v-container>
							</div>
							<div v-else>
								<v-container style="height: 550px;">						
									<v-layout column>
										<v-flex xs12>
											<div align="center" class="my-5">
												<v-img max-width="300" src="img/logo.svg"> </v-img>
											</div>
											<div class="hidden-md-and-up">
												<div align="center" class="title font-weight-black ml-7 mr-7 mt-5" style="font-family: 'SC Prosper Sans Regular';">
													<v-layout column>
														<v-flex xs12>
															<b>Welcome to the Women In CyberSec {{ programDetails.program.name }} Program</b>
														</v-flex>
														<v-flex xs12 class="mt-8">
															<v-layout row wrap>
																<v-flex xs12 md4></v-flex>
																<v-flex xs12 md4>
																	<v-divider class="primary mx-16"></v-divider>
																	<v-divider class="secondary mx-16"></v-divider>
																</v-flex>
																<v-flex xs12 md4></v-flex>
															</v-layout>
														</v-flex>
													</v-layout>														
												</div>
											</div>
											<div class="hidden-sm-and-down">
												<div align="center" class="display-1 font-weight-black ml-7 mr-7 mt-8" style="font-family: 'SC Prosper Sans Regular';">
													<v-layout column>
														<v-flex xs12>
															<b>Welcome to the Women In CyberSec {{ programDetails.program.name }} Program</b>
														</v-flex>
														<v-flex xs12 class="mt-8">
															<v-layout row wrap>
																<v-flex xs12 md4></v-flex>
																<v-flex xs12 md4>
																	<v-divider class="primary mx-16"></v-divider>
																	<v-divider class="secondary mx-16"></v-divider>
																</v-flex>
																<v-flex xs12 md4></v-flex>
															</v-layout>
														</v-flex>
													</v-layout>														
												</div>
											</div>
										</v-flex>
										<v-flex xs12>
											<div v-if="programDetails.application == 0">
												<div class="mt-16" align="center">
													We regret to inform you that applications closed on <b>{{ programDetails.program.application_deadline | moment("Do MMM YYYY")}}</b>.
												</div>
												<div align="center"> However, don’t lose hope! Keep an eye on our social media channels for <b>exciting updates and program-related news.</b></div>
												<div align="center"> There might be other opportunities waiting for you. Stay tuned! 🌟📅🔍</div>
												<div align="center" class="mt-5 mb-10">
													<template>
													<a
														target="_blank"
														href="https://www.facebook.com/iBizAfrica"
														style="text-decoration: none"
													>
														<v-btn class="mx-2" icon>
														<v-icon size="25px" color="#3b5998"> mdi-facebook </v-icon>
														</v-btn>
													</a>
													<a
														target="_blank"
														href="https://twitter.com/iBizAfrica"
														style="text-decoration: none"
													>
														<v-btn class="mx-2" icon>
														<v-icon size="25px" color="#1DA1F2"> mdi-twitter </v-icon>
														</v-btn>
													</a>
													<a
														target="_blank"
														href="https://www.youtube.com/c/iLabAfricaSU/videos"
														style="text-decoration: none"
													>
														<v-btn class="mx-2" icon>
														<v-icon size="25px" color="#cc181e"> mdi-youtube </v-icon>
														</v-btn>
													</a>
													<a
														target="_blank"
														href="https://www.instagram.com/iBizAfrica/"
														style="text-decoration: none"
													>
														<v-btn class="mx-2" icon>
														<v-icon size="25px" color="#8a3ab9"> mdi-instagram </v-icon>
														</v-btn>
													</a>
													</template>
												</div>
											</div>
											<div v-else>
												<div align="center" class="mt-16 mb-2"><b>Program Details</b></div>
												<div class="mt-6">
													<v-layout row wrap>
														<v-flex xs12 md3>

														</v-flex>
														<v-flex xs12 md6>
															<v-layout row wrap>
																<v-flex xs7 md6>
																	<div align="right">
																		<b class="ml-4">Application Deadline:</b>
																	</div>
																</v-flex>
																<v-flex xs5 md6>
																	<div align="right" class="mr-4">
																		{{ programDetails.program.application_deadline | moment("Do MMM YYYY")}}
																	</div>
																</v-flex>
															</v-layout>
														</v-flex>
														<v-flex xs12 md3>

														</v-flex>
													</v-layout>
												</div>
												<div class="mt-8">
													<v-layout row wrap>
														<v-flex xs12 md3>

														</v-flex>
														<v-flex xs12 md6>
															<v-layout row wrap>
																<v-flex xs7 md6>
																	<div align="right">
																		<b class="ml-4">Start Date:</b>
																	</div>																	
																</v-flex>
																<v-flex xs5 md6>
																	<div align="right" class="mr-4">
																		{{ programDetails.program.start_date | moment("Do MMM YYYY")}}
																	</div>
																</v-flex>
															</v-layout>
														</v-flex>
														<v-flex xs12 md3>

														</v-flex>
													</v-layout>
												</div>
												<div class="mt-8 mb-3">
													<v-layout row wrap>
														<v-flex xs12 md3>

														</v-flex>
														<v-flex xs12 md6>
															<v-layout row wrap>
																<v-flex xs7 md6>
																	<div align="right">
																		<b class="ml-4">End Date:</b>
																	</div>
																</v-flex>
																<v-flex xs5 md6>
																	<div align="right" class="mr-4">
																		{{ programDetails.program.end_date | moment("Do MMM YYYY")}}
																	</div>
																</v-flex>
															</v-layout>
														</v-flex>
														<v-flex xs12 md3>

														</v-flex>
													</v-layout>
												</div>
												<div align="center" class="mb-7">
													<v-btn depressed class="text-none mt-5 secondary white--text" @click="openForm = true">
														Get Started <v-icon right>mdi-chevron-right</v-icon>
													</v-btn>	
												</div>
											</div>
										</v-flex>
									</v-layout>
								
								</v-container>
							</div>
						</div>
						<div v-else>
							<v-stepper alt-labels v-model="e1" elevation="0" style="border-radius: 25px;">
								<v-stepper-header>
									<v-stepper-step :complete="e1 > 1" step="1">
										Preferred Role
									</v-stepper-step> 

									<v-divider></v-divider>

									<v-stepper-step :complete="e1 > 2" step="2">
										Personal Details
									</v-stepper-step>

									<v-divider></v-divider>

									<v-stepper-step :complete="e1 > 3" step="3">
										Questions
									</v-stepper-step>

									<v-divider></v-divider>

									<v-stepper-step :complete="e1 > 4" step="4">
										Commitment Statement
									</v-stepper-step>
								</v-stepper-header>

								<v-stepper-items>
									<v-stepper-content step="1">
										<v-layout column>
											<v-flex xs12>
												<div class="mx-1 mt-5">
													<div>What Role would you like to play in the Program?<span class="red--text">*</span>
													</div>
													<v-radio-group v-model="newUser.role" :rules="inputRules">
														<v-radio
															label="Mentor"
															value="Mentor"
														></v-radio>
														<v-radio
															label="Mentee"
															value="Mentee"
														></v-radio>
													</v-radio-group>
												</div>
											</v-flex>
											<v-flex xs12>
												<div align="right">
													<v-btn depressed class="primary text-none mb-3 mt-10" @click="pickRole()">
														Next <v-icon right dark>mdi-chevron-right</v-icon>
													</v-btn>
												</div>
											</v-flex>
											
										</v-layout>
									</v-stepper-content>
									<v-stepper-content step="2">
										<div v-if="formData == null">
											<v-container style="height: 400px;">
												<v-row class="fill-height" align-content="center" justify="center">
													<v-col class="text-subtitle-1 text-center" cols="12">
														Getting ready..
													</v-col>
													<v-col cols="6">
														<v-progress-linear color="primary" indeterminate rounded height="6"></v-progress-linear>
													</v-col>
												</v-row>
											</v-container>
										</div>
										<div v-else>
											<v-layout row wap>
												<v-flex xs12 md3>
													<v-layout column>
														<v-flex xs12>
															<div align="center" class="mt-10">
																<v-avatar size="200" class="primary title white--text" v-if="getProfile.prof_pic == null">
																	{{ getProfile.first_name[0] }} {{ getProfile.last_name[0] }}
																</v-avatar>
																<v-avatar size="200" class="primary title" v-else>
																	<img :src="path + '/storage/profile_pics/' + getProfile.prof_pic">
																</v-avatar>
															</div>
														</v-flex>
														<v-flex xs12>
															<div align="center">
																<v-btn depressed class="text-none mt-5 secondary white--text" @click="imageDialog = true">
																	Upload Profile Pic
																</v-btn>
															</div>
														</v-flex>
													</v-layout>
												</v-flex>
												<v-flex xs12 md9>
													<v-layout column>												
														<v-flex xs12>
															<div class="mx-9 mt-5">
																<v-form ref="profileForm" v-model="personalValid" lazy-validation>
																	<v-layout row wrap>
																		<v-flex xs12 md4>
																			<div class="mx-1 mt-5">
																				<div align="left">First Name<span class="red--text">*</span>
																				</div>
																				<v-text-field outlined dense v-model="newUser.first_name" :rules="inputRules"
																					class="text_field background" prepend-inner-icon="mdi-account"></v-text-field>
																			</div>
																		</v-flex>
																		<v-flex xs12 md4>
																			<div class="mx-1 mt-5">
																				<div align="left">Middle Name</div>
																				<v-text-field outlined dense v-model="newUser.middle_name"
																					class="text_field background" prepend-inner-icon="mdi-account"></v-text-field>
																			</div>
																		</v-flex>
																		<v-flex xs12 md4>
																			<div class="mx-1 mt-5">
																				<div align="left">Last Name<span class="red--text">*</span></div>
																				<v-text-field outlined dense v-model="newUser.last_name" :rules="inputRules"
																					class="text_field background" prepend-inner-icon="mdi-account"></v-text-field>
																				
																			</div>
																		</v-flex>
																		<v-flex xs12 md4>
																			<div class="mx-1 mt-5">
																				<div align="left">Email<span class="red--text">*</span></div>
																				<v-text-field outlined dense v-model="newUser.email" :rules="inputRules"
																					class="text_field background" disabled
																					prepend-inner-icon="mdi-email"></v-text-field>
																			</div>
																		</v-flex>
																		<v-flex xs12 md4>
																			<div class="mx-1 mt-5">
																				<div align="left">Phone Number<span class="red--text">*</span>
																				</div>
																				<v-text-field outlined dense v-model="newUser.phone" :rules="inputRules"
																					class="text_field background" prepend-inner-icon="mdi-phone"></v-text-field>
																			</div>
																		</v-flex>
																		<v-flex xs12 md4>
																			<div class="mx-1 mt-5">
																				<div align="left">Gender<span class="red--text">*</span></div>
																				<v-select :items="formData.genders" item-text="name" item-value="id"
																					v-model="newUser.gender_id" :rules="inputRules" class="text_field background" dense
																					outlined prepend-inner-icon="mdi-gender-male-female">
																				</v-select>
																			</div>
																		</v-flex>
																		<v-flex xs12 md4>
																			<div class="mx-1 mt-5">
																				<div align="left">Nationality<span class="red--text">*</span>
																				</div>
																				<v-select :items="formData.countries" item-text="name" item-value="id"
																					v-model="newUser.country_id" :rules="inputRules" class="text_field background" dense
																					outlined prepend-inner-icon="mdi-flag" @input="populateStates()">
																				</v-select>
																			</div>
																		</v-flex>
																		<v-flex xs12 md4>
																			<div class="mx-1 mt-5">
																				<div align="left">Town/City<span class="red--text">*</span>
																				</div>
																				<v-select :items="states" item-text="name" item-value="id"
																					v-model="newUser.state_id" :rules="inputRules" class="text_field background" dense
																					outlined prepend-inner-icon="mdi-flag">
																				</v-select>
																			</div>
																		</v-flex>
																		<v-flex xs12 md4>
																			<div class="mx-1 mt-5">
																				<div align="left">Date of Birth<span class="red--text">*</span></div>
																				<v-menu :close-on-content-click="false" max-width="290" transition="scale-transition"
																					v-model="dateOfBirth" offset-y>
																					<template v-slot:activator="{ on, attrs }">
																						<v-text-field outlined dense :value="formatDateOfBirth" :rules="inputRules"
																							slot="activator" readonly v-bind="attrs" v-on="on" class="text_field background"
																							prepend-inner-icon="mdi-calendar"></v-text-field>
																					</template>
																					<v-date-picker v-model="newUser.dob" :max="dateToday" @change="closeDateOfBirth">
																					</v-date-picker>
																				</v-menu>
																			</div>

																		</v-flex>
																		<v-flex xs12 md8>
																			<div class="mx-1 mt-5">
																				<div align="left">LinkedIn URL</div>
																				<v-text-field outlined dense v-model="newUser.linkedin_url"
																					class="text_field background" prepend-inner-icon="mdi-linkedin"></v-text-field>
																			</div>
																		</v-flex>
																		<v-flex xs12 md4>
																			<div class="mx-1 my-11" align="center">
																				<v-layout row wrap class="mt-5">
																					<v-flex xs12 md6>
																						<div align="left" class="ml-4">
																							Curriculum Vitae<span class="red--text">*</span>
																						</div>
																					</v-flex>
																					<v-flex xs12 md6>
																						<v-btn depressed class="secondary white--text text-none" @click="uploadDocument('CV')" v-if="newUser.curriculum_vitae==null">
																							Select File <v-icon right small>mdi-file-document</v-icon>
																						</v-btn>
																						<v-btn depressed color="#F40F02" class="white--text text-none" @click="viewDocument('CV')" v-if="newUser.curriculum_vitae!=null">
																							View File <v-icon right small>mdi-file-pdf-box</v-icon>
																						</v-btn>
																					</v-flex>
																				</v-layout>
																			</div>
																		</v-flex>
																	</v-layout>
																</v-form>
															</div>
														</v-flex>
														<v-flex xs12 class="mb-10">
															<v-layout row wrap>
																<v-flex xs6>
																	<div align="left">
																		<v-btn depressed class="red text-none white--text ml-3 mt-5" @click="e1 = 1">
																			<v-icon left dark class="white--text">mdi-chevron-left</v-icon> Back
																		</v-btn>
																	</div>
																</v-flex>
																<v-flex xs6>
																	<div align="right">
																		<v-btn depressed class="primary text-none mb-3 mt-5" @click="updateProfile()"
																			:loading="loading" :disabled="!personalValid">
																			Update and Continue <v-icon right dark>mdi-chevron-right</v-icon>
																		</v-btn>
																	</div>
																</v-flex>
															</v-layout>															
														</v-flex>
													</v-layout>
												</v-flex>
											</v-layout>
										</div>
									</v-stepper-content>

									<v-stepper-content step="3">
										<v-layout column>
											<v-flex x12>
												<div align="right">
													<!-- <v-progress-linear v-model="progressBar" :color="progressColor()"
														class="ml-5" height="25">
														<template v-slot:default="{ value }">
															<strong>
																<div class="black--text text--darken-1 black--darken-1">{{
				value }}%</div>
															</strong>
														</template>
													</v-progress-linear> -->
												</div>
											</v-flex>
											<v-flex xs12>
												<div v-if="questions.length != 0" class="mt-10">
													<template v-for="(question, index) in questions">
														<div :key="index">
															<v-flex xs12 class="my-2">
																<v-card class="mx-auto" elevation="0">
																	<v-card-text>
																		<v-layout row wrap>
																			<v-flex xs1>
																				<div align="center" class="subtitle-1">
																					{{ index + 1 }}.
																				</div>
																			</v-flex>
																			<v-flex xs11>
																				<div class="subtitle-1" align="left">
																					<b>{{ question.question }}<span class="red--text"
																							v-if="question.required == 1">*</span></b>
																				</div>
																				<div align="left" class="font-italic caption">{{ question.description }}</div>
																				<div v-if="question.question_type.name ==
																					'Single Select Multiple Choice Question'
																					">
																					<v-radio-group v-model="singleSelect[question.id]" column>
																						<template v-for="(
																							option, index
																						) in question.multi_choice_options">
																							<div :key="index">
																								<v-radio :label="option.option" :value="option.id"
																									@change="checkOther(question.id, option, question)"></v-radio>
																							</div>
																						</template>
																						<div v-if="otherQuestions[question.id] == true">
																								<ckeditor :editor="editor" v-model="openEnded[question.id]"
																									:config="editorConfig" :rules="inputRules"
																									@input="trackInputField(question.id)"></ckeditor>
																						</div>
																						<div v-if="childQuestions.length != 0" class="mt-3">
																							<template v-for="(question, index) in childQuestions[question.id]">
																								<div :key="index">
																									<v-flex xs12 class="my-2">
																										<v-card :loading="loading" class="mx-auto" elevation="0">
																											<v-card-text>
																												<v-layout row wrap>
																													<v-flex xs1>
																														<div align="center" class="subtitle-1">
																															{{ index + 1 }}.
																														</div>
																													</v-flex>
																													<v-flex xs11>
																														<div class="subtitle-1" align="left">
																															<b>{{ question.question	}}</b>
																														</div>
																														<div v-if="question.question_type.name == 'Single Select Multiple Choice Question'">
																															<v-radio-group v-model="singleSelect[question.id]" column>
																																<template v-for="(option, index) in question.multi_choice_options">
																																	<div :key="index">
																																		<v-radio :label="option.option" :value="option.id" @change="checkOther(question.id, option, question)"></v-radio>
																																	</div>
																																</template>
																																<div v-if="otherQuestions[question.id] == true">
																																	<v-text-field class="text_field mt-2" outlined
																																		v-model="openEnded[question.id]"
																																		label="Please specify.."
																																		@input="trackInputField(question.id)"
																																		required></v-text-field>
																																</div>
																																<div v-if="childQuestions.length != 0">
																																	{{ childQuestions[question.id] }}
																																</div>
																															</v-radio-group>
																														</div>
																														<div v-if="question.question_type.name == 'Multi Part Single Select Multiple Choice Question'">
																															<div class="mt-4">
																																<template v-for="(multi_question, index) in question.multi_part_questions">
																																	<div :key="index">
																																		{{ multi_question.question	}}
																																		<v-radio-group v-model="multiPartSingleAnswers[multi_question.id]" column>
																																			<template v-for="(option, index) in multi_question.multi_part_options">
																																				<div :key="index">
																																					<v-radio :label="option.option"	:value="option.id" @change="calculateProgress()"></v-radio>
																																				</div>
																																			</template>
																																		</v-radio-group>
																																	</div>
																																</template>
																															</div>
																														</div>
																														<div v-if="question.question_type.name == 'Multi Select Multiple Choice Questions'">
																															<div class="mt-4">
																																<template v-for="(option, index) in question.multi_choice_options">
																																	<v-checkbox v-model="multiSelects" :key="index"	:label="option.option" :value="option.id" @change="calculateProgress()"></v-checkbox>
																																</template>
																															</div>
																														</div>
																														<div v-if="question.question_type.name == 'Multi Part Multi Select Multiple Choice Question'">
																															<div class="mt-4">
																																<template v-for="(multi_question, index) in question.multi_part_questions">
																																	<div :key="index">
																																		{{multi_question.question}}
																																		<template v-for="(option, index) in multi_question.multi_part_options">
																																			<v-checkbox v-model="multiPartMultiAnswers" :key="index" :label="option.option"	:value="option.id" @change="calculateProgress()"></v-checkbox>
																																		</template>
																																	</div>
																																</template>
																															</div>
																														</div>
																														<div v-if="question.question_type.name == 'Open Ended'">
																															<div class="mt-3 mb-5" v-if="question.length <= 25">
																																<v-text-field class="text_field" outlined v-model="openEnded[question.id]" @input="trackInputField(question.id)" required></v-text-field>
																															</div>
																														</div>
																													</v-flex>
																												</v-layout>
																											</v-card-text>
																										</v-card>
																									</v-flex>
																								</div>
																							</template>
																						</div>
																					</v-radio-group>
																				</div>
																				<div v-if="question.question_type.name == 'Multi Part Single Select Multiple Choice Question'">
																					<div class="mt-4">
																						<template v-for="(multi_question, index) in question.multi_part_questions">
																							<div :key="index">
																								{{ multi_question.question }}
																								<v-radio-group v-model="multiPartSingleAnswers[multi_question.id]" column>
																									<template v-for="(option, index) in multi_question.multi_part_options">
																										<div :key="index">
																											<v-radio :label="option.option" :value="option.id" @change="calculateProgress()"></v-radio>
																										</div>
																									</template>
																								</v-radio-group>
																								<v-text-field v-if="multi_question.question == 'Yes'" class="text_field mb-6" outlined v-model="openEnded[question.id]" label="Please specify.." @input="trackInputField(question.id)" required></v-text-field>
																							</div>
																						</template>
																					</div>
																				</div>
																				<div v-if="question.question_type.name == 'Multi Select Multiple Choice Questions'">
																					<div class="mt-4">
																						<template v-for="(option, index) in question.multi_choice_options">
																							<v-checkbox v-model="multiSelects" :key="index" :label="option.option" :value="option.id" @change="calculateProgress()"></v-checkbox>
																						</template>
																					</div>
																				</div>
																				<div v-if="question.question_type.name == 'Multi Part Multi Select Multiple Choice Question'">
																					<div class="mt-4">
																						<template v-for="(multi_question, index) in question.multi_part_questions">
																							<div :key="index">
																								{{ multi_question.question }}
																								<template v-for="(option, index) in multi_question.multi_part_options">
																									<v-checkbox v-model="multiPartMultiAnswers" :key="index" :label="option.option" :value="option.id" @change="calculateProgress()"></v-checkbox>
																								</template>
																							</div>
																						</template>
																					</div>
																				</div>
																				<div v-if="question.question_type.name == 'Open Ended'">
																					<div class="mt-3 mb-5" v-if="question.length <= 25 && question.length != null">
																						<v-text-field class="text_field" outlined v-model="openEnded[question.id]" @input="trackInputField(question.id)" required></v-text-field>
																					</div>
																					<div class="mt-3 mb-5" v-else>
																						<ckeditor :editor="editor" v-model="openEnded[question.id]" :config="editorConfig" :rules="inputRules" @input="trackInputField(question.id)"></ckeditor>
																					</div>
																				</div>
																			</v-flex>
																		</v-layout>
																	</v-card-text>
																</v-card>
															</v-flex>
														</div>
													</template>
												</div>
												<div v-else class="mt-5">
													<v-card elevation="0">
														<v-layout row wrap>

															<v-flex xs12>
																<v-container style="height: 400px;">

																	<v-row class="fill-height" align-content="center" justify="center">
																		<v-col class="text-center">
																			<v-avatar size="200" class="primary title">
																				<v-icon class="white--text" large>mdi-text-box-check</v-icon>
																			</v-avatar>
																		</v-col>
																		<v-col class="text-subtitle-1 text-center" cols="12">
																			Loading Questions..
																		</v-col>
																		<v-col cols="6">
																			<v-progress-linear color="primary" indeterminate rounded
																				height="6"></v-progress-linear>
																		</v-col>
																	</v-row>
																</v-container>
															</v-flex>
														</v-layout>
													</v-card>
												</div>
											</v-flex>
											<v-flex xs12>
												<v-layout row wrap class="mt-10 mb-3">
													<v-flex xs6>
														<div align="left">
															<v-btn depressed class="red text-none white--text ml-3" @click="e1 = 2">
																<v-icon left dark class="white--text">mdi-chevron-left</v-icon> Back
															</v-btn>
														</div>
													</v-flex>
													<v-flex xs6>
														<div align="right">
															<v-btn depressed class="primary text-none mr-3" @click="saveAnswers()" :loading="loading">
																Save and Continue <v-icon right dark>mdi-chevron-right</v-icon>
															</v-btn>
														</div>
													</v-flex>
												</v-layout>

											</v-flex>
										</v-layout>

									</v-stepper-content>

									<v-stepper-content step="4">
										<div v-if="completeApplication == false">
											<v-layout column v-if="formData!=null">
												<v-form v-model="termsValid" ref="termsAndConditionsForm" lazy-validation>
													<v-flex xs12>
														<div class="mx-1 mt-5">
															<div align="left">Where did you first learn about the Standard Chartered Women in Cyber Program?<span
																	class="red--text">*</span>
															</div>
															<v-radio-group v-model="newApplication.marketing_source_id" column>
																<template v-for="(
																		option, index
																		) in formData.marketingSources">
																	<div :key="index">
																		<v-radio :label="option.name" :value="option.id"></v-radio>
																	</div>
																</template>
															</v-radio-group>
														</div>
													</v-flex>
													<v-flex xs12>
														<div class="mx-1 mt-5" align="left">
															Do you agree to the program <a href="/#/terms-and-conditions" target="_blank" style="text-decoration: none;">Terms and Conditions</a>?<span class="red--text">*</span>
														</div>
														<v-checkbox v-model="newApplication.terms_conditions" :rules="inputRules"
															label="I hereby declare that the information I have provided is to the best of my knowledge, true, complete and correct."></v-checkbox>
													</v-flex>
													<v-flex xs12>
														<v-layout row wrap class="mt-10 mb-3">
															<v-flex xs6>
																<div align="left">
																	<v-btn depressed class="red text-none white--text ml-3" @click="e1 = 3">
																		<v-icon left dark class="white--text">mdi-chevron-left</v-icon> Back
																	</v-btn>
																</div>
															</v-flex>
															<v-flex xs6>
																<div align="right">
																	<v-btn depressed class="primary text-none mr-3" @click="submitApplication()"
																		:loading="loading" :disabled="!termsValid">
																		Submit and Complete <v-icon right dark>mdi-chevron-right</v-icon>
																	</v-btn>
																</div>
															</v-flex>
														</v-layout>

													</v-flex>
												</v-form>
											</v-layout>
										</div>
										<div v-else>
											<v-container style="height: 400px;">
												<v-row class="fill-height" align-content="center" justify="center">
													<v-col cols="12">
														<div align="center">
															<v-icon size="150px" class="green--text">
																mdi-check-circle
															</v-icon>
														</div>
													</v-col>
													<v-col class="text-subtitle-1 text-center" cols="12">
														Your application has been successfully submitted.
													</v-col>
													<v-col class="caption text-center" cols="12">
														You have the option to make modifications up until the deadline day.
													</v-col>

												</v-row>
											</v-container>
										</div>
									</v-stepper-content>
								</v-stepper-items>
							</v-stepper>
						</div>
						</v-card>
						
					</div>
				</v-container>
			</v-flex>
		</v-layout>
	</div>
</template>

<script>
import Vue from "vue";
import apiCall from "@/utils/api";
import { mapState, mapGetters, mapActions } from "vuex";
import { USER_REQUEST } from "@/store/actions/user";
import CKEditor from "@ckeditor/ckeditor5-vue2";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import uploadAdapter from '@/uploadAdapter.js';
import pdf from 'vue-pdf'

export default {
	components: {
		pdf
	},
	data() {
		return {
			path: process.env.VUE_APP_API_URL,

			editor: ClassicEditor,
			editorConfig: {
				extraPlugins: [function (editor) {
					editor.plugins.get('FileRepository').createUploadAdapter = (loader) => {
						return new uploadAdapter(loader);
					}
				}],
				language: 'en',
			},

			color: "",
			delete: false,
			loading: false,
			certLoading: false,
			valid: true,
			personalValid: true,
			termsValid: true,
			message: "",
			snackbar: false,
			confirm: false,
			programDetails: null,
			openForm: false,
			years: [],

			formData: null,
			dateOfBirth: false,

			docType: null,
			imageDialog: false,
			imageLoading: false,
			pdfViewDialog: false,
			pdfDialog: false,
			pdfLoading: false,
			inputRules: [(v) => !!v || "Input is required"],
			emailRules: [
				(v) => !!v || "Input is required",
				(v) =>
					!v ||
					/^[a-zA-Z0-9\.!#$%&'*+/=?^_~-]+@([a-zA-Z0-9]+\.)+[a-zA-Z]{2,3}$/.test(
						v
					) ||
					"E-mail must be valid",
				(v) => !v || (v && v.length <= 50) || "Maximum 50 characters",
			],
			fileRules: [(v) => !!v || "Kindly Select a File"],
			imageFile: null,
			imageValid: true,
			pdfFile: null,
			pdfValid: true,

			completeApplication: false,

			e1: 1,

			newUser: {
				id: null,
				role: null,
				first_name: null,
				middle_name: null,
				last_name: null,
				email: null,
				phone: null,
				dob: null,
				bio: null,
				country_id: null,
				state_id: null,
				gender_id: null,

				linkedin_url: null,
				curriculum_vitae: null
			},
			newApplication: {
				id: null,
				marketing_source_id: null,
				terms_conditions: null
			},

			months: [
				"01",
				"02",
				"03",
				"04",
				"05",
				"06",
				"07",
				"08",
				"09",
				"10",
				"11",
				"12",
			],
			monthNames: [
				{ name: "January" },
				{ name: "February" },
				{ name: "March" },
				{ name: "April" },
				{ name: "May" },
				{ name: "June" },
				{ name: "July" },
				{ name: "August" },
				{ name: "September" },
				{ name: "October" },
				{ name: "November" },
				{ name: "December" },
			],

			progressBar: 0,
			questionCount: 0,

			application: {
				answers: null
			},

			answers: [],

			openEnded: [],
			singleSelect: [],
			multiPartSingleAnswers: [],
			multiPartMultiAnswers: [],
			multiSelects: [],
			questions: [],

			otherQuestions: [],
			childQuestions: [],
			counter: 0,

			applicationAnswers: [],
			progressData: {
				id: null,
				progress: null,
			},
			teamMembers: [
				{
					id: null,
					venture_id: null,
					first_name: null,
					middle_name: null,
					last_name: null,
					founder: null,
					shares: null,
					gender_id: null,
					role: null,
					years_experience: null
				}
			],

			states: [],
		};
	},
	mounted() {
		window.onscroll = () => {
			this.changeColor();
		};
	},
	created() {
		window.scrollTo(0, 0);
		this.changeHeaderColor("white");
		this.changeTitleColor("primary--text");
		this.changeButtonColor("primary--text mr-1 hidden-sm-and-down text-none caption");

		this.checkActiveProgram()
		this.assignUser()
		this.populateYears();
	},
	methods: {
		...mapActions([
			"changeNavLogo",
			"changeHeaderColor",
			"changeTitleColor",
			"changeButtonColor"
		]),
		populateStates(){
			this.states = []
			for(var x=0; x<this.formData.countries.length; x++){
				if(this.formData.countries[x].id==this.newUser.country_id){
					this.states = this.formData.countries[x].states
				}
			}
		},
		
		changeColor() {
			if (document.body.scrollTop > 100 || document.documentElement.scrollTop > 100) {
				this.changeNavLogo("img/logo-white.png");
				this.changeHeaderColor("primary");
				this.changeTitleColor("white--text");
				this.changeButtonColor("text-none mr-1 hidden-sm-and-down white--text");
			} else {
				this.changeNavLogo("img/logo-black.png");
				this.changeHeaderColor("white");
				this.changeTitleColor("primary--text");
				this.changeButtonColor("text-none mr-1 hidden-sm-and-down white primary--text");
			}
		},
		uploadDocument(doctype){
			this.docType = doctype
			this.pdfDialog = true
		},
		viewDocument(doctype){
			this.docType = doctype
			this.pdfViewDialog = true
		},
		checkActiveProgram(){
			apiCall({
				url: "/api/program?type=programDetails",
				method: "GET",
			})
				.then((resp) => {
					this.programDetails = resp;
					this.getFormData();
				})
				.catch((error) => {
					this.message = "An Error Occurred; Couldn't Get Form Data";
					this.color = "error";
				});
		},
		pickRole(){
			if(this.newUser.role == null){
				this.message = "Please Pick a Role";
				this.color = "error";
				this.snackbar = true
			}else{
				this.e1 = 2
			}			
		},
		populateYears() {
			var max = new Date().getFullYear();
			var min = max - 100;
			var years = [];

			for (var i = max; i >= min; i--) {
				years.push(i);
			}
			this.years = years;
		},
		assignUser() {
			console.log("this.getProfile", this.getProfile)
			this.newUser.id = this.getProfile.id
			this.newUser.first_name = this.getProfile.first_name
			this.newUser.middle_name = this.getProfile.middle_name
			this.newUser.last_name = this.getProfile.last_name
			this.newUser.email = this.getProfile.email
			this.newUser.phone = this.getProfile.phone
			this.newUser.dob = this.getProfile.dob
			if (this.getProfile.country_id == null) {
				this.newUser.country_id = 114
			} else {
				this.newUser.country_id = this.getProfile.country_id
			}
			this.newUser.state_id = this.getProfile.state_id
			this.newUser.curriculum_vitae = this.getProfile.curriculum_vitae
			this.newUser.gender_id = this.getProfile.gender_id
			this.newUser.linkedin_url = this.getProfile.linkedin_url

			for(var x= 0; x<=this.getProfile.roles.length; x++){
				if(this.getProfile.roles[x].name == 'Mentor'){
					this.newUser.role = "Mentor"
				}
				if(this.getProfile.roles[x].name == 'Mentee'){
					this.newUser.role = "Mentee"
				}

			}
		},
		closeDateOfBirth() {
			this.dateOfBirth = false;
		},
		uploadImage() {
			if (this.$refs.imageForm.validate()) {
				this.imageLoading = true;
				let formData = new FormData();

				// files

				formData.append("files", this.imageFile, this.imageFile.name);
					apiCall({
						url: "/api/user?type=image",
						data: formData,
						method: "POST",
					})
						.then((resp) => {
							this.snackbar = true;
							this.message = "Image Uploaded Successfully";
							this.color = "success";
							this.$store.dispatch(USER_REQUEST);
							this.imageLoading = false;
							this.imageDialog = false;
							this.imageFile = null
						})
						.catch((error) => {
							this.imageLoading = false;
						});

			}
		},
		uploadPDF(){
			if (this.$refs.pdfForm.validate()) {
				this.pdfLoading = true;
				let formData = new FormData();

				// files

				formData.append("files", this.pdfFile, this.pdfFile.name);

				if (this.docType == 'CV') {
					apiCall({
						url: "/api/user?type=CV",
						data: formData,
						method: "POST",
					})
						.then((resp) => {
							this.snackbar = true;
							this.message = "CV Uploaded Successfully";
							this.color = "success";

							this.pdfLoading = false;
							this.pdfDialog = false;
							this.pdfFile = null

							this.$nextTick(() => {
								this.newUser.curriculum_vitae = resp.curriculum_vitae
							});
						})
						.catch((error) => {
							this.pdfLoading = false;
						});
				}
			}
		},
		updateProfile() {
			if (this.$refs.profileForm.validate()) {
				if(this.newUser.curriculum_vitae == null){
					this.message = "Kindly Upload your CV";
					this.color = "error";
					this.snackbar = true;
				}else{
					confirm("Are you sure you want to update your profile?") && (this.confirm = true);
					if (this.confirm) {
						this.loading = true;
						apiCall({
							url: "/api/user/" + this.newUser.id + "?type=user",
							data: this.newUser,
							method: "PUT",
						})
							.then((resp) => {
								window.scrollTo(0, 0);
								this.message = "Profile Updated Successfully";
								this.color = "orange";
								this.loading = false;
								this.snackbar = true;
								this.confirm = false;
								this.$store.dispatch(USER_REQUEST);

								this.e1 = 3
								this.getQuestions()
							})
							.catch((error) => {
								this.message = "An Error Occurred";
								this.color = "error";
								this.loading = false;
								this.snackbar = true;
							});
					}
				}
			}
		},
		getFormData() {
			apiCall({
				url: "/api/user?type=formData",
				method: "GET",
			})
				.then((resp) => {
					this.formData = resp;

					this.populateStates()
				})
				.catch((error) => {
					this.message = "An Error Occurred; Couldn't Get Form Data";
					this.color = "error";
				});
		},
		getQuestions() {
			apiCall({
				url: "/api/question?type=public&level="+this.newUser.role,
				method: "GET",
			})
				.then((resp) => {
					this.questions = resp;
					this.totalQuestions();
					this.getAnswers()
				})
				.catch((error) => {
					this.message = "An Error Occurred; Couldn't Get Form Data";
					this.color = "error";
				});
		},

		getAnswers() {
			apiCall({
				url: "/api/answer",
				method: "GET",
			})
				.then((resp) => {
					this.answers = resp
					this.assignAnwers()
				})
				.catch((error) => {

				});
		},
		getApplication(){
			apiCall({
				url: "/api/application?type=application",
				method: "GET",
			})
				.then((resp) => {
					if (resp.status == 200) {
						this.newApplication.marketing_source_id = resp.application.marketing_source_id
						this.newApplication.terms_conditions = resp.application.terms_conditions
					}
				})
				.catch((error) => {
					this.message = "An Error Occurred; Couldn't Get Team Members";
					this.color = "error";
				});
		},

		checkProgressArray() {
			var found = false;
			for (var i = 0; i < this.progress.length; i++) {
				if (this.progress[i].id == this.$route.params.id) {
					found = true;
				}
			}
			if (found == false) {
				this.progressData.id = parseInt(this.$route.params.id)
				let progressCopy = this.progress;
				progressCopy.push(Object.assign({}, this.progressData));
				this.updateprogress(progressCopy);
			}
		},
		checkOther(questionId, option, question) {
			if (option.option == "Yes") {
				this.otherQuestions[questionId] = true;
			} else {
				this.otherQuestions[questionId] = false;
				//this.openEnded[questionId] = null;
			}
			if (option.option == "Yes"
				|| option.option == "Referred by a friend"
				|| option.option == "Yes, all of them"

			) {
				const index = this.questions.indexOf(question);
				if (this.childQuestions[questionId] === undefined) {
					this.questionCount += this.questions[index].child_questions.length
				}

				if (this.questions[index].child_questions.length != 0) {
					this.childQuestions[questionId] = this.questions[index].child_questions

				}


			} else if (option.option == "No"
				|| option.option == "Event (at school or in college)"
				|| option.option == "Relative"
				|| option.option == "Other"
				|| option.option == "No, none of them"
				|| option.option == "Majority of them"
				|| option.option == "A few of them"
				|| option.option == "Not sure"
				|| option.option == "Not Comfortable Disclosing"


			) {
				const index = this.questions.indexOf(question);
				if (this.questions[index].child_questions.length != 0) {
					this.childQuestions[questionId] = []
					if (question.question_type.name == 'Single Select Multiple Choice Question') {
						for (let i = 0; i < question.child_questions.length; i++) {
							if (this.singleSelect[question.child_questions[i].id] != null) {
								this.singleSelect[question.child_questions[i].id] = null
								this.questionCount -= 1
							}
						}
					} else if (question.question_type.name == 'Multi Part Single Select Multiple Choice Question') {
						for (let m = 0; m < question.multi_part_questions.length; m++) {
							this.multiPartSingleAnswers[question.multi_part_questions[m].id] = null
						}
					}
					// else if(question.question_type.name == 'Multi Select Multiple Choice Questions'){
					//   for (let k=0; k<question.multi_choice_options.length; k++){
					//     this.multiSelects[question.multi_part_questions[m].id] = false
					//   }
					// }
				}
			}
			this.calculateProgress()
		},
		assignAnwers() {
			if (this.answers.length != 0) {
				this.openEnded = this.answers[0];
				this.singleSelect = this.answers[1];
				this.multiPartSingleAnswers = this.answers[2];
				this.multiPartMultiAnswers = this.answers[3];
				this.multiSelects = this.answers[4];
			}
			this.calculateProgress()
		},
		trackInputField(questionId) {
			if (this.openEnded[questionId] == "") {
				this.openEnded[questionId] = null
			}
			this.calculateProgress()
		},
		totalQuestions() {
			var total = 0;
			for (var i = 0; i < this.questions.length; i++) {
				if (this.questions[i].question_type.name == 'Single Select Multiple Choice Question' || this.questions[i].question_type.name == 'Open Ended' || this.questions[i].question_type.name == 'Multi Select Multiple Choice Questions') {
					total = total + 1;
				} else if (this.questions[i].question_type.name == 'Multi Part Single Select Multiple Choice Question' || this.questions[i].question_type.name == 'Multi Part Multi Select Multiple Choice Question') {
					total = total + this.questions[i].multi_part_questions.length;
				}
			}
			this.questionCount = total
		},
		calculateProgress() {
			this.$nextTick(() => {
				let singles = Object.values(this.singleSelect)
				let openEndedKeys = Object.keys(this.openEnded)
				//let openEndedValues = Object.values(this.openEnded)
				let multiPartSingles = Object.values(this.multiPartSingleAnswers)
				let multiPartMultis = this.multiPartMultiAnswers
				let multis = this.multiSelects

				var answers = 0
				for (var i = 0; i < singles.length; i++) {
					if (singles[i] != null) {
						answers = answers + 1
					}
				}

				for (var i = 0; i < this.questions.length; i++) {
					if (this.questions[i].child_questions.length != 0) {
						for (var x = 0; x < this.questions[i].child_questions.length; x++) {
							if (this.questions[i].child_questions[x].question_type.name == 'Open Ended') {
								if (this.openEnded[this.questions[i].child_questions[x].id] != null) {
									answers = answers + 1
								}
							}
						}
					}
				}

				for (var i = 0; i < openEndedKeys.length; i++) {

					let a = this.questions.find((question) => question.id == openEndedKeys[i]);
					if (a && Object.values(a.question_type)[1] == "Open Ended") {

						if (this.openEnded[a.id] != null) {
							answers = answers + 1
						}
					}
				}

				for (var i = 0; i < multiPartSingles.length; i++) {
					if (multiPartSingles[i] != null) {
						answers = answers + 1
					}
				}

				for (var i = 0; i < this.questions.length; i++) {
					if (this.questions[i].question_type.name == 'Multi Part Multi Select Multiple Choice Question') {
						let multiPartQuestions = this.questions[i].multi_part_questions
						for (var x = 0; x < multiPartQuestions.length; x++) {
							let multipartOptions = multiPartQuestions[x].multi_part_options
							for (var y = 0; y < multipartOptions.length; y++) {
								if (multiPartMultis.includes(multipartOptions[y].id)) {
									answers = answers + 1
									break;
								}
							}
						}
					} else if (this.questions[i].question_type.name == 'Multi Select Multiple Choice Questions') {
						let multiOptions = this.questions[i].multi_choice_options
						for (var z = 0; z < multiOptions.length; z++) {
							if (multis.includes(multiOptions[z].id)) {
								answers = answers + 1
								break;
							}
						}
					}
				}
				this.progressBar = parseInt((answers / this.questionCount) * 100)
				if (this.progressBar > 100) {
					this.progressBar = 100
				}
				if (this.progressBar >= 25 && this.progressBar <= 26) {
					this.message = "You're on the right track keep going!";
					this.color = "red darken-1";
					this.snackbar = true;
				} else if (this.progressBar >= 50 && this.progressBar <= 51) {
					this.message = "This module is 50% done keep going!";
					this.color = "orange darken-1";
					this.snackbar = true;
				} else if (this.progressBar >= 75 && this.progressBar <= 76) {
					this.message = "You're almost there well done!";
					this.color = "orange darken-5";
					this.snackbar = true;
				} else if (this.progressBar == 100) {
					this.message = "Congratulations on finishing this module!";
					this.color = "green darken-5";
					this.snackbar = true;
				}
			});
		},
		progressColor() {
			if (this.progressBar <= 8) {
				return "red darken-1";
			} else if (this.progressBar >= 9 && this.progressBar <= 16) {
				return "red darken-2";
			} else if (this.progressBar >= 17 && this.progressBar <= 24) {
				return "red darken-3";
			} else if (this.progressBar >= 25 && this.progressBar <= 32) {
				return "red darken-4";
			} else if (this.progressBar >= 33 && this.progressBar <= 40) {
				return "orange darken-1";
			} else if (this.progressBar >= 41 && this.progressBar <= 48) {
				return "orange darken-2";
			} else if (this.progressBar >= 49 && this.progressBar <= 56) {
				return "orange darken-3";
			} else if (this.progressBar >= 57 && this.progressBar <= 64) {
				return "orange darken-4";
			} else if (this.progressBar >= 65 && this.progressBar <= 72) {
				return "green darken-1";
			} else if (this.progressBar >= 73 && this.progressBar <= 80) {
				return "green darken-2";
			} else if (this.progressBar >= 81 && this.progressBar <= 88) {
				return "green darken-3";
			} else if (this.progressBar >= 89 && this.progressBar <= 100) {
				return "green darken-4";
			}
		},
		saveAnswers() {
			confirm("Are you sure you want to Submit your Answers?") && (this.confirm = true);
			if (this.confirm) {
				this.applicationAnswers[0] = this.openEnded;
				this.applicationAnswers[1] = this.singleSelect;
				this.applicationAnswers[2] = this.multiPartSingleAnswers;
				this.applicationAnswers[3] = this.multiPartMultiAnswers;
				this.applicationAnswers[4] = this.multiSelects;

				this.application.answers = this.applicationAnswers

				this.loading = true;

				apiCall({ url: "/api/answer", data: this.application, method: "POST" })
					.then((resp) => {
						window.scrollTo(0, 0);
						this.message = resp.message;
						this.color = "green";
						this.loading = false;
						this.snackbar = true;
						this.confirm = false;

						this.e1 = 4

						this.getApplication()
					})
					.catch((error) => {
						this.message = "An error occurred.";
						this.color = "error";
						this.loading = false;
						this.snackbar = true;
					});
			}
		},
		submitApplication() {
			if (this.newApplication.marketing_source_id == null) {
				this.message = "Kindly indicate where you first learnt about the Program";
				this.color = "error";
				this.loading = false;
				this.snackbar = true;
			} else if (this.newApplication.terms_conditions == null) {
				this.message = "Kindly agree to the Terms and Conditions of the Program";
				this.color = "error";
				this.loading = false;
				this.snackbar = true;
			} else {
				confirm("Are you sure you want to Submit your Application?") && (this.confirm = true);
				if (this.confirm) {
					this.loading = true;
					apiCall({ url: "/api/application", data: this.newApplication, method: "POST" })
						.then((resp) => {
							window.scrollTo(0, 0);
							this.message = "Application Received Successfully";
							this.color = "green";
							this.loading = false;
							this.snackbar = true;
							this.confirm = false;

							this.completeApplication = true
						})
						.catch((error) => {
							this.message = "An error occurred. Please Try Again";
							this.color = "error";
							this.loading = false;
							this.snackbar = true;
						});
				}
			}
		},
		deleteCV(type) {
			confirm("Are You Sure You Want to Delete CV?") && (this.delete = true);
			if (this.delete) {
				this.certLoading = true;
				apiCall({ 
						url: "/api/user?type=curriculumVitaeDelete", 
						data: this.newUser,
						method: "POST",
					 })
					.then((resp) => {
						this.message = "CV Deleted Succesfully";
						this.color = "success";
						this.snackbar = true;
						this.certLoading = false;
						this.delete = false;
						this.pdfViewDialog = false;
						this.$nextTick(() => {
							this.newUser.curriculum_vitae = null
						});
					})
					.catch((error) => {
						this.message = "An Error Occurred";
						this.color = "error";
						this.snackbar = true;
						this.certLoading = false;
						this.delete = false;
						
					});
				
			}
		},
	},
	computed: {
		...mapGetters([
			"darkState",
			"getProfile"
		]),
		formatDateOfBirth() {
			if (this.newUser.dob != null) {
				const d = new Date(this.newUser.dob);
				const year = d.getFullYear();
				const date = d.getDate();
				const month = d.getMonth();

				return date + " " + this.monthNames[month].name + " " + year;
			}
		},
		dateToday() {
			var today = new Date();

			var timestamp =
				(today.getFullYear() - 17) +
				"-" +
				this.months[today.getMonth()] +
				"-" +
				("0" + today.getDate()).slice(-2);
			return timestamp;
		},
	},
};
</script>

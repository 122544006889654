<template>
	<div class="submittedApplications" v-if="$can('application_view')">
		<v-snackbar v-model="snackbar" :timeout="4000" bottom right :color="color">
			<span>{{ message }}</span>
		</v-snackbar>
		<v-dialog
            transition="dialog-bottom-transition"
            max-width="600"
            v-model="pdfViewDialog"
        >
            <v-card max-width="600" v-if="assignedApplication!=null">
				<v-toolbar flat>
					{{ docType }}
					<v-spacer></v-spacer>
					<v-btn icon @click="pdfViewDialog = false">
						<v-icon class="primary--text">mdi-close</v-icon>
					</v-btn>
				</v-toolbar>
                <v-card-text>                    
					<div align="right">
						<v-btn icon @click="$refs.myPdfComponent.print()"><v-icon class="primary--text">mdi-printer</v-icon></v-btn>
					</div>
					<pdf
						ref="myPdfComponent"
						:src="path+'/storage/docs/'+ assignedApplication.user.curriculum_vitae"
						@num-pages="pageCount = $event"
						@page-loaded="currentPage = $event"
					></pdf>
                </v-card-text>
            </v-card>
        </v-dialog>
		<v-container>
			<v-layout column>
				<div v-if="view == 'default'">
					<v-tabs show-arrows v-model="applicationTab" class="mt-5">
						<v-tabs-slider color="primary"></v-tabs-slider>

						<v-tab @click="activeTab = 'submitted'">
							<div class="text-none primary--text">
								Submitted ({{ submittedApplicationPagination.total }})
							</div>
						</v-tab>
						<v-tab @click="activeTab = 'review'">
							<div class="text-none primary--text">
								Review ({{ reviewApplicationPagination.total }})
							</div>
						</v-tab>
						<v-tab @click="activeTab = 'rejected'">
							<div class="text-none primary--text">
								Rejected ({{ rejectedApplicationPagination.total }})
							</div>
						</v-tab>
						<v-tab @click="activeTab = 'selectedForRating'">
							<div class="text-none primary--text">
								Selected for Rating ({{ selectedForRatingApplicationPagination.total }})
							</div>
						</v-tab>
						<v-tab @click="activeTab = 'shortlisted'">
							<div class="text-none primary--text">
								Shortlisted ({{ shortlistedApplicationPagination.total }})
							</div>
						</v-tab>
					</v-tabs>

					<div v-if="activeTab == 'submitted'">
						<v-card outlined class="mt-5 pa-3" style="border-radius: 25px;">
							<v-flex xs12 class="mt-10 mb-2">
								<div class="mx-5">
									<v-layout row wrap>
										<v-flex xs12 md4>
											<h1>Submitted Applications</h1>
										</v-flex>
										<v-flex xs12 md4>
											<div class="pa-1">
												<v-text-field class="text_field header" outlined dense label="Search"
													append-icon="mdi-undo-variant" @click:append="resetSearch()"
													v-on:keyup.enter="search" v-model="searchTerm"
													@input="enableSearch()"></v-text-field>
											</div>

										</v-flex>
										<v-flex xs12 md2>
											<div class="pa-1">
												<v-btn class="secondary white--text text-none" depressed block
													@click="search" :loading="searchLoader"
													:disabled="searchButtonDisabled">
													Search
													<v-icon right>mdi-magnify</v-icon>
												</v-btn>
											</div>
										</v-flex>
										<v-flex xs12 md2>
											<div class="pa-1">
												<v-btn class="primary white--text text-none" depressed block
													@click="csvDownload('submittedApplications')" :loading="csvLoading">
													Download CSV
													<v-icon right>mdi-microsoft-excel</v-icon>
												</v-btn>
											</div>
										</v-flex>
									</v-layout>
								</div>
							</v-flex>
							<div class="mx-5 mt-9">
								<v-progress-linear v-if="submittedApplicationLoader" height="1" indeterminate
									color="primary"></v-progress-linear>
							</div>
							<v-divider class="mx-5"></v-divider>
							<v-flex xs12 class="mt-5">
								<div v-if="submittedApplications.length == 0">
									<v-card elevation="0">
										<v-layout row wrap>
											<v-flex xs12 md1>
												<v-container fill-height fluid>
													<v-row align="center" justify="center">
														<v-col class="text-center">
															<v-icon large class="text--text">
																mdi-alert-circle-outline
															</v-icon>
														</v-col>
													</v-row>
												</v-container>
											</v-flex>
											<v-flex xs12 md11>
												<v-container fill-height fluid>
													<v-row align="center" justify="center">
														<v-col class="text-center"> No Application Found </v-col>
													</v-row>
												</v-container>
											</v-flex>
										</v-layout>
									</v-card>
								</div>
								<div v-else>
									<div class="hidden-sm-and-down">
										<v-card elevation="0" class="pa-2">
											<v-simple-table>
												<template v-slot:default>
													<thead class="header">
														<tr class="tableHeader">
															<th class="text-left text--text">#</th>
															<th class="text-left text--text">Images</th>
															<th class="text-left text--text">Applicant</th>
															<th class="text-left text--text">Role</th>
															<th class="text-left text--text">Source</th>
															<th class="text-left text--text">Date Added</th>
															<th class="text-right text--text">Actions</th>
														</tr>
													</thead>
													<tbody>
														<tr v-for="(item, index) in submittedApplications" :key="item.id">
															<td>
																{{ index + 1 }}
															</td>
															<td>
																<v-avatar size="40" class="primary title white--text mx-1"
																	v-if="item.user.prof_pic == null">
																	{{ item.user.first_name[0] }}
																</v-avatar>
																<v-avatar size="40" class="primary title mx-1" v-else>
																	<img :src="path + '/storage/profile_pics/' + item.user.prof_pic"
																	>
																</v-avatar>
																
															</td>
															<td>{{ item.user.first_name }} {{ item.user.middle_name }} {{ item.user.last_name }}</td>
															<td>
																<div class="green--text" v-for="role in item.user.roles">
																	{{ role.name }}
																</div>
															</td>
															<td>
																{{ item.marketing_source.name }}
															</td>
															<td>
																{{
																	item.created_at | moment("DD/MM/YYYY - hh:mm a")
																}}
															</td>
															<td>
																<div align="right">
																	<v-tooltip top v-if="$can('application_view')">
																		<template v-slot:activator="{ on, attrs }">
																			<v-btn icon class="button mr-1 universal--text"
																				@click="showApplication(item)">
																				<v-icon small> mdi-eye </v-icon>
																			</v-btn>
																		</template>
																		<span>View</span>
																	</v-tooltip>
																	<!-- <v-tooltip top v-if="$can('application_edit')">

																		<template v-slot:activator="{ on, attrs }">
																			<v-btn icon class="button mr-1 success--text"
																				@click="editApplication(item)" v-bind="attrs"
																				v-on="on">
																				<v-icon small> mdi-pencil </v-icon>
																			</v-btn>
																		</template>
																		<span>Edit</span>
																	</v-tooltip> -->

																	<v-tooltip top v-if="$can('application_archive')">
																		<template v-slot:activator="{ on, attrs }">
																			<v-btn icon class="button mr-1 error--text"
																				@click="deleteApplication(item)"
																				:loading="loading && applicationIndex == item.id"
																				v-bind="attrs" v-on="on">
																				<v-icon small> mdi-delete </v-icon>
																			</v-btn>
																		</template>
																		<span>delete</span>
																	</v-tooltip>

																	<v-tooltip top
																		v-if="$can('application_review')">

																		<template v-slot:activator="{ on, attrs }">
																			<v-btn icon class="button mr-1 green--text"
																				@click="applicationReview(item, 'reviewStage', 'up')"
																				:loading="upgradeLoading && applicationIndex == item.id"
																				v-bind="attrs" v-on="on">
																				<v-icon small> mdi-arrow-right
																				</v-icon>
																			</v-btn>
																		</template>
																		<span>Send For Review</span>
																	</v-tooltip>
																	<v-tooltip top v-if="$can('log_view')">

																		<template v-slot:activator="{ on, attrs }">
																			<v-btn icon class="button mr-1 purple--text"
																				@click="viewLog(item)" v-bind="attrs"
																				v-on="on">
																				<v-icon small> mdi-script-text </v-icon>
																			</v-btn>
																		</template>
																		<span>Log</span>
																	</v-tooltip>

																</div>
															</td>
														</tr>
													</tbody>
												</template>
											</v-simple-table>
										</v-card>

									</div>
									<div class="hidden-md-and-up">
										<v-layout column>

											<template v-for="(application, index) in submittedApplications">
												<div :key="index">
													<v-flex xs12 class="mb-2">
														<v-card elevation="0">
															<div class="pa-5">
																<v-layout column>
																	<v-flex xs12>
																		<v-layout row wrap>
																			<v-flex xs11>
																				<div class="title primary--text ml-3">
																					{{ application.user.first_name }} {{ application.user.middle_name }} {{ application.user.last_name }}
																				</div>
																			</v-flex>
																			<v-flex xs1>
																				<v-btn depressed class="red--text" :loading="loading && applicationIndex == application.id
			" icon @click="deleteApplication(application)" v-if="$can('application_archive')">
																					<v-icon> mdi-delete </v-icon>
																				</v-btn>
																			</v-flex>
																		</v-layout>
																	</v-flex>
																	<v-divider class="mt-2"></v-divider>
																	<v-flex xs12 class="mt-1">
																		<v-layout column>
																			<v-flex xs12 class="mt-1">
																				<div>
																					<b>Applicant</b>
																				</div>
																				<div>
																					{{ application.user.first_name }} {{ application.user.middle_name }} {{ application.user.last_name }}
																				</div>
																			</v-flex>
																			<v-flex xs12 class="mt-1">
																				<div>
																					<b>Venture</b>
																				</div>
																				<div>
																					{{ application.user.first_name }} {{ application.user.middle_name }} {{ application.user.last_name }}
																				</div>
																			</v-flex>
																			<v-flex xs12 class="mt-1">
																				<div>
																					<b>Source</b>
																				</div>
																				<div>
																					{{
																						application.marketing_source.name
																					}}
																				</div>
																			</v-flex>
																			<v-flex xs12 class="mt-1">
																				<div>
																					<b>Date Added</b>
																				</div>
																				<div>
																					{{
																						application.created_at
																						| moment("DD/MM/YYYY - hh:mm a")
																					}}
																				</div>
																			</v-flex>
																			<v-flex xs12 class="mt-5">
																				<v-layout row wrap>
																					<v-flex xs12>
																						<div class="pa-1">
																							<v-btn
																								v-if="$can('application_view')"
																								depressed class="
																								primary
																								text-none
																								white--text
																								" block @click="showApplication(application)">View
																								<v-icon right> mdi-eye
																								</v-icon>
																							</v-btn>
																						</div>
																					</v-flex>
																					<!-- <v-flex xs12>
																						<div class="pa-1">
																							<v-btn depressed
																								class="blue text-none white--text"
																								block
																								@click="editApplication(application)"
																								v-if="$can('application_edit')">Edit
																								<v-icon right>
																									mdi-border-color
																								</v-icon>
																							</v-btn>
																						</div>
																					</v-flex> -->
																					<v-flex xs12>
																						<div class="pa-1">
																							<v-btn depressed
																								class="green text-none white--text"
																								block
																								@click="applicationReview(application, 'reviewStage', 'up')"
																								:loading="upgradeLoading && applicationIndex == application.id"
																								v-if="$can('application_review')">Send For Review
																								<v-icon right>
																									mdi-arrow-right
																								</v-icon>
																							</v-btn>
																						</div>
																					</v-flex>
																					<v-flex xs12>
																						<div class="pa-1">
																							<v-btn v-if="$can('log_view')"
																								depressed class="
													purple
													text-none
													white--text
													" block @click="viewLog(application)">Log
																								<v-icon right>
																									mdi-script-text
																								</v-icon>
																							</v-btn>
																						</div>
																					</v-flex>
																				</v-layout>
																			</v-flex>
																		</v-layout>
																	</v-flex>
																</v-layout>
															</div>
														</v-card>
													</v-flex>
												</div>
											</template>
										</v-layout>
									</div>
								</div>
							</v-flex>
						</v-card>

						<v-layout column class="mt-5">
							<v-flex xs12>
								<div align="center">
									<v-pagination v-if="length != 0" :length="submittedApplicationLength" total-visible="10"
										v-model="submittedApplicationPagination.current_page" @input="changeSubmittedApplicationPage()" circle>
									</v-pagination>
								</div>
							</v-flex>
							<v-flex xs12>
								<div v-if="length != 0" align="center" class="mt-5">
									<b class="primary--text">Total: </b>{{ submittedApplicationPagination.total | formatNumber }}
								</div>
							</v-flex>
						</v-layout>
					</div>
					<div v-if="activeTab == 'review'">
						<v-card outlined class="mt-5 pa-3" style="border-radius: 25px;">
							<v-flex xs12 class="mt-10 mb-2">
								<div class="mx-5">
									<v-layout row wrap>
										<v-flex xs12 md4>
											<h1>Review Applications</h1>
										</v-flex>
										<v-flex xs12 md4>
											<div class="pa-1">
												<v-text-field class="text_field header" outlined dense label="Search"
													append-icon="mdi-undo-variant" @click:append="resetSearch()"
													v-on:keyup.enter="search" v-model="searchTerm"
													@input="enableSearch()"></v-text-field>
											</div>

										</v-flex>
										<v-flex xs12 md2>
											<div class="pa-1">
												<v-btn class="secondary white--text text-none" depressed block
													@click="search" :loading="searchLoader"
													:disabled="searchButtonDisabled">
													Search
													<v-icon right>mdi-magnify</v-icon>
												</v-btn>
											</div>
										</v-flex>
										<v-flex xs12 md2>
											<div class="pa-1">
												<v-btn class="primary white--text text-none" depressed block
													@click="csvDownload('reviewApplications')" :loading="csvLoading">
													Download CSV
													<v-icon right>mdi-microsoft-excel</v-icon>
												</v-btn>												
											</div>
										</v-flex>
									</v-layout>
								</div>
							</v-flex>
							<div class="mx-5 mt-9">
								<v-progress-linear v-if="reviewApplicationLoader" height="1" indeterminate
									color="primary"></v-progress-linear>
							</div>
							<v-divider class="mx-5"></v-divider>
							<v-flex xs12 class="mt-5">
								<div v-if="reviewApplications.length == 0">
									<v-card elevation="0">
										<v-layout row wrap>
											<v-flex xs12 md1>
												<v-container fill-height fluid>
													<v-row align="center" justify="center">
														<v-col class="text-center">
															<v-icon large class="primary--text">
																mdi-alert-circle-outline
															</v-icon>
														</v-col>
													</v-row>
												</v-container>
											</v-flex>
											<v-flex xs12 md11>
												<v-container fill-height fluid>
													<v-row align="center" justify="center">
														<v-col class="text-center"> No Application Found </v-col>
													</v-row>
												</v-container>
											</v-flex>
										</v-layout>
									</v-card>
								</div>
								<div v-else>
									<div class="hidden-sm-and-down">
										<v-card elevation="0" class="pa-2">
											<v-simple-table>
												<template v-slot:default>
													<thead class="header">
														<tr class="tableHeader">
															<th class="text-left text--text">#</th>
															<th class="text-left text--text">Images</th>
															<th class="text-left text--text">Applicant</th>
															<th class="text-left text--text">Role</th>
															<th class="text-right text--text">Rating</th>
															<th class="text-left text--text">Source</th>
															<th class="text-left text--text">Date Added</th>
															<th class="text-right text--text">Actions</th>
														</tr>
													</thead>
													<tbody>
														<tr v-for="(item, index) in reviewApplications" :key="item.id">
															<td>
																{{ index + 1 }}
															</td>
															<td>
																<v-avatar size="40" class="primary title white--text mx-1"
																	v-if="item.user.prof_pic == null">
																	{{ item.user.first_name[0] }}
																</v-avatar>
																<v-avatar size="40" class="primary title mx-1" v-else>
																	<img :src="path + '/storage/profile_pics/' + item.user.prof_pic"
																	>
																</v-avatar>
																
															</td>
															<td>{{ item.user.first_name }} {{ item.user.middle_name }} {{ item.user.last_name }}</td>
															<td>
																<div class="green--text" v-for="role in item.user.roles">
																	{{ role.name }}
																</div>
															</td>
															<td>
																<div align="right" v-if="item.applicationScore==0">
																	<i>Pending</i>
																</div>
																<div v-else>
																	<div align="right" class="red--text" v-if="item.applicationScore>0 && item.applicationScore<=33.33">
																		{{ item.applicationScore }}%
																	</div>
																	<div align="right" class="orange--text" v-if="item.applicationScore>33.33 && item.applicationScore<=66.66">
																		{{ item.applicationScore }}%
																	</div>
																	<div align="right" class="green--text" v-if="item.applicationScore>66.66">
																		{{ item.applicationScore }}%
																	</div>
																</div>																
															</td>
															<td>
																{{ item.marketing_source.name }}
															</td>
															<td>
																{{
																	item.created_at | moment("DD/MM/YYYY - hh:mm a")
																}}
															</td>
															<td>
																<div align="right">
																	<v-tooltip top v-if="$can('application_view')">
																		<template v-slot:activator="{ on, attrs }">
																			<v-btn icon class="button mr-1 universal--text"
																				@click="showApplication(item)">
																				<v-icon small> mdi-eye </v-icon>
																			</v-btn>
																		</template>
																		<span>View</span>
																	</v-tooltip>
																	<!-- <v-tooltip top v-if="$can('application_edit')">

																		<template v-slot:activator="{ on, attrs }">
																			<v-btn icon class="button mr-1 success--text"
																				@click="editApplication(item)" v-bind="attrs"
																				v-on="on">
																				<v-icon small> mdi-pencil </v-icon>
																			</v-btn>
																		</template>
																		<span>Edit</span>
																	</v-tooltip> -->

																	<v-tooltip top v-if="$can('application_archive')">
																		<template v-slot:activator="{ on, attrs }">
																			<v-btn icon class="button mr-1 error--text"
																				@click="deleteApplication(item)"
																				:loading="loading && applicationIndex == item.id"
																				v-bind="attrs" v-on="on">
																				<v-icon small> mdi-delete </v-icon>
																			</v-btn>
																		</template>
																		<span>delete</span>
																	</v-tooltip>

																	<v-tooltip top
																		v-if="$can('application_review')">

																		<template v-slot:activator="{ on, attrs }">
																			<v-btn icon class="button mr-1 red--text"
																				@click="applicationReview(item, 'rejectedStage', 'down')"
																				:loading="downgradeLoading && applicationIndex == item.id"
																				v-bind="attrs" v-on="on">
																				<v-icon small> mdi-cancel
																				</v-icon>
																			</v-btn>
																		</template>
																		<span>Reject Application</span>
																	</v-tooltip>
																	<v-tooltip top
																		v-if="$can('application_review')">

																		<template v-slot:activator="{ on, attrs }">
																			<v-btn icon class="button mr-1 green--text"
																				@click="applicationReview(item, 'selectedForRating', 'up')"
																				:loading="upgradeLoading && applicationIndex == item.id"
																				v-bind="attrs" v-on="on">
																				<v-icon small> mdi-arrow-right
																				</v-icon>
																			</v-btn>
																		</template>
																		<span>Select for Rating</span>
																	</v-tooltip>
																	<v-tooltip top v-if="$can('log_view')">

																		<template v-slot:activator="{ on, attrs }">
																			<v-btn icon class="button mr-1 purple--text"
																				@click="viewLog(item)" v-bind="attrs"
																				v-on="on">
																				<v-icon small> mdi-script-text </v-icon>
																			</v-btn>
																		</template>
																		<span>Log</span>
																	</v-tooltip>

																</div>
															</td>
														</tr>
													</tbody>
												</template>
											</v-simple-table>
										</v-card>

									</div>
									<div class="hidden-md-and-up">
										<v-layout column>

											<template v-for="(application, index) in reviewApplications">
												<div :key="index">
													<v-flex xs12 class="mb-2">
														<v-card elevation="0">
															<div class="pa-5">
																<v-layout column>
																	<v-flex xs12>
																		<v-layout row wrap>
																			<v-flex xs11>
																				<div class="title primary--text ml-3">
																					{{ application.user.first_name }} {{ application.user.middle_name }} {{ application.user.last_name }}
																				</div>
																			</v-flex>
																			<v-flex xs1>
																				<v-btn depressed class="red--text" :loading="loading && applicationIndex == application.id
			" icon @click="deleteApplication(application)" v-if="$can('application_archive')">
																					<v-icon> mdi-delete </v-icon>
																				</v-btn>
																			</v-flex>
																		</v-layout>
																	</v-flex>
																	<v-divider class="mt-2"></v-divider>
																	<v-flex xs12 class="mt-1">
																		<v-layout column>
																			<v-flex xs12 class="mt-1">
																				<div>
																					<b>Applicant</b>
																				</div>
																				<div>
																					{{ application.user.first_name }} {{ application.user.middle_name }} {{ application.user.last_name }}
																				</div>
																			</v-flex>
																			<v-flex xs12 class="mt-1">
																				<div>
																					<b>Venture</b>
																				</div>
																				<div>
																					{{ application.user.first_name }} {{ application.user.middle_name }} {{ application.user.last_name }}
																				</div>
																			</v-flex>
																			<v-flex xs12 class="mt-1">
																				<div>
																					<b>Rating</b>
																				</div>
																				<div>
																					<div v-if="application.applicationScore==0">
																						<i>Pending</i>
																					</div>
																					<div v-else>
																						<div class="red--text" v-if="application.applicationScore>0 && application.applicationScore<=33.33">
																							{{ application.applicationScore }}%
																						</div>
																						<div class="orange--text" v-if="application.applicationScore>33.33 && application.applicationScore<=66.66">
																							{{ application.applicationScore }}%
																						</div>
																						<div class="green--text" v-if="application.applicationScore>66.66">
																							{{ application.applicationScore }}%
																						</div>
																					</div>	
																				</div>
																			</v-flex>
																			<v-flex xs12 class="mt-1">
																				<div>
																					<b>Source</b>
																				</div>
																				<div>
																					{{
																						application.marketing_source.name
																					}}
																				</div>
																			</v-flex>
																			<v-flex xs12 class="mt-1">
																				<div>
																					<b>Date Added</b>
																				</div>
																				<div>
																					{{
																						application.created_at
																						| moment("DD/MM/YYYY - hh:mm a")
																					}}
																				</div>
																			</v-flex>
																			<v-flex xs12 class="mt-5">
																				<v-layout row wrap>
																					<v-flex xs12>
																						<div class="pa-1">
																							<v-btn
																								v-if="$can('application_view')"
																								depressed class="
																								primary
																								text-none
																								white--text
																								" block @click="showApplication(application)">View
																								<v-icon right> mdi-eye
																								</v-icon>
																							</v-btn>
																						</div>
																					</v-flex>
																					<!-- <v-flex xs12>
																						<div class="pa-1">
																							<v-btn depressed
																								class="blue text-none white--text"
																								block
																								@click="editApplication(application)"
																								v-if="$can('application_edit')">Edit
																								<v-icon right>
																									mdi-border-color
																								</v-icon>
																							</v-btn>
																						</div>
																					</v-flex> -->
																					<v-flex xs12>
																						<div class="pa-1">
																							<v-btn depressed
																								class="green text-none white--text"
																								block
																								@click="applicationReview(application, 'selectedForRating', 'up')"
																								:loading="upgradeLoading && applicationIndex == application.id"
																								v-if="$can('application_review')">Select for Rating
																								<v-icon right>
																									mdi-arrow-right
																								</v-icon>
																							</v-btn>
																						</div>
																					</v-flex>
																					<v-flex xs12>
																						<div class="pa-1">
																							<v-btn depressed
																								class="red text-none white--text"
																								block
																								@click="applicationReview(application, 'rejectedStage', 'down')"
																								:loading="downgradeLoading && applicationIndex == application.id"
																								v-if="$can('application_review')">Reject Application
																								<v-icon right>
																									mdi-cancel
																								</v-icon>
																							</v-btn>
																						</div>
																					</v-flex>
																					
																					<v-flex xs12>
																						<div class="pa-1">
																							<v-btn v-if="$can('log_view')"
																								depressed class="
													purple
													text-none
													white--text
													" block @click="viewLog(application)">Log
																								<v-icon right>
																									mdi-script-text
																								</v-icon>
																							</v-btn>
																						</div>
																					</v-flex>
																				</v-layout>
																			</v-flex>
																		</v-layout>
																	</v-flex>
																</v-layout>
															</div>
														</v-card>
													</v-flex>
												</div>
											</template>
										</v-layout>
									</div>
								</div>
							</v-flex>
						</v-card>

						<v-layout column class="mt-5">
							<v-flex xs12>
								<div align="center">
									<v-pagination v-if="length != 0" :length="reviewApplicationLength" total-visible="10"
										v-model="reviewApplicationPagination.current_page" @input="changeReviewApplicationPage()" circle>
									</v-pagination>
								</div>
							</v-flex>
							<v-flex xs12>
								<div v-if="length != 0" align="center" class="mt-5">
									<b class="primary--text">Total: </b>{{ reviewApplicationPagination.total | formatNumber }}
								</div>
							</v-flex>
						</v-layout>
					</div>
					<div v-if="activeTab == 'rejected'">
						<v-card outlined class="mt-5 pa-3" style="border-radius: 25px;">
							<v-flex xs12 class="mt-10 mb-2">
								<div class="mx-5">
									<v-layout row wrap>
										<v-flex xs12 md4>
											<h1>Rejected Applications</h1>
										</v-flex>
										<v-flex xs12 md4>
											<div class="pa-1">
												<v-text-field class="text_field header" outlined dense label="Search"
													append-icon="mdi-undo-variant" @click:append="resetSearch()"
													v-on:keyup.enter="search" v-model="searchTerm"
													@input="enableSearch()"></v-text-field>
											</div>

										</v-flex>
										<v-flex xs12 md2>
											<div class="pa-1">
												<v-btn class="secondary white--text text-none" depressed block
													@click="search" :loading="searchLoader"
													:disabled="searchButtonDisabled">
													Search
													<v-icon right>mdi-magnify</v-icon>
												</v-btn>
											</div>
										</v-flex>
										<v-flex xs12 md2>
											<div class="pa-1">
												<v-btn class="primary white--text text-none" depressed block
													@click="csvDownload('rejectedApplications')" :loading="csvLoading">
													Download CSV
													<v-icon right>mdi-microsoft-excel</v-icon>
												</v-btn>												
											</div>
										</v-flex>
									</v-layout>
								</div>
							</v-flex>
							<div class="mx-5 mt-9">
								<v-progress-linear v-if="rejectedApplicationLoader" height="1" indeterminate
									color="primary"></v-progress-linear>
							</div>
							<v-divider class="mx-5"></v-divider>
							<v-flex xs12 class="mt-5">
								<div v-if="rejectedApplications.length == 0">
									<v-card elevation="0">
										<v-layout row wrap>
											<v-flex xs12 md1>
												<v-container fill-height fluid>
													<v-row align="center" justify="center">
														<v-col class="text-center">
															<v-icon large class="primary--text">
																mdi-alert-circle-outline
															</v-icon>
														</v-col>
													</v-row>
												</v-container>
											</v-flex>
											<v-flex xs12 md11>
												<v-container fill-height fluid>
													<v-row align="center" justify="center">
														<v-col class="text-center"> No Application Found </v-col>
													</v-row>
												</v-container>
											</v-flex>
										</v-layout>
									</v-card>
								</div>
								<div v-else>
									<div class="hidden-sm-and-down">
										<v-card elevation="0" class="pa-2">
											<v-simple-table>
												<template v-slot:default>
													<thead class="header">
														<tr class="tableHeader">
															<th class="text-left text--text">#</th>
															<th class="text-left text--text">Images</th>
															<th class="text-left text--text">Applicant</th>
															<th class="text-left text--text">Role</th>
															<th class="text-right text--text">Rating</th>
															<th class="text-left text--text">Source</th>
															<th class="text-left text--text">Date Added</th>
															<th class="text-right text--text">Actions</th>
														</tr>
													</thead>
													<tbody>
														<tr v-for="(item, index) in rejectedApplications" :key="item.id">
															<td>
																{{ index + 1 }}
															</td>
															<td>
																<v-avatar size="40" class="primary title white--text mx-1"
																	v-if="item.user.prof_pic == null">
																	{{ item.user.first_name[0] }}
																</v-avatar>
																<v-avatar size="40" class="primary title mx-1" v-else>
																	<img :src="path + '/storage/profile_pics/' + item.user.prof_pic"
																	>
																</v-avatar>
																
															</td>
															<td>{{ item.user.first_name }} {{ item.user.middle_name }} {{ item.user.last_name }}</td>
															<td>
																<div class="green--text" v-for="role in item.user.roles">
																	{{ role.name }}
																</div>
															</td>
															<td>
																<div align="right" v-if="item.applicationScore==0">
																	<i>Pending</i>
																</div>
																<div v-else>
																	<div align="right" class="red--text" v-if="item.applicationScore>0 && item.applicationScore<=33.33">
																		{{ item.applicationScore }}%
																	</div>
																	<div align="right" class="orange--text" v-if="item.applicationScore>33.33 && item.applicationScore<=66.66">
																		{{ item.applicationScore }}%
																	</div>
																	<div align="right" class="green--text" v-if="item.applicationScore>66.66">
																		{{ item.applicationScore }}%
																	</div>
																</div>																
															</td>
															<td>
																{{ item.marketing_source.name }}
															</td>
															<td>
																{{
																	item.created_at | moment("DD/MM/YYYY - hh:mm a")
																}}
															</td>
															<td>
																<div align="right">
																	<v-tooltip top v-if="$can('application_view')">
																		<template v-slot:activator="{ on, attrs }">
																			<v-btn icon class="button mr-1 universal--text"
																				@click="showApplication(item)">
																				<v-icon small> mdi-eye </v-icon>
																			</v-btn>
																		</template>
																		<span>View</span>
																	</v-tooltip>
																	<!-- <v-tooltip top v-if="$can('application_edit')">

																		<template v-slot:activator="{ on, attrs }">
																			<v-btn icon class="button mr-1 success--text"
																				@click="editApplication(item)" v-bind="attrs"
																				v-on="on">
																				<v-icon small> mdi-pencil </v-icon>
																			</v-btn>
																		</template>
																		<span>Edit</span>
																	</v-tooltip> -->

																	<v-tooltip top v-if="$can('application_archive')">
																		<template v-slot:activator="{ on, attrs }">
																			<v-btn icon class="button mr-1 error--text"
																				@click="deleteApplication(item)"
																				:loading="loading && applicationIndex == item.id"
																				v-bind="attrs" v-on="on">
																				<v-icon small> mdi-delete </v-icon>
																			</v-btn>
																		</template>
																		<span>delete</span>
																	</v-tooltip>

																	<v-tooltip top
																		v-if="$can('application_review')">

																		<template v-slot:activator="{ on, attrs }">
																			<v-btn icon class="button mr-1 red--text"
																				@click="applicationReview(item, 'reviewStage', 'down')"
																				:loading="downgradeLoading && applicationIndex == item.id"
																				v-bind="attrs" v-on="on">
																				<v-icon small> mdi-arrow-left
																				</v-icon>
																			</v-btn>
																		</template>
																		<span>Send Back to Review</span>
																	</v-tooltip>
																	<v-tooltip top
																		v-if="$can('application_review')">

																		<template v-slot:activator="{ on, attrs }">
																			<v-btn icon class="button mr-1 green--text"
																				@click="applicationReview(item, 'selectedForRating', 'up')"
																				:loading="upgradeLoading && applicationIndex == item.id"
																				v-bind="attrs" v-on="on">
																				<v-icon small> mdi-arrow-right
																				</v-icon>
																			</v-btn>
																		</template>
																		<span>Select for Rating</span>
																	</v-tooltip>
																	<v-tooltip top v-if="$can('log_view')">

																		<template v-slot:activator="{ on, attrs }">
																			<v-btn icon class="button mr-1 purple--text"
																				@click="viewLog(item)" v-bind="attrs"
																				v-on="on">
																				<v-icon small> mdi-script-text </v-icon>
																			</v-btn>
																		</template>
																		<span>Log</span>
																	</v-tooltip>

																</div>
															</td>
														</tr>
													</tbody>
												</template>
											</v-simple-table>
										</v-card>

									</div>
									<div class="hidden-md-and-up">
										<v-layout column>

											<template v-for="(application, index) in rejectedApplications">
												<div :key="index">
													<v-flex xs12 class="mb-2">
														<v-card elevation="0">
															<div class="pa-5">
																<v-layout column>
																	<v-flex xs12>
																		<v-layout row wrap>
																			<v-flex xs11>
																				<div class="title primary--text ml-3">
																					{{ application.user.first_name }} {{ application.user.middle_name }} {{ application.user.last_name }}
																				</div>
																			</v-flex>
																			<v-flex xs1>
																				<v-btn depressed class="red--text" :loading="loading && applicationIndex == application.id
			" icon @click="deleteApplication(application)" v-if="$can('application_archive')">
																					<v-icon> mdi-delete </v-icon>
																				</v-btn>
																			</v-flex>
																		</v-layout>
																	</v-flex>
																	<v-divider class="mt-2"></v-divider>
																	<v-flex xs12 class="mt-1">
																		<v-layout column>
																			<v-flex xs12 class="mt-1">
																				<div>
																					<b>Applicant</b>
																				</div>
																				<div>
																					{{ application.user.first_name }} {{ application.user.middle_name }} {{ application.user.last_name }}
																				</div>
																			</v-flex>
																			<v-flex xs12 class="mt-1">
																				<div>
																					<b>Venture</b>
																				</div>
																				<div>
																					{{ application.user.first_name }} {{ application.user.middle_name }} {{ application.user.last_name }}
																				</div>
																			</v-flex>
																			<v-flex xs12 class="mt-1">
																				<div>
																					<b>Rating</b>
																				</div>
																				<div>
																					<div v-if="application.applicationScore==0">
																						<i>Pending</i>
																					</div>
																					<div v-else>
																						<div class="red--text" v-if="application.applicationScore>0 && application.applicationScore<=33.33">
																							{{ application.applicationScore }}%
																						</div>
																						<div class="orange--text" v-if="application.applicationScore>33.33 && application.applicationScore<=66.66">
																							{{ application.applicationScore }}%
																						</div>
																						<div class="green--text" v-if="application.applicationScore>66.66">
																							{{ application.applicationScore }}%
																						</div>
																					</div>	
																				</div>
																			</v-flex>
																			<v-flex xs12 class="mt-1">
																				<div>
																					<b>Source</b>
																				</div>
																				<div>
																					{{
																						application.marketing_source.name
																					}}
																				</div>
																			</v-flex>
																			<v-flex xs12 class="mt-1">
																				<div>
																					<b>Date Added</b>
																				</div>
																				<div>
																					{{
																						application.created_at
																						| moment("DD/MM/YYYY - hh:mm a")
																					}}
																				</div>
																			</v-flex>
																			<v-flex xs12 class="mt-5">
																				<v-layout row wrap>
																					<v-flex xs12>
																						<div class="pa-1">
																							<v-btn
																								v-if="$can('application_view')"
																								depressed class="
																								primary
																								text-none
																								white--text
																								" block @click="showApplication(application)">View
																								<v-icon right> mdi-eye
																								</v-icon>
																							</v-btn>
																						</div>
																					</v-flex>
																					<!-- <v-flex xs12>
																						<div class="pa-1">
																							<v-btn depressed
																								class="blue text-none white--text"
																								block
																								@click="editApplication(application)"
																								v-if="$can('application_edit')">Edit
																								<v-icon right>
																									mdi-border-color
																								</v-icon>
																							</v-btn>
																						</div>
																					</v-flex> -->
																					<v-flex xs12>
																						<div class="pa-1">
																							<v-btn depressed
																								class="red text-none white--text"
																								block
																								@click="applicationReview(application, 'reviewStage', 'down')"
																								:loading="downgradeLoading && applicationIndex == application.id"
																								v-if="$can('application_review')">Send Back to Review
																								<v-icon right>
																									mdi-arrow-left
																								</v-icon>
																							</v-btn>
																						</div>
																					</v-flex>
																					<v-flex xs12>
																						<div class="pa-1">
																							<v-btn depressed
																								class="green text-none white--text"
																								block
																								@click="applicationReview(application, 'selectedForRating', 'up')"
																								:loading="upgradeLoading && applicationIndex == application.id"
																								v-if="$can('application_review')">Select for Rating
																								<v-icon right>
																									mdi-arrow-right
																								</v-icon>
																							</v-btn>
																						</div>
																					</v-flex>
																					<v-flex xs12>
																						<div class="pa-1">
																							<v-btn v-if="$can('log_view')"
																								depressed class="
													purple
													text-none
													white--text
													" block @click="viewLog(application)">Log
																								<v-icon right>
																									mdi-script-text
																								</v-icon>
																							</v-btn>
																						</div>
																					</v-flex>
																				</v-layout>
																			</v-flex>
																		</v-layout>
																	</v-flex>
																</v-layout>
															</div>
														</v-card>
													</v-flex>
												</div>
											</template>
										</v-layout>
									</div>
								</div>
							</v-flex>
						</v-card>

						<v-layout column class="mt-5">
							<v-flex xs12>
								<div align="center">
									<v-pagination v-if="length != 0" :length="rejectedApplicationLength" total-visible="10"
										v-model="rejectedApplicationPagination.current_page" @input="changeRejectedApplicationPage()" circle>
									</v-pagination>
								</div>
							</v-flex>
							<v-flex xs12>
								<div v-if="length != 0" align="center" class="mt-5">
									<b class="primary--text">Total: </b>{{ rejectedApplicationPagination.total | formatNumber }}
								</div>
							</v-flex>
						</v-layout>
					</div>
					<div v-if="activeTab == 'selectedForRating'">
						<v-card outlined class="mt-5 pa-3" style="border-radius: 25px;">
							<v-flex xs12 class="mt-10 mb-2">
								<div class="mx-5">
									<v-layout row wrap>
										<v-flex xs12 md4>
											<h1>Selected for Rating</h1>
										</v-flex>
										<v-flex xs12 md4>
											<div class="pa-1">
												<v-text-field class="text_field header" outlined dense label="Search"
													append-icon="mdi-undo-variant" @click:append="resetSearch()"
													v-on:keyup.enter="search" v-model="searchTerm"
													@input="enableSearch()"></v-text-field>
											</div>

										</v-flex>
										<v-flex xs12 md2>
											<div class="pa-1">
												<v-btn class="secondary white--text text-none" depressed block
													@click="search" :loading="searchLoader"
													:disabled="searchButtonDisabled">
													Search
													<v-icon right>mdi-magnify</v-icon>
												</v-btn>
											</div>
										</v-flex>
										<v-flex xs12 md2>
											<div class="pa-1">
												<v-btn class="primary white--text text-none" depressed block
													@click="csvDownload('selectedForRatingApplications')" :loading="csvLoading">
													Download CSV
													<v-icon right>mdi-microsoft-excel</v-icon>
												</v-btn>
											</div>
										</v-flex>
									</v-layout>
								</div>
							</v-flex>
							<div class="mx-5 mt-9">
								<v-progress-linear v-if="selectedForRatingApplicationLoader" height="1" indeterminate
									color="primary"></v-progress-linear>
							</div>
							<v-divider class="mx-5"></v-divider>
							<v-flex xs12 class="mt-5">
								<div v-if="selectedForRatingApplications.length == 0">
									<v-card elevation="0">
										<v-layout row wrap>
											<v-flex xs12 md1>
												<v-container fill-height fluid>
													<v-row align="center" justify="center">
														<v-col class="text-center">
															<v-icon large class="primary--text">
																mdi-alert-circle-outline
															</v-icon>
														</v-col>
													</v-row>
												</v-container>
											</v-flex>
											<v-flex xs12 md11>
												<v-container fill-height fluid>
													<v-row align="center" justify="center">
														<v-col class="text-center"> No Application Found </v-col>
													</v-row>
												</v-container>
											</v-flex>
										</v-layout>
									</v-card>
								</div>
								<div v-else>
									<div class="hidden-sm-and-down">
										<v-card elevation="0" class="pa-2">
											<v-simple-table>
												<template v-slot:default>
													<thead class="header">
														<tr class="tableHeader">
															<th class="text-left text--text">#</th>
															<th class="text-left text--text">Images</th>
															<th class="text-left text--text">Applicant</th>
															<th class="text-left text--text">Role</th>
															<th class="text-right text--text">Rating</th>
															<th class="text-left text--text">Source</th>
															<th class="text-left text--text">Date Added</th>
															<th class="text-right text--text">Actions</th>
														</tr>
													</thead>
													<tbody>
														<tr v-for="(item, index) in selectedForRatingApplications" :key="item.id">
															<td>
																{{ index + 1 }}
															</td>
															<td>
																<v-avatar size="40" class="primary title white--text mx-1"
																	v-if="item.user.prof_pic == null">
																	{{ item.user.first_name[0] }}
																</v-avatar>
																<v-avatar size="40" class="primary title mx-1" v-else>
																	<img :src="path + '/storage/profile_pics/' + item.user.prof_pic"
																	>
																</v-avatar>
																
															</td>
															<td>{{ item.user.first_name }} {{ item.user.middle_name }} {{ item.user.last_name }}</td>
															<td>
																<div class="green--text" v-for="role in item.user.roles">
																	{{ role.name }}
																</div>
															</td>
															<td>
																<div align="right" v-if="item.applicationScore==0">
																	<i>Pending</i>
																</div>
																<div v-else>
																	<div align="right" class="red--text" v-if="item.applicationScore>0 && item.applicationScore<=33.33">
																		{{ item.applicationScore }}%
																	</div>
																	<div align="right" class="orange--text" v-if="item.applicationScore>33.33 && item.applicationScore<=66.66">
																		{{ item.applicationScore }}%
																	</div>
																	<div align="right" class="green--text" v-if="item.applicationScore>66.66">
																		{{ item.applicationScore }}%
																	</div>
																</div>																
															</td>
															<td>
																{{ item.marketing_source.name }}
															</td>
															<td>
																{{
																	item.created_at | moment("DD/MM/YYYY - hh:mm a")
																}}
															</td>
															<td>
																<div align="right">
																	<v-tooltip top v-if="$can('application_view')">
																		<template v-slot:activator="{ on, attrs }">
																			<v-btn icon class="button mr-1 universal--text"
																				@click="showApplication(item)">
																				<v-icon small> mdi-eye </v-icon>
																			</v-btn>
																		</template>
																		<span>View</span>
																	</v-tooltip>
																	<!-- <v-tooltip top v-if="$can('application_edit')">

																		<template v-slot:activator="{ on, attrs }">
																			<v-btn icon class="button mr-1 success--text"
																				@click="editApplication(item)" v-bind="attrs"
																				v-on="on">
																				<v-icon small> mdi-pencil </v-icon>
																			</v-btn>
																		</template>
																		<span>Edit</span>
																	</v-tooltip> -->

																	<v-tooltip top v-if="$can('application_archive')">
																		<template v-slot:activator="{ on, attrs }">
																			<v-btn icon class="button mr-1 error--text"
																				@click="deleteApplication(item)"
																				:loading="loading && applicationIndex == item.id"
																				v-bind="attrs" v-on="on">
																				<v-icon small> mdi-delete </v-icon>
																			</v-btn>
																		</template>
																		<span>delete</span>
																	</v-tooltip>

																	<v-tooltip top
																		v-if="$can('application_review')">

																		<template v-slot:activator="{ on, attrs }">
																			<v-btn icon class="button mr-1 orange--text"
																				@click="applicationReview(item, 'reviewStage', 'down')"
																				:loading="downgradeLoading && applicationIndex == item.id"
																				v-bind="attrs" v-on="on">
																				<v-icon small> mdi-arrow-left
																				</v-icon>
																			</v-btn>
																		</template>
																		<span>Send Back to Review</span>
																	</v-tooltip>
																	<v-tooltip top
																		v-if="$can('application_review')">

																		<template v-slot:activator="{ on, attrs }">
																			<v-btn icon class="button mr-1 red--text"
																				@click="applicationReview(item, 'rejectedStage', 'downSpec')"
																				:loading="rejectLoading && applicationIndex == item.id"
																				v-bind="attrs" v-on="on">
																				<v-icon small> mdi-cancel
																				</v-icon>
																			</v-btn>
																		</template>
																		<span>Reject Application</span>
																	</v-tooltip>
																	<v-tooltip top
																		v-if="$can('application_review')">

																		<template v-slot:activator="{ on, attrs }">
																			<v-btn icon class="button mr-1 green--text"
																				@click="applicationReview(item, 'shortlisted', 'up')"
																				:loading="upgradeLoading && applicationIndex == item.id"
																				v-bind="attrs" v-on="on">
																				<v-icon small> mdi-arrow-right
																				</v-icon>
																			</v-btn>
																		</template>
																		<span>Shortlist Application</span>
																	</v-tooltip>
																	<v-tooltip top v-if="$can('log_view')">

																		<template v-slot:activator="{ on, attrs }">
																			<v-btn icon class="button mr-1 purple--text"
																				@click="viewLog(item)" v-bind="attrs"
																				v-on="on">
																				<v-icon small> mdi-script-text </v-icon>
																			</v-btn>
																		</template>
																		<span>Log</span>
																	</v-tooltip>

																</div>
															</td>
														</tr>
													</tbody>
												</template>
											</v-simple-table>
										</v-card>

									</div>
									<div class="hidden-md-and-up">
										<v-layout column>

											<template v-for="(application, index) in selectedForRatingApplications">
												<div :key="index">
													<v-flex xs12 class="mb-2">
														<v-card elevation="0">
															<div class="pa-5">
																<v-layout column>
																	<v-flex xs12>
																		<v-layout row wrap>
																			<v-flex xs11>
																				<div class="title primary--text ml-3">
																					{{ application.user.first_name }} {{ application.user.middle_name }} {{ application.user.last_name }}
																				</div>
																			</v-flex>
																			<v-flex xs1>
																				<v-btn depressed class="red--text" :loading="loading && applicationIndex == application.id
			" icon @click="deleteApplication(application)" v-if="$can('application_archive')">
																					<v-icon> mdi-delete </v-icon>
																				</v-btn>
																			</v-flex>
																		</v-layout>
																	</v-flex>
																	<v-divider class="mt-2"></v-divider>
																	<v-flex xs12 class="mt-1">
																		<v-layout column>
																			<v-flex xs12 class="mt-1">
																				<div>
																					<b>Applicant</b>
																				</div>
																				<div>
																					{{ application.user.first_name }} {{ application.user.middle_name }} {{ application.user.last_name }}
																				</div>
																			</v-flex>
																			<v-flex xs12 class="mt-1">
																				<div>
																					<b>Venture</b>
																				</div>
																				<div>
																					{{ application.user.first_name }} {{ application.user.middle_name }} {{ application.user.last_name }}
																				</div>
																			</v-flex>
																			<v-flex xs12 class="mt-1">
																				<div>
																					<b>Rating</b>
																				</div>
																				<div>
																					<div v-if="application.applicationScore==0">
																						<i>Pending</i>
																					</div>
																					<div v-else>
																						<div class="red--text" v-if="application.applicationScore>0 && application.applicationScore<=33.33">
																							{{ application.applicationScore }}%
																						</div>
																						<div class="orange--text" v-if="application.applicationScore>33.33 && application.applicationScore<=66.66">
																							{{ application.applicationScore }}%
																						</div>
																						<div class="green--text" v-if="application.applicationScore>66.66">
																							{{ application.applicationScore }}%
																						</div>
																					</div>	
																				</div>
																			</v-flex>
																			<v-flex xs12 class="mt-1">
																				<div>
																					<b>Source</b>
																				</div>
																				<div>
																					{{
																						application.marketing_source.name
																					}}
																				</div>
																			</v-flex>
																			<v-flex xs12 class="mt-1">
																				<div>
																					<b>Date Added</b>
																				</div>
																				<div>
																					{{
																						application.created_at
																						| moment("DD/MM/YYYY - hh:mm a")
																					}}
																				</div>
																			</v-flex>
																			<v-flex xs12 class="mt-5">
																				<v-layout row wrap>
																					<v-flex xs12>
																						<div class="pa-1">
																							<v-btn
																								v-if="$can('application_view')"
																								depressed class="
																								primary
																								text-none
																								white--text
																								" block @click="showApplication(application)">View
																								<v-icon right> mdi-eye
																								</v-icon>
																							</v-btn>
																						</div>
																					</v-flex>
																					<!-- <v-flex xs12>
																						<div class="pa-1">
																							<v-btn depressed
																								class="blue text-none white--text"
																								block
																								@click="editApplication(application)"
																								v-if="$can('application_edit')">Edit
																								<v-icon right>
																									mdi-border-color
																								</v-icon>
																							</v-btn>
																						</div>
																					</v-flex> -->
																					<v-flex xs12>
																						<div class="pa-1">
																							<v-btn depressed
																								class="orange text-none white--text"
																								block
																								@click="applicationReview(application, 'reviewStage', 'down')"
																								:loading="downgradeLoading && applicationIndex == application.id"
																								v-if="$can('application_review')">Send Back to Review
																								<v-icon right>
																									mdi-arrow-left
																								</v-icon>
																							</v-btn>
																						</div>
																					</v-flex>
																					<v-flex xs12>
																						<div class="pa-1">
																							<v-btn depressed
																								class="red text-none white--text"
																								block
																								@click="applicationReview(application, 'rejectedStage', 'downSpec')"
																								:loading="rejectLoading && applicationIndex == application.id"
																								v-if="$can('application_review')">Reject Application
																								<v-icon right>
																									mdi-cancel
																								</v-icon>
																							</v-btn>
																						</div>
																					</v-flex>
																					<v-flex xs12>
																						<div class="pa-1">
																							<v-btn depressed
																								class="green text-none white--text"
																								block
																								@click="applicationReview(application, 'shortlisted', 'up')"
																								:loading="upgradeLoading && applicationIndex == application.id"
																								v-if="$can('application_review')">Shortlist Application
																								<v-icon right>
																									mdi-arrow-right
																								</v-icon>
																							</v-btn>
																						</div>
																					</v-flex>
																					<v-flex xs12>
																						<div class="pa-1">
																							<v-btn v-if="$can('log_view')"
																								depressed class="
													purple
													text-none
													white--text
													" block @click="viewLog(application)">Log
																								<v-icon right>
																									mdi-script-text
																								</v-icon>
																							</v-btn>
																						</div>
																					</v-flex>
																				</v-layout>
																			</v-flex>
																		</v-layout>
																	</v-flex>
																</v-layout>
															</div>
														</v-card>
													</v-flex>
												</div>
											</template>
										</v-layout>
									</div>
								</div>
							</v-flex>
						</v-card>

						<v-layout column class="mt-5">
							<v-flex xs12>
								<div align="center">
									<v-pagination v-if="length != 0" :length="selectedForRatingApplicationLength" total-visible="10"
										v-model="selectedForRatingApplicationPagination.current_page" @input="changeSelectedForRatingApplicationPage()" circle>
									</v-pagination>
								</div>
							</v-flex>
							<v-flex xs12>
								<div v-if="length != 0" align="center" class="mt-5">
									<b class="primary--text">Total: </b>{{ selectedForRatingApplicationPagination.total | formatNumber }}
								</div>
							</v-flex>
						</v-layout>
					</div>
					<div v-if="activeTab == 'shortlisted'">
						<v-card outlined class="mt-5 pa-3" style="border-radius: 25px;">
							<v-flex xs12 class="mt-10 mb-2">
								<div class="mx-5">
									<v-layout row wrap>
										<v-flex xs12 md4>
											<h1>Shortlisted Applications</h1>
										</v-flex>
										<v-flex xs12 md4>
											<div class="pa-1">
												<v-text-field class="text_field header" outlined dense label="Search"
													append-icon="mdi-undo-variant" @click:append="resetSearch()"
													v-on:keyup.enter="search" v-model="searchTerm"
													@input="enableSearch()"></v-text-field>
											</div>

										</v-flex>
										<v-flex xs12 md2>
											<div class="pa-1">
												<v-btn class="secondary white--text text-none" depressed block
													@click="search" :loading="searchLoader"
													:disabled="searchButtonDisabled">
													Search
													<v-icon right>mdi-magnify</v-icon>
												</v-btn>
											</div>
										</v-flex>
										<v-flex xs12 md2>
											<div class="pa-1">
												<v-btn class="primary white--text text-none" depressed block
													@click="csvDownload('shortlistedApplications')" :loading="csvLoading">
													Download CSV
													<v-icon right>mdi-microsoft-excel</v-icon>
												</v-btn>
											</div>
										</v-flex>
									</v-layout>
								</div>
							</v-flex>
							<div class="mx-5 mt-9">
								<v-progress-linear v-if="shortlistedApplicationLoader" height="1" indeterminate
									color="primary"></v-progress-linear>
							</div>
							<v-divider class="mx-5"></v-divider>
							<v-flex xs12 class="mt-5">
								<div v-if="shortlistedApplications.length == 0">
									<v-card elevation="0">
										<v-layout row wrap>
											<v-flex xs12 md1>
												<v-container fill-height fluid>
													<v-row align="center" justify="center">
														<v-col class="text-center">
															<v-icon large class="primary--text">
																mdi-alert-circle-outline
															</v-icon>
														</v-col>
													</v-row>
												</v-container>
											</v-flex>
											<v-flex xs12 md11>
												<v-container fill-height fluid>
													<v-row align="center" justify="center">
														<v-col class="text-center"> No Application Found </v-col>
													</v-row>
												</v-container>
											</v-flex>
										</v-layout>
									</v-card>
								</div>
								<div v-else>
									<div class="hidden-sm-and-down">
										<v-card elevation="0" class="pa-2">
											<v-simple-table>
												<template v-slot:default>
													<thead class="header">
														<tr class="tableHeader">
															<th class="text-left text--text">#</th>
															<th class="text-left text--text">Images</th>
															<th class="text-left text--text">Applicant</th>
															<th class="text-left text--text">Role</th>
															<th class="text-right text--text">Rating</th>
															<th class="text-left text--text">Source</th>
															<th class="text-left text--text">Date Added</th>
															<th class="text-right text--text">Actions</th>
														</tr>
													</thead>
													<tbody>
														<tr v-for="(item, index) in shortlistedApplications" :key="item.id">
															<td>
																{{ index + 1 }}
															</td>
															<td>
																<v-avatar size="40" class="primary title white--text mx-1"
																	v-if="item.user.prof_pic == null">
																	{{ item.user.first_name[0] }}
																</v-avatar>
																<v-avatar size="40" class="primary title mx-1" v-else>
																	<img :src="path + '/storage/profile_pics/' + item.user.prof_pic"
																	>
																</v-avatar>
																
															</td>
															<td>{{ item.user.first_name }} {{ item.user.middle_name }} {{ item.user.last_name }}</td>
															<td>
																<div class="green--text" v-for="role in item.user.roles">
																	{{ role.name }}
																</div>
															</td>
															<td>
																<div align="right" v-if="item.applicationScore==0">
																	<i>Pending</i>
																</div>
																<div v-else>
																	<div align="right" class="red--text" v-if="item.applicationScore>0 && item.applicationScore<=33.33">
																		{{ item.applicationScore }}%
																	</div>
																	<div align="right" class="orange--text" v-if="item.applicationScore>33.33 && item.applicationScore<=66.66">
																		{{ item.applicationScore }}%
																	</div>
																	<div align="right" class="green--text" v-if="item.applicationScore>66.66">
																		{{ item.applicationScore }}%
																	</div>
																</div>																
															</td>
															<td>
																{{ item.marketing_source.name }}
															</td>
															<td>
																{{
																	item.created_at | moment("DD/MM/YYYY - hh:mm a")
																}}
															</td>
															<td>
																<div align="right">
																	<v-tooltip top v-if="$can('application_view')">
																		<template v-slot:activator="{ on, attrs }">
																			<v-btn icon class="button mr-1 universal--text"
																				@click="showApplication(item)">
																				<v-icon small> mdi-eye </v-icon>
																			</v-btn>
																		</template>
																		<span>View</span>
																	</v-tooltip>
																	<!-- <v-tooltip top v-if="$can('application_edit')">

																		<template v-slot:activator="{ on, attrs }">
																			<v-btn icon class="button mr-1 success--text"
																				@click="editApplication(item)" v-bind="attrs"
																				v-on="on">
																				<v-icon small> mdi-pencil </v-icon>
																			</v-btn>
																		</template>
																		<span>Edit</span>
																	</v-tooltip> -->

																	<v-tooltip top v-if="$can('application_archive')">
																		<template v-slot:activator="{ on, attrs }">
																			<v-btn icon class="button mr-1 error--text"
																				@click="deleteApplication(item)"
																				:loading="loading && applicationIndex == item.id"
																				v-bind="attrs" v-on="on">
																				<v-icon small> mdi-delete </v-icon>
																			</v-btn>
																		</template>
																		<span>delete</span>
																	</v-tooltip>

																	<v-tooltip top
																		v-if="$can('application_review')">

																		<template v-slot:activator="{ on, attrs }">
																			<v-btn icon class="button mr-1 red--text"
																				@click="applicationReview(item, 'selectedForRating', 'down')"
																				:loading="downgradeLoading && applicationIndex == item.id"
																				v-bind="attrs" v-on="on">
																				<v-icon small> mdi-arrow-left
																				</v-icon>
																			</v-btn>
																		</template>
																		<span>Send Back to Rating</span>
																	</v-tooltip>
																	<v-tooltip top v-if="$can('log_view')">

																		<template v-slot:activator="{ on, attrs }">
																			<v-btn icon class="button mr-1 purple--text"
																				@click="viewLog(item)" v-bind="attrs"
																				v-on="on">
																				<v-icon small> mdi-script-text </v-icon>
																			</v-btn>
																		</template>
																		<span>Log</span>
																	</v-tooltip>

																</div>
															</td>
														</tr>
													</tbody>
												</template>
											</v-simple-table>
										</v-card>

									</div>
									<div class="hidden-md-and-up">
										<v-layout column>

											<template v-for="(application, index) in shortlistedApplications">
												<div :key="index">
													<v-flex xs12 class="mb-2">
														<v-card elevation="0">
															<div class="pa-5">
																<v-layout column>
																	<v-flex xs12>
																		<v-layout row wrap>
																			<v-flex xs11>
																				<div class="title primary--text ml-3">
																					{{ application.user.first_name }} {{ application.user.middle_name }} {{ application.user.last_name }}
																				</div>
																			</v-flex>
																			<v-flex xs1>
																				<v-btn depressed class="red--text" :loading="loading && applicationIndex == application.id
			" icon @click="deleteApplication(application)" v-if="$can('application_archive')">
																					<v-icon> mdi-delete </v-icon>
																				</v-btn>
																			</v-flex>
																		</v-layout>
																	</v-flex>
																	<v-divider class="mt-2"></v-divider>
																	<v-flex xs12 class="mt-1">
																		<v-layout column>
																			<v-flex xs12 class="mt-1">
																				<div>
																					<b>Applicant</b>
																				</div>
																				<div>
																					{{ application.user.first_name }} {{ application.user.middle_name }} {{ application.user.last_name }}
																				</div>
																			</v-flex>
																			<v-flex xs12 class="mt-1">
																				<div>
																					<b>Venture</b>
																				</div>
																				<div>
																					{{ application.user.first_name }} {{ application.user.middle_name }} {{ application.user.last_name }}
																				</div>
																			</v-flex>
																			<v-flex xs12 class="mt-1">
																				<div>
																					<b>Rating</b>
																				</div>
																				<div>
																					<div v-if="application.applicationScore==0">
																						<i>Pending</i>
																					</div>
																					<div v-else>
																						<div class="red--text" v-if="application.applicationScore>0 && application.applicationScore<=33.33">
																							{{ application.applicationScore }}%
																						</div>
																						<div class="orange--text" v-if="application.applicationScore>33.33 && application.applicationScore<=66.66">
																							{{ application.applicationScore }}%
																						</div>
																						<div class="green--text" v-if="application.applicationScore>66.66">
																							{{ application.applicationScore }}%
																						</div>
																					</div>	
																				</div>
																			</v-flex>
																			<v-flex xs12 class="mt-1">
																				<div>
																					<b>Source</b>
																				</div>
																				<div>
																					{{
																						application.marketing_source.name
																					}}
																				</div>
																			</v-flex>
																			<v-flex xs12 class="mt-1">
																				<div>
																					<b>Date Added</b>
																				</div>
																				<div>
																					{{
																						application.created_at
																						| moment("DD/MM/YYYY - hh:mm a")
																					}}
																				</div>
																			</v-flex>
																			<v-flex xs12 class="mt-5">
																				<v-layout row wrap>
																					<v-flex xs12>
																						<div class="pa-1">
																							<v-btn
																								v-if="$can('application_view')"
																								depressed class="
																								primary
																								text-none
																								white--text
																								" block @click="showApplication(application)">View
																								<v-icon right> mdi-eye
																								</v-icon>
																							</v-btn>
																						</div>
																					</v-flex>
																					<!-- <v-flex xs12>
																						<div class="pa-1">
																							<v-btn depressed
																								class="blue text-none white--text"
																								block
																								@click="editApplication(application)"
																								v-if="$can('application_edit')">Edit
																								<v-icon right>
																									mdi-border-color
																								</v-icon>
																							</v-btn>
																						</div>
																					</v-flex> -->
																					<v-flex xs12>
																						<div class="pa-1">
																							<v-btn depressed
																								class="red text-none white--text"
																								block
																								@click="applicationReview(application, 'selectedForRating', 'down')"
																								:loading="downgradeLoading && applicationIndex == application.id"
																								v-if="$can('application_review')">Send Back to Rating
																								<v-icon right>
																									mdi-arrow-left
																								</v-icon>
																							</v-btn>
																						</div>
																					</v-flex>
																					<v-flex xs12>
																						<div class="pa-1">
																							<v-btn v-if="$can('log_view')"
																								depressed class="
													purple
													text-none
													white--text
													" block @click="viewLog(application)">Log
																								<v-icon right>
																									mdi-script-text
																								</v-icon>
																							</v-btn>
																						</div>
																					</v-flex>
																				</v-layout>
																			</v-flex>
																		</v-layout>
																	</v-flex>
																</v-layout>
															</div>
														</v-card>
													</v-flex>
												</div>
											</template>
										</v-layout>
									</div>
								</div>
							</v-flex>
						</v-card>

						<v-layout column class="mt-5">
							<v-flex xs12>
								<div align="center">
									<v-pagination v-if="length != 0" :length="shortlistedApplicationLength" total-visible="10"
										v-model="selectedForRatingApplicationPagination.current_page" @input="changeSelectedForRatingApplicationPage()" circle>
									</v-pagination>
								</div>
							</v-flex>
							<v-flex xs12>
								<div v-if="length != 0" align="center" class="mt-5">
									<b class="primary--text">Total: </b>{{ shortlistedApplicationPagination.total | formatNumber }}
								</div>
							</v-flex>
						</v-layout>
					</div>

				</div>
	
				<div v-if="view == 'show'">
					<v-card outlined class="mt-5">
						<v-card-title class="tableHeader text--text">
							{{ assignedApplication.user.first_name }} {{ assignedApplication.user.middle_name }} {{ assignedApplication.user.last_name }}
							<v-spacer></v-spacer>
							<v-btn icon @click="changeView('default')">
								<v-icon class="text--text"> mdi-close </v-icon>
							</v-btn>
						</v-card-title>
						<v-card-text>
							<div class="mt-2">
								<v-tabs show-arrows v-model="applicantTab">
									<v-tabs-slider color="primary"></v-tabs-slider>

									<v-tab @click="subTab = 'applicant'">
										<div class="text-none primary--text">Applicant</div>
									</v-tab>
									<v-tab @click="subTab = 'aboutYou'">
										<div class="text-none primary--text">
											Questions
										</div>
									</v-tab>
									<v-tab @click="subTab = 'rating'">
										<div class="text-none primary--text">
											Rating
										</div>
									</v-tab>
									<!-- <v-tab @click="pdfDownload()" :loading="pdfLoading" class="pdf text-none white--text" style="border-radius: 5px;">
										<div class="mt-1">
											Download PDF
											<v-icon right class="white--text mb-1">mdi-file-pdf-box</v-icon>
										</div>
									</v-tab> -->
								</v-tabs>
								<div v-if="subTab == 'applicant'" class="mx-3 mt-7">
									<v-layout row wrap>
										<v-flex xs12 md3>
											<div align="center" class="mt-7">
												<v-avatar size="200" class="primary title white--text"
													v-if="assignedApplication.user.prof_pic == null">
													{{ assignedApplication.user.first_name[0] }}
												</v-avatar>
												<v-avatar size="200" class="primary title" v-else>
													<img :src="path + '/storage/profile_pics/' + assignedApplication.user.prof_pic"
													>
												</v-avatar>
											</div>
										</v-flex>
										<v-flex xs12 md9>
											<div class="pa-5">
												<v-layout row wrap>
													<v-flex xs12 md6>
														<v-layout column>
															<v-flex xs12 class="mt-7">
																<v-layout row wrap>
																	<v-flex xs12 md3>
																		<div><b>Name:</b></div>
																	</v-flex>
																	<v-flex xs12 md9>
																		{{ assignedApplication.user.first_name }} {{ assignedApplication.user.middle_name }} {{ assignedApplication.user.last_name }}
																	</v-flex>
																</v-layout>
															</v-flex>
															<v-flex xs12 class="mt-7">
																<v-layout row wrap>
																	<v-flex xs12 md3>
																		<div><b>Email Address:</b></div>
																	</v-flex>
																	<v-flex xs12 md9>
																		{{ assignedApplication.user.email }}
																	</v-flex>
																</v-layout>
															</v-flex>
															<v-flex xs12 class="mt-7">
																<v-layout row wrap>
																	<v-flex xs12 md3>
																		<div><b>Phone Number:</b></div>
																	</v-flex>
																	<v-flex xs12 md9>
																		{{ assignedApplication.user.phone }}
																	</v-flex>
																</v-layout>
															</v-flex>
															<v-flex xs12 class="mt-7">
																<v-layout row wrap>
																	<v-flex xs12 md3>
																		<div><b>Date of Birth:</b></div>
																	</v-flex>
																	<v-flex xs12 md9>
																		{{ assignedApplication.user.dob | moment("DD/MM/YYYY") }}
																	</v-flex>
																</v-layout>
															</v-flex>

														</v-layout>
													</v-flex>
													<v-flex xs12 md6>
														<v-layout column>
															<v-flex xs12 class="mt-7">
																<v-layout row wrap>
																	<v-flex xs12 md3>
																		<div><b>Nationality:</b></div>
																	</v-flex>
																	<v-flex xs12 md9>
																		{{ assignedApplication.user.country.name }}
																	</v-flex>
																</v-layout>
															</v-flex>
															<v-flex xs12 class="mt-7">
																<v-layout row wrap>
																	<v-flex xs12 md3>
																		<div><b>Town/City:</b></div>
																	</v-flex>
																	<v-flex xs12 md9>
																		{{ assignedApplication.user.state.name }}
																	</v-flex>
																</v-layout>
															</v-flex>
															<v-flex xs12 class="mt-7">
																<v-layout row wrap>
																	<v-flex xs12 md3>
																		<div><b>Gender:</b></div>
																	</v-flex>
																	<v-flex xs12 md9>
																		{{ assignedApplication.user.gender.name }}
																	</v-flex>
																</v-layout>
															</v-flex>
															<v-flex xs12 class="mt-7">
																<v-layout row wrap>
																	<v-flex xs12 md3>
																		<div><b>Role:</b></div>
																	</v-flex>
																	<v-flex xs12 md9>
																		<div class="green--text" v-for="role in assignedApplication.user.roles">
																			{{ role.name }}
																		</div>
																	</v-flex>
																</v-layout>
															</v-flex>
														</v-layout>
													</v-flex>
													<v-flex xs12 class="mt-10">
														<v-layout row wrap>
															<v-flex xs12>
																<div><b>Socials</b></div>
															</v-flex>
														</v-layout>
													</v-flex>
													<v-flex xs12 md6>
														<v-layout column>
															<v-flex xs12 class="mt-7">
																<v-layout row wrap>
																	<v-flex xs12 md3>
																		<div><b>LinkedIn:</b></div>
																	</v-flex>
																	<v-flex xs12 md9>
																		<div v-if="assignedApplication.user.linkedin_url!=null">
																			<a :href="assignedApplication.user.linkedin_url" style="text-decoration: none;">
																				{{ assignedApplication.user.linkedin_url }}
																			</a>
																		</div>
																		<div v-else>
																			N/A
																		</div>																		
																	</v-flex>
																</v-layout>
															</v-flex>
														</v-layout>
													</v-flex>
													<v-flex xs12>
														<div align="center">
															<v-btn depressed color="#F40F02" class="white--text text-none mt-5" @click="viewDocument('Curriculum Vitae')">
																View Curriculum Vitae <v-icon right small>mdi-file-pdf-box</v-icon>
															</v-btn>
														</div>
													</v-flex>
												</v-layout>												
											</div>
										</v-flex>
									</v-layout>
									
								</div>
								<div v-if="subTab == 'aboutYou'" class="mx-3 mt-7">
									<v-layout column>
										<div v-if="questions.length != 0">
												<template v-for="(question, index) in questions">
													<div :key="index">
														<v-flex xs12 class="my-2">
															<v-card class="mx-auto" elevation="0">
																<v-card-text>
																	<v-layout row wrap>
																		<v-flex xs1>
																			<div align="center" class="subtitle-1">
																				{{ index + 1 }}.
																			</div>
																		</v-flex>
																		<v-flex xs11>
																			<div class="subtitle-1">
																				<b>{{ question.question }}<span class="red--text" v-if="question.required==1">*</span></b>
																			</div>
																			<div class="font-italic caption">{{ question.description }}</div>
																			<div v-if="question.question_type.name == 'Single Select Multiple Choice Question'">
																				<v-radio-group
																					disabled
																					v-model="singleSelect[question.id]"
																					column>
																					<template v-for="(option, index) in question.multi_choice_options">
																						<div :key="index">
																							<v-radio
																								:label="option.option"
																								:value="option.id"
																								@change="checkOther(question.id, option, question)"></v-radio>
																						</div>
																					</template>
																					<div>
																						<p v-html="openEnded[question.id]" class="mt-3"></p>
																					</div>
																					<div v-if="childQuestions.length != 0"
																						class="mt-3">
																						<template
																							v-for="(question, index) in childQuestions[question.id]">
																							<div :key="index">
																								<v-flex xs12
																									class="my-2">
																									<v-card
																										:loading="loading"
																										class="mx-auto"
																										elevation="0">
																										<v-card-text>
																											<v-layout
																												row
																												wrap>
																												<v-flex
																													xs1>
																													<div align="center"
																														class="subtitle-1">
																														{{ index + 1 }}.
																													</div>
																												</v-flex>
																												<v-flex
																													xs11>
																													<div
																														class="subtitle-1">
																														<b>{{ question.question }}</b>
																													</div>
																													<div v-if="question.question_type.name == 'Single Select Multiple Choice Question'">
																														<v-radio-group
																															disabled
																															v-model="singleSelect[question.id]"
																															column>
																															<template
																																v-for="(option, index) in question.multi_choice_options">
																																<div
																																	:key="index">
																																	<v-radio
																																		:label="option.option"
																																		:value="option.id"
																																		@change="checkOther(question.id, option, question)"></v-radio>
																																</div>
																															</template>
																															<div
																																v-if="otherQuestions[question.id] == true">
																																	{{ openEnded[question.id] }}
																															</div>
																															<div
																																v-if="childQuestions.length != 0">
																																{{ childQuestions[question.id] }}
																															</div>
																														</v-radio-group>
																													</div>
																													<div v-if="question.question_type.name == 'Multi Part Single Select Multiple Choice Question'">
																														<div
																															class="mt-4">
																															<template
																																v-for="( multi_question, index ) in question.multi_part_questions">
																																<div :key="index">{{ multi_question.question }}
																																	<v-radio-group disabled
																																		v-model="multiPartSingleAnswers[multi_question.id]" column>
																																		<template
																																			v-for="(option, index) in multi_question.multi_part_options">
																																			<div
																																				:key="index">
																																				<v-radio
																																					:label="option.option"
																																					:value="option.id"
																																					@change="calculateProgress()"></v-radio>
																																			</div>
																																		</template>
																																	</v-radio-group>
																																</div>
																															</template>
																														</div>
																													</div>
																													<div v-if="question.question_type.name == 'Multi Select Multiple Choice Questions'">
																														<div
																															class="mt-4">
																															<template
																																v-for="(option, index) in question.multi_choice_options">
																																<v-checkbox
																																	disabled
																																	v-model="multiSelects"
																																	:key="index"
																																	:label="option.option"
																																	:value="option.id"
																																	@change="calculateProgress()"></v-checkbox>
																															</template>

																														</div>
																													</div>
																													<div v-if="question.question_type.name == 'Multi Part Multi Select Multiple Choice Question'">
																														<div
																															class="mt-4">
																															<template
																																v-for="(multi_question, index) in question.multi_part_questions">
																																<div :key="index">{{ multi_question.question }}
																																	<template
																																		v-for="(option, index) in multi_question.multi_part_options">
																																		<v-checkbox
																																			disabled
																																			v-model="multiPartMultiAnswers"
																																			:key="index"
																																			:label="option.option"
																																			:value="option.id"
																																			@change="calculateProgress()"></v-checkbox>
																																	</template>
																																</div>
																															</template>
																														</div>
																													</div>
																													<div v-if="question.question_type.name == 'Open Ended'">
																														<div
																															class="mt-3 mb-5" v-if="question.length<=25">
																																<div v-if="openEnded[question.id] != null">
																																	{{ openEnded[question.id] }}
																																</div>
																																<div v-else>
																																	N/A
																																</div>
																														</div>
																													</div>
																												</v-flex>
																											</v-layout>
																										</v-card-text>
																									</v-card>
																								</v-flex>
																							</div>
																						</template>
																					</div>
																				</v-radio-group>
																			</div>
																			<div v-if="question.question_type.name =='Multi Part Single Select Multiple Choice Question'">
																				<div class="mt-4">
																					<template v-for="(multi_question, index) in question.multi_part_questions">
																						<div :key="index">
																							{{ multi_question.question }}
																							<v-radio-group disabled v-model="multiPartSingleAnswers[multi_question.id]" column>
																								<template v-for="(option, index) in multi_question.multi_part_options">
																									<div :key="index">
																										<v-radio
																											:label="option.option"
																											:value="option.id">
																										</v-radio>
																									</div>
																								</template>
																							</v-radio-group>
																							<v-text-field
																								v-if="multi_question.question == 'Other'"
																								class="text_field mb-6"
																								outlined
																								disabled
																								v-model="openEnded[question.id]"
																								label="Please specify.."
																								required></v-text-field>
																						</div>
																					</template>
																				</div>
																			</div>
																			<div v-if="question.question_type.name == 'Multi Select Multiple Choice Questions'">
																				<div class="mt-4">
																					<template v-for="(option, index) in question.multi_choice_options">
																						<v-checkbox
																							disabled
																							v-model="multiSelects"
																							:key="index"
																							:label="option.option"
																							:value="option.id"></v-checkbox>
																					</template>
																				</div>
																			</div>
																			<div v-if="question.question_type.name =='Multi Part Multi Select Multiple Choice Question'">
																				<div class="mt-4">
																					<template v-for="(multi_question, index) in question.multi_part_questions">
																						<div :key="index">
																							{{ multi_question.question }}
																							<template v-for="(option, index) in multi_question.multi_part_options">
																								<v-checkbox
																									disabled
																									v-model="multiPartMultiAnswers"
																									:key="index"
																									:label="option.option"
																									:value="option.id"></v-checkbox>
																							</template>
																						</div>
																					</template>
																				</div>
																			</div>
																			<div v-if="question.question_type.name == 'Open Ended'">
																				<div class="mt-3 mb-5" v-if="question.length<=25 && question.length!=null">
																						<div v-if="openEnded[question.id] != null">
																							{{ openEnded[question.id] }}
																						</div>
																						<div v-else>
																							N/A
																						</div>
																				</div>
																				<div class="mt-3 mb-5" v-else>
																					<div v-html="openEnded[question.id]"></div>																					
																				</div>
																			</div>
																		</v-flex>
																	</v-layout>
																</v-card-text>
															</v-card>
														</v-flex>
													</div>
												</template>
											</div>
									</v-layout>
								</div>
								<div v-if="subTab == 'rating'" class="mx-3 mt-7">
									<v-layout column class="mt-5">
										<div v-if="ratings.length != 0">
											<v-flex xs12 class="mb-10">
												<v-layout row wrap>
													<v-flex xs12 md2>
														<b>Overall Rating:</b>
													</v-flex>
													<v-flex xs12 md10>
														<div v-if="assignedApplication.applicationScore == 0">
															<i>Pending</i>
														</div>
														<div v-else>
															<div class="red--text" v-if="assignedApplication.applicationScore>0 && assignedApplication.applicationScore<=33.33">
																{{ assignedApplication.applicationScore }}%
															</div>
															<div class="orange--text" v-if="assignedApplication.applicationScore>33.33 && assignedApplication.applicationScore<=66.66">
																{{ assignedApplication.applicationScore }}%
															</div>
															<div class="green--text" v-if="assignedApplication.applicationScore>66.66">
																{{ assignedApplication.applicationScore }}%
															</div>
														</div>
													</v-flex>
												</v-layout>
											</v-flex>
											<template v-for="(applicationRating, index1) in assignedApplication.applicationRatings">
												<div :key="index">
													<v-layout column>
														<v-flex xs12 class="mt-3">
															<v-layout row wrap>
																<v-flex xs12 md2>
																	<b>Rated by:</b>
																</v-flex>
																<v-flex xs12 md10>
																	{{ applicationRating.judge.first_name }} {{ applicationRating.judge.middle_name }} {{ applicationRating.judge.last_name }}
																</v-flex>
															</v-layout>
														</v-flex>
														<v-flex xs12 class="mt-5">
															<v-layout row wrap>
																<v-flex xs12 md2>
																	<b>Judge Score:</b>
																</v-flex>
																<v-flex xs12 md10>
																	<div class="red--text" v-if="applicationRating.individualScore>0 && applicationRating.individualScore<=33.33">
																		{{ applicationRating.individualScore }}%
																	</div>
																	<div class="orange--text" v-if="applicationRating.individualScore>33.33 && applicationRating.individualScore<=66.66">
																		{{ applicationRating.individualScore }}%
																	</div>
																	<div class="green--text" v-if="applicationRating.individualScore>66.66">
																		{{ applicationRating.individualScore }}%
																	</div>
																</v-flex>
															</v-layout>
														</v-flex>
														<v-flex xs12 class="mt-6">
															<template v-for="(rating, index) in ratings">
																<div :key="index">
																	<v-flex xs12 class="mb-7">
																		<v-layout row wrap>
																			<v-flex xs1>
																				<div align="center">
																					{{ index+1 }}.
																				</div>
																			</v-flex>
																			<v-flex xs11>
																				<v-layout column>
																					<v-flex xs12>
																						<b>{{ rating.name }}</b>
																					</v-flex>
																					<v-flex xs12>
																						{{ rating.description }}
																					</v-flex>
																					<v-flex xs12>
																						Weight: {{ rating.weight }}
																					</v-flex>
																					<v-flex xs12>
																						<div v-if="rating.type=='radio'">
																							<v-layout row wrap>
																								<v-flex xs12 md10>
																									<v-rating
																											background-color="grey lighten-2"
																											color="warning"
																											half-increments
																											hover
																											length="10"
																											size="17"
																											:value="applicationRating.applicantRatings[rating.id]"
																											readonly
																										></v-rating>
																								</v-flex>
																								<v-flex xs12 md2>
																									<div align="right" class="mr-5" v-if="applicationRating.applicantRatings[rating.id]">
																										<b>{{ applicationRating.applicantRatings[rating.id] }}/10</b>
																									</div>
																									<div align="right" class="mr-5" v-else>
																										<b>-/10</b>
																									</div>
																								</v-flex>
																							</v-layout>
																						</div>
																						<div v-else>
																							<div v-html="applicationRating.applicantRatings[rating.id]">

																							</div>
																						</div>
																					</v-flex>
																				</v-layout>
																				
																			</v-flex>
																		</v-layout>
																	</v-flex>
																</div>
															</template>
														</v-flex>
													</v-layout>
												</div>
											</template>
											
										</div>
									</v-layout>
								</div>
							</div>
						</v-card-text>
					</v-card>
				</div>
			</v-layout>
		</v-container>
		<logData v-if="logDataModel" v-model="logDataModel" />
	</div>
</template>

<script>
import apiCall from "@/utils/api";
import { mapGetters, mapActions } from "vuex";
import logData from "@/views/accessControl/logData";
import Vue from "vue";
import pdf from 'vue-pdf'
var numeral = require("numeral");
Vue.filter("formatNumber", function (value) {
	return numeral(value).format("0,0");
});

Vue.use(require("vue-moment"));

export default {
	components: {
		logData,
		pdf
	},
	data() {
		return {
			path: process.env.VUE_APP_API_URL,
			view: "default",
			activeTab: 'submitted',
			subTab: 'applicant',
			applicationTab: null,
			applicantTab: null,

			docType: null,
			pdfViewDialog: false,

			loading: false,
			upgradeLoading: false,
			downgradeLoading: false,
			rejectLoading: false,
			deactivateLoading: false,

			csvLoading: false,
			pdfLoading: false,

			albumLoader: false,
			imageLoader: false,

			albumDatePicker: false,

			valid: true,
			message: "",
			color: "",
			delete: false,
			activate: false,
			confirm: false,
			lockEmail: false,

			applicationIndex: null,
			albumIndex: null,

			snackbar: false,
			inputRules: [
				(v) => !!v || "Input is required"
			],
			fileRules: [(v) => !!v || "Kindly Select a File"],

			imageDialog: false,
			imageLink: '',
			imageName: "",
			imageUrl: "",
			imageFile: "",
			imageValid: true,
			imageLoading: false,

			months: [
				"01",
				"02",
				"03",
				"04",
				"05",
				"06",
				"07",
				"08",
				"09",
				"10",
				"11",
				"12",
			],

			images: [],

			ratings: [],

			openEnded: [],
			singleSelect: [],
			multiPartSingleAnswers: [],
			multiPartMultiAnswers: [],
			multiSelects: [],
			questions: [],

			otherQuestions: [],
			childQuestions: [],
			counter: 0,

			searchTerm: null,
			searchLoader: false,
			searchButtonDisabled: false,

			filteredSubmittedApplications: false,
			filteredReviewApplications: false,
			filteredRejectedApplications: false,
			filteredSelectedForRatingApplications: false,
			filteredShortlistedApplications: false,

			newApplication: {
				id: null,
				name: null,
			},

			assignedApplication: null,

			formData: null,
			logData: {
				message: null,
				model: "App\\Models\\Application",
			},
		};
	},
	created() {
		if (this.$can("application_view")) {
			this.startSubmittedApplicationLoader();
			this.fetchSubmittedApplications(this.submittedApplicationPagination.current_page);

			this.startReviewApplicationLoader();
			this.fetchReviewApplications(this.reviewApplicationPagination.current_page);

			this.startRejectedApplicationLoader();
			this.fetchRejectedApplications(this.rejectedApplicationPagination.current_page);

			this.startSelectedForRatingApplicationLoader();
			this.fetchSelectedForRatingApplications(this.selectedForRatingApplicationPagination.current_page);

			this.startShortlistedApplicationLoader();
			this.fetchShortlistedApplications(this.shortlistedApplicationPagination.current_page);
		}
	},
	methods: {
		...mapActions([
			"fetchSubmittedApplications",
			"startSubmittedApplicationLoader",
			"stopSubmittedApplicationLoader",
			"filterSubmittedApplications",

			"fetchReviewApplications",
			"startReviewApplicationLoader",
			"stopReviewApplicationLoader",
			"filterReviewApplications",

			"fetchRejectedApplications",
			"startRejectedApplicationLoader",
			"stopRejectedApplicationLoader",
			"filterRejectedApplications",

			"fetchSelectedForRatingApplications",
			"startSelectedForRatingApplicationLoader",
			"stopSelectedForRatingApplicationLoader",
			"filterSelectedForRatingApplications",

			"fetchShortlistedApplications",
			"startShortlistedApplicationLoader",
			"stopShortlistedApplicationLoader",
			"filterShortlistedApplications",

			"filterLogData",
			"openLogDataModel",
		]),

		showApplication(application) {
			this.assignedApplication = application;
			this.getRatings()
			this.getQuestions();
			this.changeView("show");			
		},

		assignAnwers() {				
			this.openEnded = this.assignedApplication.answers[0];
			this.singleSelect = this.assignedApplication.answers[1];
			this.multiPartSingleAnswers = this.assignedApplication.answers[2];
			this.multiPartMultiAnswers = this.assignedApplication.answers[3];
			this.multiSelects = this.assignedApplication.answers[4];
		},

		enableSearch() {
			this.searchButtonDisabled = false;
		},

		resetSubmittedSearch() {
			this.searchTerm = null;
			this.filteredSubmittedApplications = true;
			this.startSubmittedApplicationLoader();
			this.fetchSubmittedApplications(1);
		},
		resetReviewSearch() {
			this.searchTerm = null;
			this.filteredReviewApplications = true;
			this.startReviewApplicationLoader();
			this.fetchReviewApplications(1);
		},
		resetRejectedSearch() {
			this.searchTerm = null;
			this.filteredRejectedApplications = true;
			this.startRejectedApplicationLoader();
			this.fetchRejectedApplications(1);
		},
		resetSelectedForRatingSearch() {
			this.searchTerm = null;
			this.filteredSelectedForRatingApplications = true;
			this.startSelectedForRatingApplicationLoader();
			this.fetchSelectedForRatingApplications(1);
		},
		resetShortlistedSearch() {
			this.searchTerm = null;
			this.filteredShortlistedApplications = true;
			this.startShortlistedApplicationLoader();
			this.fetchShortlistedApplications(1);
		},
		

		viewLog(data) {
			this.logData.message = data.id;
			this.filterLogData(this.logData);
			this.openLogDataModel();
		},

		viewDocument(doctype){
			this.docType = doctype
			this.pdfViewDialog = true
		},

		csvDownload(type) {
            this.csvLoading = true;
            apiCall({
                url: "/api/report?type="+type,
                method: "GET",
            })
                .then((resp) => {
                    this.csvLoading = false;
                    this.downloadFile(resp, type);
                })
                .catch((error) => {
                    console.log(error.response);
                    this.csvLoading = false;
                    this.message = "An Error Occurred";
                    this.color = "error";
                    this.snackbar = true;
                });
        },

		downloadFile(response, type) {
            // It is necessary to create a new blob object with mime-type explicitly set
            // otherwise only Chrome works like it should
            var newBlob = new Blob([response], { type: "application/csv" });

            // IE doesn't allow using a blob object directly as link href
            // instead it is necessary to use msSaveOrOpenBlob
            if (window.navigator && window.navigator.msSaveOrOpenBlob) {
                window.navigator.msSaveOrOpenBlob(newBlob);
                return;
            }

            // For other browsers:
            // Create a link pointing to the ObjectURL containing the blob.
            const data = window.URL.createObjectURL(newBlob);
            var link = document.createElement("a");
            link.href = data;
			if(type=='submittedApplications'){
				link.download = 'Submitted Applications - '  + this.dateToday +  ".csv";
			}
			if(type=='reviewApplications'){
				link.download = 'Review Applications - '  + this.dateToday +  ".csv";
			}
			if(type=='rejectedApplications'){
				link.download = 'Rejected Applications - '  + this.dateToday +  ".csv";
			}
			if(type=='selectedForRatingApplications'){
				link.download = 'Selected for Rating Applications - '  + this.dateToday +  ".csv";
			}
			if(type=='shortlistedApplications'){
				link.download = 'Shortlisted Applications - '  + this.dateToday +  ".csv";
			}
            
            link.click();
            setTimeout(function () {
                // For Firefox it is necessary to delay revoking the ObjectURL
                window.URL.revokeObjectURL(data);
            }, 100);
        },

		pdfDownload() {
            this.pdfLoading = true;

            axios.defaults.headers.common['Authorization'] = localStorage.getItem('user-token');
            axios({
                url: this.path+"/api/report?type=applicationPDF",
                data: this.assignedApplication,
                method: "POST",
                responseType: "blob",
            })
                    .then((resp) => {
                        this.downloadPDFFile(resp, this.assignedApplication.user.venture.name);
                        this.pdfLoading = false;
                    })
                    .catch((error) => {
                        this.pdfLoading = false;
                    });
        },
		downloadPDFFile(response, filename) {
            // It is necessary to create a new blob object with mime-type explicitly set
            // otherwise only Chrome works like it should
            console.log('response.data', response.data)
            var newBlob = new Blob([response.data], { type: "application/pdf" });

            // IE doesn't allow using a blob object directly as link href
            // instead it is necessary to use msSaveOrOpenBlob
            if (window.navigator && window.navigator.msSaveOrOpenBlob) {
                window.navigator.msSaveOrOpenBlob(newBlob);
                return;
            }

            // For other browsers:
            // Create a link pointing to the ObjectURL containing the blob.
            const data = window.URL.createObjectURL(newBlob);
            var link = document.createElement("a");
            link.href = data;
            link.download = filename + ' ' + this.dateToday + ".pdf";
            link.click();
            setTimeout(function () {
                // For Firefox it is necessary to delay revoking the ObjectURL
                window.URL.revokeObjectURL(data);
            }, 100);
        },



		submittedSearch() {
			if (this.searchTerm == null) {
				this.searchButtonDisabled = true;
			} else {
				this.searchLoader = true;
				this.searchButtonDisabled = false;
				this.startSubmittedApplicationLoader();
				apiCall({
					url: "/api/application?type=submittedSearch&search=" + this.searchTerm,
					method: "GET",
				})
					.then((resp) => {
						this.filterSubmittedApplications(resp);
						this.searchLoader = false;
						this.stopSubmittedApplicationLoader();
						this.filteredSubmittedApplications = true;
					})
					.catch((error) => {
						console.log(error.response);
						this.searchLoader = false;
						this.stopSubmittedApplicationLoader();
						this.message = "An Error Occurred";
						this.color = "error";
						this.snackbar = true;
					});
			}
		},
		reviewSearch() {
			if (this.searchTerm == null) {
				this.searchButtonDisabled = true;
			} else {
				this.searchLoader = true;
				this.searchButtonDisabled = false;
				this.startReviewApplicationLoader();
				apiCall({
					url: "/api/application?type=reviewSearch&search=" + this.searchTerm,
					method: "GET",
				})
					.then((resp) => {
						this.filterReviewApplications(resp);
						this.searchLoader = false;
						this.stopReviewApplicationLoader();
						this.filteredReviewApplications = true;
					})
					.catch((error) => {
						console.log(error.response);
						this.searchLoader = false;
						this.stopReviewApplicationLoader();
						this.message = "An Error Occurred";
						this.color = "error";
						this.snackbar = true;
					});
			}
		},
		rejectedSearch() {
			if (this.searchTerm == null) {
				this.searchButtonDisabled = true;
			} else {
				this.searchLoader = true;
				this.searchButtonDisabled = false;
				this.startRejectedApplicationLoader();
				apiCall({
					url: "/api/application?type=rejectedSearch&search=" + this.searchTerm,
					method: "GET",
				})
					.then((resp) => {
						this.filterRejectedApplications(resp);
						this.searchLoader = false;
						this.stopRejectedApplicationLoader();
						this.filteredRejectedApplications = true;
					})
					.catch((error) => {
						console.log(error.response);
						this.searchLoader = false;
						this.stopRejectedApplicationLoader();
						this.message = "An Error Occurred";
						this.color = "error";
						this.snackbar = true;
					});
			}
		},
		selectedForRatingSearch() {
			if (this.searchTerm == null) {
				this.searchButtonDisabled = true;
			} else {
				this.searchLoader = true;
				this.searchButtonDisabled = false;
				this.startSelectedForRatingApplicationLoader();
				apiCall({
					url: "/api/application?type=selectedForRatingSearch&search=" + this.searchTerm,
					method: "GET",
				})
					.then((resp) => {
						this.filterSelectedForRatingApplications(resp);
						this.searchLoader = false;
						this.stopSelectedForRatingApplicationLoader();
						this.filteredSelectedForRatingApplications = true;
					})
					.catch((error) => {
						console.log(error.response);
						this.searchLoader = false;
						this.stopSelectedForRatingApplicationLoader();
						this.message = "An Error Occurred";
						this.color = "error";
						this.snackbar = true;
					});
			}
		},
		shortlistedSearch() {
			if (this.searchTerm == null) {
				this.searchButtonDisabled = true;
			} else {
				this.searchLoader = true;
				this.searchButtonDisabled = false;
				this.startShortlistedApplicationLoader();
				apiCall({
					url: "/api/application?type=shortlistedSearch&search=" + this.searchTerm,
					method: "GET",
				})
					.then((resp) => {
						this.filterShortlistedApplications(resp);
						this.searchLoader = false;
						this.stopShortlistedApplicationLoader();
						this.filteredShortlistedApplications = true;
					})
					.catch((error) => {
						console.log(error.response);
						this.searchLoader = false;
						this.stopShortlistedApplicationLoader();
						this.message = "An Error Occurred";
						this.color = "error";
						this.snackbar = true;
					});
			}
		},
		
		changeView(view) {
			if (view == "default") {
				
			} else if (view == "create") {
				this.getRatings();
			} else if (view == "show") {
			}
			this.view = view;
		},

		getQuestions() {
			var role = null
			for(var x= 0; x<this.assignedApplication.user.roles.length; x++){
				if(this.assignedApplication.user.roles[x].name == 'Mentor'){
					role = "Mentor"
				}
				if(this.assignedApplication.user.roles[x].name == 'Mentee'){
					role = "Mentee"
				}
			}
			apiCall({
				url: "/api/question?type=public&level="+role,
				method: "GET",
			})
				.then((resp) => {
					this.questions = resp;
					this.assignAnwers()
				})
				.catch((error) => {
					this.message = "An Error Occurred; Couldn't Get Form Data";
					this.color = "error";
				});
		},

		changeSubmittedApplicationPage() {
			this.startSubmittedApplicationLoader();
			if (this.filteredSubmittedApplications == true) {
				this.searchButtonDisabled = false;
				apiCall({
					url:
						"/api/application?page=" +
						this.submittedApplicationPagination.current_page +
						"&type=search&search=" +
						this.searchTerm,
					method: "GET",
				})
					.then((resp) => {
						this.filterSubmittedApplications(resp);
						this.stopSubmittedApplicationLoader();
					})
					.catch((error) => {
						this.stopSubmittedApplicationLoader();
						this.message = "An Error Occurred";
						this.color = "error";
						this.snackbar = true;
					});
			} else {
				this.fetchSubmittedApplications(this.submittedApplicationPagination.current_page);
			}
		},
		changeReviewApplicationPage() {
			this.startReviewApplicationLoader();
			if (this.filteredReviewApplications == true) {
				this.searchButtonDisabled = false;
				apiCall({
					url:
						"/api/application?page=" +
						this.reviewApplicationPagination.current_page +
						"&type=search&search=" +
						this.searchTerm,
					method: "GET",
				})
					.then((resp) => {
						this.filterReviewApplications(resp);
						this.stopReviewApplicationLoader();
					})
					.catch((error) => {
						this.stopReviewApplicationLoader();
						this.message = "An Error Occurred";
						this.color = "error";
						this.snackbar = true;
					});
			} else {
				this.fetchReviewApplications(this.reviewApplicationPagination.current_page);
			}
		},
		changeRejectedApplicationPage() {
			this.startRejectedApplicationLoader();
			if (this.filteredRejectedApplications == true) {
				this.searchButtonDisabled = false;
				apiCall({
					url:
						"/api/application?page=" +
						this.rejectedApplicationPagination.current_page +
						"&type=search&search=" +
						this.searchTerm,
					method: "GET",
				})
					.then((resp) => {
						this.filterRejectedApplications(resp);
						this.stopRejectedApplicationLoader();
					})
					.catch((error) => {
						this.stopRejectedApplicationLoader();
						this.message = "An Error Occurred";
						this.color = "error";
						this.snackbar = true;
					});
			} else {
				this.fetchRejectedApplications(this.rejectedApplicationPagination.current_page);
			}
		},
		changeSelectedForRatingApplicationPage() {
			this.startSelectedForRatingApplicationLoader();
			if (this.filteredSelectedForRatingApplications == true) {
				this.searchButtonDisabled = false;
				apiCall({
					url:
						"/api/application?page=" +
						this.selectedForRatingApplicationPagination.current_page +
						"&type=search&search=" +
						this.searchTerm,
					method: "GET",
				})
					.then((resp) => {
						this.filterSelectedForRatingApplications(resp);
						this.stopSelectedForRatingApplicationLoader();
					})
					.catch((error) => {
						this.stopSelectedForRatingApplicationLoader();
						this.message = "An Error Occurred";
						this.color = "error";
						this.snackbar = true;
					});
			} else {
				this.fetchSelectedForRatingApplications(this.selectedForRatingApplicationPagination.current_page);
			}
		},
		changeShortlistedApplicationPage() {
			this.startShortlistedApplicationLoader();
			if (this.filteredShortlistedApplications == true) {
				this.searchButtonDisabled = false;
				apiCall({
					url:
						"/api/application?page=" +
						this.shortlistedApplicationPagination.current_page +
						"&type=search&search=" +
						this.searchTerm,
					method: "GET",
				})
					.then((resp) => {
						this.filterShortlistedApplications(resp);
						this.stopShortlistedApplicationLoader();
					})
					.catch((error) => {
						this.stopShortlistedApplicationLoader();
						this.message = "An Error Occurred";
						this.color = "error";
						this.snackbar = true;
					});
			} else {
				this.fetchShortlistedApplications(this.shortlistedApplicationPagination.current_page);
			}
		},

		deleteApplication(item) {
			confirm("Are You Sure You Want to Delete Application?") && (this.delete = true);
			if (this.delete) {
				this.loading = true;
				this.applicationIndex = item.id;
				apiCall({ url: "/api/application/" + item.id, method: "DELETE" })
					.then((resp) => {
						this.message = "Application Deleted Succesfully";
						this.color = "success";
						this.snackbar = true;
						this.loading = false;
						this.delete = false;
						this.applicationIndex = null;
						this.fetchSubmittedApplications(this.submittedApplicationPagination.current_page);
					})
					.catch((error) => {
						this.message = "An Error Occurred";
						this.color = "error";
						this.snackbar = true;
						this.loading = false;
						this.delete = false;
						this.applicationIndex = null;
						console.log(error.response);
					});
			}
		},

		applicationReview(item, type, loader) {
			confirm("Please confirm if you would like to advance the application to the subsequent stage.") && (this.activate = true);
			if (this.activate) {
				if(loader=='up'){
					this.upgradeLoading = true;
				}else if(loader=='down'){
					this.downgradeLoading = true;
				}else if(loader=='downSpec'){
					this.rejectLoading = true;
				}
				
				this.applicationIndex = item.id;
				this.newApplication.id = item.id;
				apiCall({
					url: "/api/application?type=review&level="+type,
					data: this.newApplication,
					method: "POST"
				})
					.then((resp) => {
						if(type == 'reviewStage'){
							this.message = "Application Sent to Review Stage";
						}else if(type == 'shortlisted'){
							this.message = "Application Shortlisted";
						}else if(type == 'selectedForRating'){
							this.message = "Application Selected for Rating";
						}else if(type == 'rejectedStage'){
							this.message = "Application Rejected";
						}
						
						if(loader=='up'){
							this.color = "success";
						}else{
							this.color = "orange";
						}
						this.snackbar = true;
						this.upgradeLoading = false;
						this.downgradeLoading = false;
						this.rejectLoading = false;
						this.activate = false;
						this.applicationIndex = null;
						if(type=='reviewStage'){
							if(loader=='up'){
								this.fetchSubmittedApplications(this.submittedApplicationPagination.current_page);								
							}else{
								this.fetchRejectedApplications(this.rejectedApplicationPagination.current_page);
								this.fetchSelectedForRatingApplications(this.selectedForRatingApplicationPagination.current_page);
							}
							this.fetchReviewApplications(this.reviewApplicationPagination.current_page);						
						}else if(type=='rejectedStage'){
							if(loader=='up'){
								this.fetchReviewApplications(this.reviewApplicationPagination.current_page);
							}else{
								this.fetchSelectedForRatingApplications(this.selectedForRatingApplicationPagination.current_page);
								this.fetchReviewApplications(this.reviewApplicationPagination.current_page);
							}							
							this.fetchRejectedApplications(this.rejectedApplicationPagination.current_page);
						}else if(type=='selectedForRating'){
							if(loader=='up'){
								this.fetchReviewApplications(this.reviewApplicationPagination.current_page);
								this.fetchRejectedApplications(this.rejectedApplicationPagination.current_page);
							}else{
								this.fetchShortlistedApplications(this.shortlistedApplicationPagination.current_page);
							}
							this.fetchSelectedForRatingApplications(this.selectedForRatingApplicationPagination.current_page);
						}else if(type=='shortlisted'){
							if(loader=='up'){
								this.fetchSelectedForRatingApplications(this.selectedForRatingApplicationPagination.current_page);
								this.fetchShortlistedApplications(this.shortlistedApplicationPagination.current_page);
							}
						}
						
					})
					.catch((error) => {
						this.message = "An Error Occurred";
						this.color = "error";
						this.snackbar = true;
						this.upgradeLoading = false;
						this.activate = false;
						this.applicationIndex = null;
						console.log(error.response);
					});
			}
		},
		getRatings() {
			var role = null
			for(var x= 0; x<this.assignedApplication.user.roles.length; x++){
				if(this.assignedApplication.user.roles[x].name == 'Mentor'){
					role = "Mentor"
				}
				if(this.assignedApplication.user.roles[x].name == 'Mentee'){
					role = "Mentee"
				}
			}
			apiCall({
				url: "/api/rating?level="+role,
				method: "GET",
			})
				.then((resp) => {
					this.ratings = resp;
				})
				.catch((error) => {
					this.message = "An Error Occurred; Couldn't Get Form Data";
					this.color = "error";
				});
		},
	},
	computed: {
		...mapGetters([
			"submittedApplications", "submittedApplicationPagination", "submittedApplicationLoader", 
			"reviewApplications", "reviewApplicationPagination", "reviewApplicationLoader", 
			"rejectedApplications", "rejectedApplicationPagination", "rejectedApplicationLoader", 
			"selectedForRatingApplications", "selectedForRatingApplicationPagination", "selectedForRatingApplicationLoader", 
			"shortlistedApplications", "shortlistedApplicationPagination", "shortlistedApplicationLoader", 
			
			"logDataModel"
		]),
		submittedApplicationLength: function () {
			return Math.ceil(
				this.submittedApplicationPagination.total / this.submittedApplicationPagination.per_page
			);
		},
		reviewApplicationLength: function () {
			return Math.ceil(
				this.reviewApplicationPagination.total / this.reviewApplicationPagination.per_page
			);
		},
		rejectedApplicationLength: function () {
			return Math.ceil(
				this.rejectedApplicationPagination.total / this.rejectedApplicationPagination.per_page
			);
		},
		selectedForRatingApplicationLength: function () {
			return Math.ceil(
				this.selectedForRatingApplicationPagination.total / this.selectedForRatingApplicationPagination.per_page
			);
		},
		shortlistedApplicationLength: function () {
			return Math.ceil(
				this.shortlistedApplicationPagination.total / this.shortlistedApplicationPagination.per_page
			);
		},


		formatAlbumDate() {
			if (this.newAlbum.date != null) {
				const d = new Date(this.newAlbum.date);
				const year = d.getFullYear();
				const date = d.getDate();
				const month = d.getMonth();

				return date + " " + this.monthNames[month].name + " " + year;
			}
		},
		dateToday() {
			var today = new Date();

			var timestamp =
				today.getFullYear() +
				"-" +
				this.months[today.getMonth()] +
				"-" +
				("0" + today.getDate()).slice(-2);
			return timestamp;
		},
	},
};
</script>
const state = {
	navLogo: 'img/logo-black.png',
	headerColor: 'primary',
    titleColor: 'black--text',
    buttonText: 'black--text hidden-sm-and-down text-none caption'
};

const getters = {
	navLogo: (state) => state.navLogo,
	headerColor: (state) => state.headerColor,
    titleColor: (state) => state.titleColor,
    buttonText: (state) => state.buttonText,
};

const actions = {
	async changeNavLogo({commit}, color) {
		commit('updateNavLogo', color)
	},
	async changeHeaderColor({commit}, color) {
		commit('updateHeaderColor', color)
	},
    async changeTitleColor({commit}, color) {
		commit('updateTitleColor', color)
	},
    async changeButtonColor({commit}, color) {
		commit('updateButtonColor', color)
	},
};

const mutations = {
	updateNavLogo : (state, navLogo) => state.navLogo = navLogo,
	updateHeaderColor : (state, headerColor) => state.headerColor = headerColor,
    updateTitleColor : (state, titleColor) => state.titleColor = titleColor,
    updateButtonColor : (state, buttonText) => state.buttonText = buttonText,
};

export default {
	state,
	getters,
	actions,
	mutations
}
import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);

export default new Vuetify({
    theme: {
        //dark: true,
        themes: {
            light: {
                primary: '#0074BD',
                secondary: '#58B55C',
                accent: '#525355',
                error: '#b71c1c',
                background: "#fff",
                header: "#fff",
                button: "#EEEEEE",
                universal: '#1e85f1',
                nav: '#1e85f1',
                excel: '#1D6F42',
                pdf: "#f40f02",
                text: '#FFF',
                tableHeader: '#58B55C',
                
            },
            dark: {
                primary: '#0074BD',
                secondary: '#58B55C',
                accent: '#fff',
                error: '#b71c1c',
                background: "#121212",
                header: "#272727",
                button: "292929",
                universal: '#1e85f1',
                nav: '#0f1e3d',
                excel: '#1D6F42',
                pdf: "#f40f02",
                text: '#007932',
                tableHeader: '#121212',
            },
        },
    },
});

<template>
    <v-footer
      inset
      relative 
      dark
      padless
      width="auto"
      color="primary darken-2"
      height="auto"
    >
    <v-layout column>
   
      <v-flex xs12 class="primary darken-2">        
          <v-container class="my-5">
            <v-layout column class="mt-16">
              <v-flex xs12>
                <div>
                  <div align="center">
                  <a href="http://www.ilabafrica.ac.ke/" style="text-decoration: none;" target="_blank"><v-img class="hidden-md-and-down mb-5" max-width="360" src="img/logo-white.png">
                    </v-img></a>
                    <a href="http://www.ilabafrica.ac.ke/" style="text-decoration: none;" target="_blank"><v-img class="hidden-lg-and-up mb-5" max-width="360" src="img/logo-white.png">
                    </v-img></a>
                  </div>
                </div>
              </v-flex>
              <v-flex xs12>
                <div align="center">
                  <template>
                    <a class="white--text caption mx-2 mt-5" href="/#/" style="text-decoration: none">Home</a>
                    <a class="white--text caption mx-2" href="/#/about" style="text-decoration: none">About</a>
                    <a class="white--text caption mx-2" href="/#/apply-now" style="text-decoration: none">Apply Now</a>
                    <a class="white--text caption mx-2" href="/#/gallery" style="text-decoration: none">Gallery</a>
                  </template>
                </div>
              </v-flex>              
            </v-layout>
          </v-container>
      </v-flex>
      <v-flex xs12 class="primary darken-2">
          <div align="center" class="mb-10">
            <template>
              <a
                target="_blank"
                href="https://www.facebook.com/iLabAfricaStrathmore"
                style="text-decoration: none"
              >
                <v-btn class="mx-2" icon>
                  <v-icon size="30px" color="white"> mdi-facebook </v-icon>
                </v-btn>
              </a>
              <a
                target="_blank"
                href="https://twitter.com/iLabAfrica"
                style="text-decoration: none"
              >
                <v-btn class="mx-2" icon>
                  <v-icon size="30px" color="white"> mdi-twitter </v-icon>
                </v-btn>
              </a>
              <a
                target="_blank"
                href="https://www.youtube.com/c/iLabAfricaSU/videos"
                style="text-decoration: none"
              >
                <v-btn class="mx-2" icon>
                  <v-icon size="30px" color="white"> mdi-youtube </v-icon>
                </v-btn>
              </a>
              <a
                target="_blank"
                href="https://www.instagram.com/strathmore.university"
                style="text-decoration: none"
              >
                <v-btn class="mx-2" icon>
                  <v-icon size="30px" color="white"> mdi-instagram </v-icon>
                </v-btn>
              </a>
            </template>
          </div>                      
        </v-flex>
        <v-flex xs12 class="primary darken-2">
          <div class="mx-16">
            <v-divider class="primary"></v-divider>
            <v-divider class="primary"></v-divider>
          </div>
            <v-container class="my-5">
              <v-layout row wrap>
                  <v-flex xs12 md6>
                    <div align="center">
                      <a class="caption white--text hidden-md-and-up" href="/#/terms-and-conditions" style="text-decoration: none;">Terms and Conditions</a>
                    </div>
                    </v-flex>
                  <v-flex xs12 md6>
                  <div align="center" class="caption white--text hidden-md-and-up">
                      © {{ new Date().getFullYear() }} • All Rights Reserved
                  </div>
                  </v-flex>
                  <v-flex xs12 md8>
                    <a align="left" class="caption white--text hidden-sm-and-down" href="/#/terms-and-conditions" style="text-decoration: none;">Terms and Conditions</a>
                  </v-flex>
                  <v-flex xs12 md4>
                  <div align="right" class="caption white--text hidden-sm-and-down">
                      © {{ new Date().getFullYear() }} • All Rights Reserved
                  </div>
                  </v-flex>
              </v-layout>
            </v-container>
        </v-flex>
    </v-layout>
    
          
    </v-footer>
  </template>
  <script>
    export default {
      data: () => ({
        socials: [
          {icon: 'mdi-facebook', link: 'https://www.facebook.com/iLabAfricaStrathmore/?fref=ts'},
          {icon: 'mdi-twitter', link: 'https://twitter.com/ilabafrica'},
          {icon: 'mdi-linkedin', link: 'https://www.linkedin.com/company/2596334?trk=tyah&trkInfo=clickedVertical%3Acompany%2CclickedEntityId%3A2596334%2Cidx%3A2-1-2%2CtarId%3A1465459099320%2Ctas%3AILABAFRICA'},
          {icon: 'mdi-youtube', link: 'https://www.youtube.com/channel/UCeo0kj1SRiVkje78cyxnAzQ'},
          {icon: 'mdi-instagram', link: 'https://www.instagram.com/ilabafrica/?hl=en'},
        ],
      }),
    }
  </script>
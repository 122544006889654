<template>
    <div class="home">
      <v-container fill-height fluid>
        <v-row align="center">
          <v-col class="text-center mt-10 mx-10">
            <v-progress-linear indeterminate color="primary"></v-progress-linear>
          </v-col>
        </v-row>
      </v-container>
    </div>
  </template>
  <script>
  import apiCall from "@/utils/api";
  import { mapGetters } from "vuex";
  import { AUTH_LOGOUT } from "@/store/actions/auth";
  export default {
    created() {
      this.initialize()
    },
    computed: {
      ...mapGetters(["getProfile"]),
    },
    methods: {
      initialize(){
        if (this.getProfile.roles.length == 0) {
          this.$router.push("/");
        } else {
          for (var i = 0; i < this.getProfile.roles.length; i++) {
            console.log("this.getProfile.roles[i].name", this.getProfile.roles[i].name)
            if(this.getProfile.roles[i].name == "Judge"){
              this.$router.push("/groups");
            } else if(this.getProfile.roles[i].name == "System Admin"){
              this.$router.push("/");
            }
          }
          // this.$router.push("/");
        }
      }
    }
  };
  </script>
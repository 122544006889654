<template>
	<div class="welcome">
		<v-dialog transition="dialog-bottom-transition" max-width="1500" v-model="dialog">
			<v-card v-if="assignedImage!=null">
				<v-toolbar color="white" elevation="0">
					<v-btn icon class="background ml-1" @click="previousImage()" v-if="imageIndex!=0">
                        <v-icon class="secondary--text">mdi-chevron-left</v-icon>
                    </v-btn>
                    <v-btn icon class="background ml-1" disabled v-if="imageIndex==0">
                        <v-icon class="secondary--text">mdi-chevron-left</v-icon>
                    </v-btn>
                    <v-btn icon class="background ml-1" @click="nextImage()" v-if="(imageIndex + 1) != images.length">
                        <v-icon class="primary--text">mdi-chevron-right</v-icon>
                    </v-btn>
                    <v-btn icon class="background ml-1" disabled v-if="(imageIndex + 1) == images.length">
                        <v-icon class="primary--text">mdi-chevron-right</v-icon>
                    </v-btn>
					<v-spacer></v-spacer>
					<v-btn icon class="background mr-1" @click="dialog = false">
                        <v-icon class="primary--text">mdi-close</v-icon>
                    </v-btn>
				</v-toolbar>
				<div>
					<v-img max-height="800" contain :src="path + '/storage/' + images[imageIndex].name"/>
				</div>
			</v-card>
		</v-dialog>
		<v-layout column>
			<v-flex xs12>
                <v-img dark height="590" src="img/banner-2.png">
                    <v-layout row wrap>
                      <v-flex xs10>
                        <div align="right" class="font-weight-black display-4 mt-10 mr-13 primary--text">
                          Gallery
                        </div>
                      </v-flex>
                      <v-flex xs2>
                        <div align="left" class="display-4 black--text mt-9" style="margin-left: -50px;">
                          <v-icon size="100px" color="accent">
                            mdi-camera
                          </v-icon>
                        </div>
                      </v-flex>
                    </v-layout>                    
                </v-img>
            </v-flex>
			<v-progress-linear v-if="imageLoader" height="2" indeterminate color="primary"></v-progress-linear>

			<v-flex xs12 class="white">
				<v-container class="mt-12">
					<div align="center" class="mt-15">
						<v-card elevation="0" style="margin-top: -500px;" max-width="1000" class="pa-16">
							<v-layout column>
								<v-flex xs12>
									<v-tabs show-arrows v-model="picGroup">
										<v-tabs-slider color="primary"></v-tabs-slider>

										<template v-for="(gallery, index) in allGalleries">
											<v-tab @click="assignGallery(gallery)">
												<div class="text-none primary--text">{{ gallery.name }}</div>
											</v-tab>
										</template>
									</v-tabs>
								</v-flex>
								<v-flex xs12>
									<v-layout row wrap>
										<v-flex xs12 md3>
											<div v-if="allGalleries.length != 0" class="my-15">
												<v-timeline align-top dense>
													<template v-for="(album, index) in activeGallery.albums">
														<v-timeline-item color="primary" small v-if="assignedAlbum.id == album.id">
															<v-row>
																<v-col>
																	<a style="text-decoration: none" class="primary--text"
																		@click="assignAlbum(album)"><strong>{{ album.name }}</strong></a>
																	<div class="text-caption primary--text">
																		{{ album.date }}
																	</div>
																</v-col>
															</v-row>
														</v-timeline-item>
														<v-timeline-item color="secondary" small v-if="assignedAlbum.id != album.id">
															<v-row>
																<v-col>
																	<a style="text-decoration: none" class="black--text"
																		@click="assignAlbum(album)"><strong>{{ album.name }}</strong></a>
																	<div class="text-caption">
																		{{ album.date }}
																	</div>
																</v-col>
															</v-row>
														</v-timeline-item>
													</template>
												</v-timeline>
											</div>
											<div v-else class="mx-5">
												<v-skeleton-loader v-bind="attrs"
													type="table-heading, table-heading, table-heading, table-heading"></v-skeleton-loader>
											</div>
										</v-flex>
										<v-flex xs12 md9>
											<div v-if="images.length != 0">
												<v-layout column class="mt-15">
													<v-flex xs12>
														<v-layout row wrap>
															<v-flex xs12 md4>
																<v-layout column>
																	<template v-for="(image, index) in imageArray[0]">
																		<v-flex xs12>
																			<v-hover v-slot="{ hover }">
																				<v-card class="mx-1 mt-2" color="grey lighten-4" max-width="600" elevation="0">
																					<v-img contain :src="path + '/storage/' + image.name"
																						lazy-src="img/assignedAlbum4/Gift-Chain-Africa-Ltd-700x500.jpg">
																						<v-expand-transition>
																							<div v-if="hover"
																								class="d-flex transition-fast-in-fast-out primary darken-2 v-card--reveal "
																								style="height: 100%;">
																								<v-icon large class="white--text"
																									@click="expandImage(image)">mdi-magnify-plus-outline</v-icon>
																							</div>
																						</v-expand-transition>
																						<template v-slot:placeholder>
																							<v-row class="fill-height ma-0" align="center" justify="center">
																								<v-progress-circular indeterminate
																									color="grey lighten-5"></v-progress-circular>
																							</v-row>
																						</template>
																					</v-img>
																				</v-card>
																			</v-hover>
																		</v-flex>
																	</template>
																</v-layout>
															</v-flex>
															<v-flex xs12 md4>
																<v-layout column>
																	<template v-for="(
																		image, index
																		) in imageArray[1]">
																		<v-flex xs12>
																			<v-hover v-slot="{ hover }">
																				<v-card class="mx-1 mt-2" color="grey lighten-4" max-width="600" elevation="0">
																					<v-img contain :src="path + '/storage/' + image.name"
																						lazy-src="img/assignedAlbum4/Gift-Chain-Africa-Ltd-700x500.jpg">
																						<v-expand-transition>
																							<div v-if="hover"
																								class="d-flex transition-fast-in-fast-out primary darken-2 v-card--reveal "
																								style="height: 100%;">
																								<v-icon large class="white--text"
																									@click="expandImage(image)">mdi-magnify-plus-outline</v-icon>
																							</div>
																						</v-expand-transition>
																						<template v-slot:placeholder>
																							<v-row class="fill-height ma-0" align="center" justify="center">
																								<v-progress-circular indeterminate
																									color="grey lighten-5"></v-progress-circular>
																							</v-row>
																						</template>
																					</v-img>
																				</v-card>
																			</v-hover>
																		</v-flex>
																	</template>
																</v-layout>
															</v-flex>
															<v-flex xs12 md4>
																<v-layout column>
																	<template v-for="(
																		image, index
																		) in imageArray[2]">
																		<v-flex xs12>
																			<v-hover v-slot="{ hover }">
																				<v-card class="mx-1 mt-2" color="grey lighten-4" max-width="600" elevation="0">
																					<v-img contain :src="path + '/storage/' + image.name"
																						lazy-src="img/assignedAlbum4/Gift-Chain-Africa-Ltd-700x500.jpg">
																						<v-expand-transition>
																							<div v-if="hover"
																								class="d-flex transition-fast-in-fast-out primary darken-2 v-card--reveal "
																								style="height: 100%;">
																								<v-icon large class="white--text"
																									@click="expandImage(image)">mdi-magnify-plus-outline</v-icon>
																							</div>
																						</v-expand-transition>
																						<template v-slot:placeholder>
																							<v-row class="fill-height ma-0" align="center" justify="center">
																								<v-progress-circular indeterminate
																									color="grey lighten-5"></v-progress-circular>
																							</v-row>
																						</template>
																					</v-img>
																				</v-card>
																			</v-hover>
																		</v-flex>
																	</template>
																</v-layout>
															</v-flex>
														</v-layout>
													</v-flex>
												</v-layout>
											</div>
											<div v-else class="mt-7">
												<v-layout row wrap>
													<v-flex xs12 md4>
														<div class="mx-1 my-1">
															<v-skeleton-loader v-bind="attrs" type="image"></v-skeleton-loader>
														</div>
													</v-flex>
													<v-flex xs12 md4>
														<div class="mx-1 my-1">
															<v-skeleton-loader v-bind="attrs" type="image"></v-skeleton-loader>
														</div>
													</v-flex>
													<v-flex xs12 md4>
														<div class="mx-1 my-1">
															<v-skeleton-loader v-bind="attrs" type="image"></v-skeleton-loader>
														</div>
													</v-flex>
													<v-flex xs12 md4>
														<div class="mx-1 my-1">
															<v-skeleton-loader v-bind="attrs" type="image"></v-skeleton-loader>
														</div>
													</v-flex>
													<v-flex xs12 md4>
														<div class="mx-1 my-1">
															<v-skeleton-loader v-bind="attrs" type="image"></v-skeleton-loader>
														</div>
													</v-flex>
													<v-flex xs12 md4>
														<div class="mx-1 my-1">
															<v-skeleton-loader v-bind="attrs" type="image"></v-skeleton-loader>
														</div>
													</v-flex>
													<v-flex xs12 md4>
														<div class="mx-1 my-1">
															<v-skeleton-loader v-bind="attrs" type="image"></v-skeleton-loader>
														</div>
													</v-flex>
													<v-flex xs12 md4>
														<div class="mx-1 my-1">
															<v-skeleton-loader v-bind="attrs" type="image"></v-skeleton-loader>
														</div>
													</v-flex>
													<v-flex xs12 md4>
														<div class="mx-1 my-1">
															<v-skeleton-loader v-bind="attrs" type="image"></v-skeleton-loader>
														</div>
													</v-flex>
													<v-flex xs12 md4>
														<div class="mx-1 my-1">
															<v-skeleton-loader v-bind="attrs" type="image"></v-skeleton-loader>
														</div>
													</v-flex>
													<v-flex xs12 md4>
														<div class="mx-1 my-1">
															<v-skeleton-loader v-bind="attrs" type="image"></v-skeleton-loader>
														</div>
													</v-flex>
													<v-flex xs12 md4>
														<div class="mx-1 my-1">
															<v-skeleton-loader v-bind="attrs" type="image"></v-skeleton-loader>
														</div>
													</v-flex>
												</v-layout>
											</div>
											<v-layout column class="mt-15">
												<v-flex xs12>
													<div align="left" v-if="images.length != 0">
														<v-pagination v-if="length != 0" :length="imageLength" total-visible="10" v-model="imagePagination.current_page
					" @input="changeImagePage()" circle>
														</v-pagination>
													</div>
												</v-flex>
											</v-layout>
										</v-flex>
									</v-layout>

								</v-flex>
							</v-layout>
						</v-card>
					</div>
				</v-container>
			</v-flex>
		</v-layout>
	</div>
</template>
<style>
.v-card--reveal {
	align-items: center;
	bottom: 0;
	justify-content: center;
	opacity: .5;
	position: absolute;
	width: 100%;
}
</style>
<script>
import apiCall from "@/utils/api";
import { mapState, mapGetters, mapActions } from "vuex";
import Vue from "vue";

export default {
	components: {

	},
	data() {
		return {
			path: process.env.VUE_APP_API_URL,
			dialog: false,
			color: "",
			message: "",
			snackbar: false,
			drawer: false,

			activeGallery: 'launch',
			picGroup: 'launch',
			assignedAlbum: '6',
			assignedImage: null,
			imageIndex: 0,
			arrayNumber: null,

			absolute: true,
			overlay: true,
			opacity: 0.3,

			originalArray: [],
			imageArray: [],
		};
	},
	watch: {
		allGalleries() {
			this.initWorkFlow();
		},
	},
	mounted() {

	},
	created() {
		window.scrollTo(0, 0);
		this.fetchAllGalleries()
		if(this.images.length!=0){
			this.chunkImages()
		}
	},
	methods: {
		...mapActions([
			'fetchAllGalleries',
			'filterImages',
            'startImageLoader',
            'stopImageLoader',
			"changeNavLogo",
			"changeHeaderColor",
			"changeTitleColor",
			"changeButtonColor"
		]),
		changeColor() {
			if (document.body.scrollTop > 100 || document.documentElement.scrollTop > 100) {
			this.changeNavLogo("img/logo-white.png");
			this.changeHeaderColor("primary");
			this.changeTitleColor("white--text");
			this.changeButtonColor("text-none mr-1 hidden-sm-and-down white--text");
			} else {
			this.changeNavLogo("img/logo-black.png");
			this.changeHeaderColor("white");
			this.changeTitleColor("primary--text");
			this.changeButtonColor("text-none mr-1 hidden-sm-and-down white primary--text");
			}
		},
		initWorkFlow() {
			this.activeGallery = this.allGalleries[0]
			this.assignedAlbum = this.activeGallery.albums[0]

			this.changeImagePage()
		},

		assignGallery(gallery) {
			this.activeGallery = gallery
		},
		assignAlbum(album) {
			this.assignedAlbum = album
			this.startImageLoader()
			apiCall({
				url:
					"/api/image?type=public&album_id=" +
					this.assignedAlbum.id +
					"&page=1" ,
				method: "GET",
			})
				.then((resp) => {
					this.stopImageLoader();
					this.filterImages(resp);

					this.chunkImages()
				})
				.catch((error) => {
					console.log(error);
					this.stopImageLoader();
					this.message = "An Error Occurred";
					this.color = "error";
					this.snackbar = true;
				});
		},
		expandImage(image) {
			this.dialog = true
			this.assignedImage = this.images.find((originalImage) => originalImage.name == image.name);

			var index;
			for (var i = 0; i < this.images.length; i++) {
				if (this.images[i].name == image.name) {
					index = i;
					break;
				}
			}

			this.imageIndex = index
		},
		previousImage(){
            this.imageIndex -= 1
            this.assignedImage = this.images[this.imageIndex]
        },
        nextImage(){
            this.imageIndex += 1
            this.assignedImage = this.images[this.imageIndex]
        },

		closeDialog() {
			this.imageIndex = 0
			this.dialog = false
		},
		chunkImages() {
			this.imageArray = [];

			for (var i = 0; i < this.images.length; i++) {
				this.originalArray.push(this.images[i])
			}

			let result = [];
			for (let i = 3; i > 0; i--) {
				result.push(this.originalArray.splice(0, Math.ceil(this.originalArray.length / i)));
			}
			this.imageArray = result;
		},
		changeImagePage() {
			this.startImageLoader()
			apiCall({
				url:
					"/api/image?type=public&album_id=" +
					this.assignedAlbum.id +
					"&page=" +
					this.imagePagination.current_page,
				method: "GET",
			})
				.then((resp) => {
					this.stopImageLoader();
					this.filterImages(resp);

					this.chunkImages()
				})
				.catch((error) => {
					console.log(error);
					this.stopImageLoader();
					this.message = "An Error Occurred";
					this.color = "error";
					this.snackbar = true;
				});
		},
	},
	computed: {
		...mapGetters(["darkState", "allGalleries", "images", "imagePagination", "imageLoader"]),
		imageLength: function () {
			return Math.ceil(
				this.imagePagination.total /
				this.imagePagination.per_page
			);
		},
	},
};
</script>
